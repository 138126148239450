import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-congraultion',
  templateUrl: './congraultion.component.html',
  styleUrls: ['./congraultion.component.scss']
})
export class CongraultionComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  ngOnInit() {
  }

}
