import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/services/http/http.service';
import * as braintrees from 'braintree-web-drop-in';
import { CommonService } from 'src/app/services/common/common.service';
import { Router } from '@angular/router';
declare var braintree: any;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class PaymentComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isEditable = false;
  isLinear = true;
  professionaldetails: any;
  bookingDetails: any;
  imageBaseUrl: any = environment.imageUrl;
  hostedFieldsInstance: braintrees.HostedFields;
  cardholdersName: string;
  braintrees = require('braintree-web');
  clientToken: any;
  nonce: any;
  isVenmoOptionCheck: boolean = false;
  isVenmoCheck: boolean = false;
  isCashChecked: boolean = true;
  venmoButton: any;
  constructor(private _formBuilder: FormBuilder, private http: HttpService, private common: CommonService, private router: Router) {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', null]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }

  ngOnInit() {
    if (!(localStorage.professionalDetails == '' || localStorage.professionalDetails == undefined || localStorage.professionalDetails == null)) {
      this.professionaldetails = JSON.parse(localStorage.professionalDetails)
      console.log(this.professionaldetails)
    }
    if (!(localStorage.bookingData == '' || localStorage.bookingData == undefined || localStorage.bookingData == null)) {
      this.bookingDetails = JSON.parse(localStorage.bookingData)
      console.log(this.bookingDetails, "BOOKING-DETAILS")
    }
    this.getClienttoken();
  }

  changeIntoGMT(time: any) {
    var gmtTime = time.toString();
    return gmtTime;
  }

  getClienttoken() {
    this.http.httpGetWithQueryandHeader('getClientToken', '').subscribe((res: any) => {
      if (res.status == 200) {
        this.clientToken = res.result.token;
      }
      console.log(res)

    })

  }

  // payWithVenmo(event: any) {
  //   if (event.target.checked) {
  //     this.isVenmoCheck = true;
  //     this.isCashChecked = false;
  //     this.isVenmoOptionCheck = false;
  //     const that = this;
  //     braintrees.create({
  //       authorization: this.clientToken,
  //       container: document.getElementById('dropin-container'),
  //       paypal: {
  //         flow: 'vault'
  //       },
  //       venmo: true
  //     }, function (createErr, instance) {
  //       if (instance.isPaymentMethodRequestable()) {
  //         instance.requestPaymentMethod(function (requestPaymentMethodErr, payload) {
  //           that.nonce = payload.nonce;
  //           console.log(that.nonce)
  //         });
  //       }
  //     })
  //   }
  // }

  payWithVenmoOption(event: any) {
    if (event.target.checked) {
      this.isVenmoCheck = false;
      this.isCashChecked = false;
      this.isVenmoOptionCheck = true;
      //document.getElementById('dropin-container').innerHTML = '';
      this.nonce = '';
      const that = this;
      braintree.client.create({
        authorization: this.clientToken
      }, function (clientErr, clientInstance) {
        if (clientErr) {
          this.common.presentsToast('error', 'top-end', clientErr)
          console.error('Error creating client:', clientErr);
          return;
        }
        braintree.venmo.create({
          client: clientInstance,
          allowDesktop: true,
          allowNewBrowserTab: false,
          paymentMethodUsage: 'multi_use' // available in v3.77.0+
        }, function (venmoErr, venmoInstance) {
          if (venmoErr) {
            this.common.presentsToast('error', 'top-end', venmoErr)
            return;
          }

          if (!venmoInstance.isBrowserSupported()) {
            this.common.presentsToast('error', 'top-end', 'Browser does not support Venmo')
            return;
          }
         // if (venmoInstance.hasTokenizationResult()) {
            venmoInstance.tokenize(function (tokenizeErr, payload) {
              if (tokenizeErr) {
                that.handleVenmoError(tokenizeErr);
              } else {
                that.handleVenmoSuccess(payload);
              }
            });
        // }
        });
      });
    }
  }

  handleVenmoError(err) {
    if (err.code === 'VENMO_CANCELED') {
      this.common.presentsToast('error', 'top-end', 'App is not available or user aborted payment flow')
    } else if (err.code === 'VENMO_APP_CANCELED') {
      this.common.presentsToast('error', 'top-end', 'User canceled payment flow')
    } else {
      this.common.presentsToast('error', 'top-end', err)
    }
  }

  handleVenmoSuccess(payload) {
    this.nonce = payload.nonce
    console.log(payload)
    console.log(payload.nonce)
  }

  confrimPayment() {
    if (this.isVenmoOptionCheck == true && !(this.nonce == undefined || this.nonce == null || this.nonce == '')) {
      this.http.httpPostWithHeader('bookingPayment', { nonce: this.nonce, bookingId: this.bookingDetails.booking._id, paymentMethod: 'VENMO' }).subscribe((res: any) => {
        if (res.status == 200) {
          // document.getElementById('dropin-container').innerHTML = '';
          this.nonce = '';
          this.common.presentsToast('success', 'top-end', 'Payment completed successfully')
          this.router.navigate(['/payment-completed'])
        }
      })
    }

    else if (this.isCashChecked == true) {
      this.http.httpPostWithHeader('bookingPayment', { bookingId: this.bookingDetails.booking._id, paymentMethod: 'CASH' }).subscribe((res: any) => {
        if (res.status == 200) {
          this.common.presentsToast('success', 'top-end', 'Payment completed successfully');
          this.router.navigate(['/payment-completed'])
        }
      })
    }

    else {
      return true;
    }
  }

  cashPayment(event: any) {
    if (event.target.checked) {
      //document.getElementById('dropin-container').innerHTML = '';
      this.isVenmoCheck = false;
      this.isCashChecked = true;
      this.isVenmoOptionCheck = false;
      this.nonce = '';
    }
    else {
      this.isCashChecked = false;
    }
  }

  cancelPaymentMethod() {
    this.isVenmoCheck = false;
    this.isCashChecked = false;
    this.isVenmoOptionCheck = false;
    this.nonce = '';
    //document.getElementById('dropin-container').innerHTML = '';
    this.router.navigate(['/chat'])
  }
}
