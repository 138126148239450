import { Injectable } from "@angular/core";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { MatDialog } from "@angular/material/dialog";
import { ModalPopupComponent } from "src/app/shared/modal-popup/modal-popup.component";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  UrlTree,
  RouterStateSnapshot,
} from "@angular/router";
import { HttpService } from "../http/http.service";
import { CommonService } from "../common/common.service";
import { ModalPopupEmailComponent } from "src/app/shared/modal-popup-email/modal-popup-email.component";
import { LoaderComponent } from "src/app/shared/loader/loader.component";
import { BehaviorSubject, Observable } from "rxjs";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TranslateService } from "@ngx-translate/core";

const defaultPath = "/";

@Injectable()
export class AuthService {
  isEmailVerified: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isPhoneVerified: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isUserDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  _loggedIn: boolean = false;
  isForgot: any = "emailVerify";
  isLoogedOut: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get loggedIn(): boolean {
    if (
      !(
        localStorage.accessToken == undefined ||
        localStorage.accessToken == null ||
        localStorage.accessToken == ""
      )
    ) {
      this._loggedIn = true;
    }
    return this._loggedIn;
  }

  private _lastAuthenticatedPath: string = defaultPath;
  set lastAuthenticatedPath(value: string) {
    this._lastAuthenticatedPath = value;
  }

  constructor(
    private commonbService: CommonService,
    public dialogRef: MatDialog,
    private router: Router,
    public dialog: MatDialog,
    private https: HttpService,
    private loading: LoaderComponent,
    private ngxService: NgxUiLoaderService,
    public translate: TranslateService
  ) {}

  logInPhone(param) {
    this.ngxService.start();
    this.loading.setLoading(true);
    this.https
      .httpPostPromise("login", param)
      .then((res: any) => {
        // if (
        //   res.result &&
        //   !(
        //     res.result.firstName == "" ||
        //     res.result.firstName == undefined ||
        //     res.result.firstName == null ||
        //     res.result.lastName == "" ||
        //     res.result.lastName == undefined ||
        //     res.result.lastName == null ||
        //     res.result.address == undefined ||
        //     res.result.address == null ||
        //     res.result.address == ""
        //   ) &&
        //   !res.result.isEmailVerify
        // ) {
        //   this.ngxService.stop();
        //   this.sendEmailVerify(res.result.email);
        // } else
        if (
          res.result &&
          (res.result.firstName == "" ||
            res.result.firstName == undefined ||
            res.result.firstName == null ||
            res.result.lastName == "" ||
            res.result.lastName == undefined ||
            res.result.lastName == null ||
            res.result.address == undefined ||
            res.result.address == null ||
            res.result.address == "")
        ) {
          this.ngxService.stop();
          localStorage.setItem("accessToken", res.result.Authorization);
          localStorage.setItem("userProfile", JSON.stringify(res.result));
          this.router.navigateByUrl("/profile-set");
        } else if (
          res.result &&
          res.status == 200 &&
          !(
            res.result.firstName == "" ||
            res.result.firstName == undefined ||
            res.result.firstName == null ||
            res.result.lastName == "" ||
            res.result.lastName == undefined ||
            res.result.lastName == null ||
            res.result.address == undefined ||
            res.result.address == null ||
            res.result.address == ""
          ) &&
          res.result.isEmailVerify
        ) {
          this.ngxService.stop();
          localStorage.removeItem("userProfile");
          localStorage.setItem("accessToken", res.result.Authorization);
          localStorage.setItem("userData", JSON.stringify(res.result));
          var dataParam = {
            firstName: res.result.firstName,
            lastName: res.result.lastName,
            _id: res.result._id,
            email: res.result.email,
            phone: res.result.phone,
            countryCode: res.result.countryCode,
            address: res.result.address,
            city: res.result.city,
            state: res.result.state,
            country: res.result.country,
            image: res.result.image,
            latitude: res.result.latitude,
            longitude: res.result.longitude,
          };
          localStorage.setItem("userProfileData", JSON.stringify(dataParam));
          this.isLoggedIn.next(true);
          this.isUserDataSubject.next(res.result);
          if (
            !(
              this.commonbService.allPiercingData == undefined ||
              this.commonbService.allPiercingData == "" ||
              this.commonbService.allPiercingData == null
            )
          ) {
            this.router.navigateByUrl("/piercingConsultation");
            return;
          }
          if (
            !(
              this.commonbService.allTattooData == undefined ||
              this.commonbService.allTattooData == "" ||
              this.commonbService.allTattooData == null
            )
          ) {
            this.router.navigateByUrl("/tattooConsultaion");
            return;
          }
          if (
            !(
              this.commonbService.allArtistData == undefined ||
              this.commonbService.allArtistData == "" ||
              this.commonbService.allArtistData == null
            )
          ) {
            this.router.navigate([
              "/artist-appointment/" + this.commonbService.profeId,
            ]);
            return;
          } else {
            this.router.navigate(["/"]);
          }
        } else {
          this.ngxService.stop();
          this.loading.setLoading(false);
          Swal.fire(
            this.translate.instant("OOPS"),
            this.translate.instant("SOMETHING_GONE_WRONG"),
            "error"
          );
        }
      })
      .catch((err: any) => {
        this.ngxService.stop();
        this.commonbService.presentsToast(
          "error",
          "top-end",
          err.error.message
        );
        this.router.navigate(["/login-phone"]);
      });
  }
  logInEmail(param) {
    this.ngxService.start();
    this.https
      .httpPostPromise("login", param)
      .then((res: any) => {
        // if (
        //   res.result &&
        //   !(res.result.firstName == "" ||
        //     res.result.firstName == undefined ||
        //     res.result.firstName == null ||
        //     res.result.lastName == "" ||
        //     res.result.lastName == undefined ||
        //     res.result.lastName == null ||
        //     res.result.address == undefined ||
        //     res.result.address == null ||
        //     res.result.address == "")
        //   &&
        //   !res.result.isEmailVerify
        // ) {
        //   this.ngxService.stop();
        //   this.sendEmailVerify(res.result.email);
        // } else
        if (
          res.result &&
          (res.result.firstName == "" ||
            res.result.firstName == undefined ||
            res.result.firstName == null ||
            res.result.lastName == "" ||
            res.result.lastName == undefined ||
            res.result.lastName == null ||
            res.result.address == undefined ||
            res.result.address == null ||
            res.result.address == "")
        ) {
          this.ngxService.stop();
          localStorage.setItem("accessToken", res.result.Authorization);
          localStorage.setItem("userProfile", JSON.stringify(res.result));
          this.router.navigateByUrl("/profile-set");
        } else if (
          res.status === 200 &&
          res.result &&
          !(
            res.result.firstName == "" ||
            res.result.firstName == undefined ||
            res.result.firstName == null ||
            res.result.lastName == "" ||
            res.result.lastName == undefined ||
            res.result.lastName == null ||
            res.result.address == undefined ||
            res.result.address == null ||
            res.result.address == ""
          )
          // &&
          // res.result.isEmailVerify
        ) {
          this.ngxService.stop();
          localStorage.removeItem("userProfile");
          localStorage.setItem("accessToken", res.result.Authorization);
          localStorage.setItem("userData", JSON.stringify(res.result));
          var dataParam = {
            firstName: res.result.firstName,
            lastName: res.result.lastName,
            _id: res.result._id,
            email: res.result.email,
            phone: res.result.phone,
            countryCode: res.result.countryCode,
            address: res.result.address,
            city: res.result.city,
            state: res.result.state,
            country: res.result.country,
            image: res.result.image,
            latitude: res.result.latitude,
            longitude: res.result.longitude,
          };
          localStorage.setItem("userProfileData", JSON.stringify(dataParam));
          this.isLoggedIn.next(true);
          this.isUserDataSubject.next(res.result);
          if (
            !(
              this.commonbService.allPiercingData == undefined ||
              this.commonbService.allPiercingData == "" ||
              this.commonbService.allPiercingData == null
            )
          ) {
            this.router.navigate(["/piercingConsultation"]);
            return;
          } else if (
            !(
              this.commonbService.allTattooData == undefined ||
              this.commonbService.allTattooData == "" ||
              this.commonbService.allTattooData == null
            )
          ) {
            this.router.navigateByUrl("/tattooConsultaion");
            return;
          }
          if (
            !(
              this.commonbService.allArtistData == undefined ||
              this.commonbService.allArtistData == "" ||
              this.commonbService.allArtistData == null
            )
          ) {
            this.router.navigate([
              "/artist-appointment/" + this.commonbService.profeId,
            ]);
            return;
          } else {
            this.router.navigate(["/"]);
          }
        } else {
          this.ngxService.stop();
          Swal.fire(
            this.translate.instant("OOPS"),
            this.translate.instant("SOMETHING_GONE_WRONG"),
            "error"
          );
        }
      })
      .catch((err: any) => {
        this.ngxService.stop();
        this.commonbService.presentsToast(
          "error",
          "top-end",
          err.error.message
        );
        this.router.navigate(["/login"]);
      });
  }

  sendEmailVerify(email) {
    return this.https
      .httpPost("sendVerifyEmail", { email })
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.isForgot = "emailVerify";
          this.dialog.open(ModalPopupEmailComponent, {
            disableClose: true,
            height: "400px",
            width: "400px",
          });
          this.commonbService.presentsToast("success", "top-end", res.message);
        } else {
          Swal.fire(
            this.translate.instant("OOPS"),
            this.translate.instant("SOMETHING_GONE_WRONG"),
            "error"
          );
        }
      });
  }

  signupSendOtp(phone: Number, countryCode: String, email: any, text: any) {
    let data = {
      phone: phone,
      countryCode: countryCode,
      email: email,
    };
    return this.https.httpPost("sendOtp", data).subscribe((res: any) => {
      if (res.status == 200) {
        if (text != "resend") {
          this.commonbService.presentsToast("success", "top-end", res.message);
        }
        if (text == "resend") {
          this.commonbService.presentsToast(
            "success",
            "top-end",
            this.translate.instant("OTP_RESENT_SUCCESSFULLY")
          );
        }
        this.dialog.open(ModalPopupComponent, {
          disableClose: true,
          height: "400px",
          width: "400px",
        });
      } else {
        Swal.fire(this.translate.instant("OOPS"), res.message, "error");
      }
    });
  }

  signupSendOtpForProfile(data: any) {
    return this.https
      .httpPostWithHeader("sendOtpForProfile", data)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.dialog.open(ModalPopupComponent, {
            disableClose: true,
            height: "400px",
            width: "400px",
          });
        } else {
          Swal.fire(this.translate.instant("OOPS"), res.message, "error");
        }
      });
  }

  signupVerifyOtp(params) {
    return this.https.httpPost("signup", params).subscribe((res: any) => {
      if (res.status == 200) {
        this.dialogRef.closeAll();
        localStorage.setItem("isCountryCode", params.countrtyCode);
        localStorage.setItem("accessToken", res.result.Authorization);
        localStorage.setItem("userProfile", JSON.stringify(res.result));
        this.router.navigate(["/profile-set"]);
      } else {
        Swal.fire(this.translate.instant("OOPS"), res.message, "error");
      }
    });
  }

  VerifyProfileOtp(params, type) {
    return this.https
      .httpPostWithHeader("verifyOtpForProfile", params)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.dialogRef.closeAll();
          if (type == "email") {
            this.isEmailVerified.next(true);
          }
          if (type == "phone") {
            this.isPhoneVerified.next(true);
          }
        } else {
          Swal.fire(this.translate.instant("OOPS"), res.message, "error");
        }
      });
  }

  signupCompleteProfile(params) {
    this.https
      .httpPostWithHeaderFormData("completeProfile", params)
      .subscribe((res: any) => {
        if (res.status == 200) {
          localStorage.clear();
          localStorage.setItem("isProfileSetupCompleted", "true");
          this.commonbService.presentsToast("success", "top-end", res.message);
          this.router.navigate(["/login"]);
        } else {
          Swal.fire(this.translate.instant("OOPS"), res.message, "error");
        }
      });
  }

  logOut() {
    if (localStorage.accessToken !== undefined) {
      this.https.httpGetWithHeader("logout", "").subscribe((res: any) => {
        if (res) {
          var deviceToken;
          if (
            !(
              localStorage.fcmDeviceToken == null ||
              localStorage.fcmDeviceToken == undefined ||
              localStorage.fcmDeviceToken == ""
            )
          ) {
            deviceToken = localStorage.fcmDeviceToken;
          }
          this._loggedIn = false;
          localStorage.clear();
          localStorage.setItem("fcmDeviceToken", deviceToken);
          this.commonbService.presentsToast(
            "success",
            "top-end",
            this.translate.instant("USER_LOGGED_OUT_SUCCESSFULLY")
          );
          this.router.navigate(["/"]);
        }
      });
    } else {
      var deviceToken;
      if (
        !(
          localStorage.fcmDeviceToken == null ||
          localStorage.fcmDeviceToken == undefined ||
          localStorage.fcmDeviceToken == ""
        )
      ) {
        deviceToken = localStorage.fcmDeviceToken;
      }
      this._loggedIn = false;
      localStorage.clear();
      localStorage.setItem("fcmDeviceToken", deviceToken);
      this.commonbService.presentsToast(
        "success",
        "top-end",
        this.translate.instant("USER_LOGGED_OUT_SUCCESSFULLY")
      );
      this.router.navigate(["/"]);
    }
  }
  forgetPassword(params) {
    this.https.httpPost("forgotPassword", params).subscribe((res: any) => {
      if (res.status === 200) {
        this.isForgot = "forgot";
        this.dialog.open(ModalPopupEmailComponent, {
          disableClose: true,
          height: "400px",
          width: "400px",
        });
        this.commonbService.presentsToast("success", "top-end", res.message);
      } else {
        this.commonbService.presentsToast("error", "top-end", res.message);
      }
    });
  }

  forgetPasswordReset(code, params) {
    this.https
      .httpPostWithHeaderParam("resetPassword", code, params)
      .subscribe((res: any) => {
        if (res.status === 200) {
          this.commonbService.presentsToast("success", "top-end", res.message);
          this.router.navigate(["/login"]);
        } else {
          this.commonbService.presentsToast("error", "top-end", res.message);
        }
      });
  }

  verifyEmail(code) {
    this.https
      .httpGetWithHeaderParam("verifyEmail", code)
      .subscribe((res: any) => {
        if (res.status === 200) {
          this.commonbService.presentsToast("success", "top-end", res.message);
        } else {
          this.commonbService.presentsToast("error", "top-end", res.message);
        }
      });
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isLoggedIn = this.authService.loggedIn;
    let userData = localStorage.userProfile
      ? JSON.parse(localStorage.userProfile)
      : "";
    if (next.routeConfig.path === "home" || next.routeConfig.path === "") {
      if (userData != "") {
        this.router.navigateByUrl("/signup-landing");
        return false;
      } else {
        return true;
      }
    } else {
      if (
        next.routeConfig.path.includes("profile-set") &&
        localStorage.isProfileSetupCompleted == "true" &&
        (!localStorage.accessToken ||
          localStorage.accessToken == null ||
          localStorage.accessToken == undefined ||
          localStorage.accessToken == "")
      ) {
        localStorage.clear();
        this.router.navigateByUrl("/");
        return false;
      }
      if (
        next.routeConfig.path.includes("profile-set") &&
        localStorage.isProfileSetupCompleted != "true" &&
        !(
          localStorage.accessToken == null ||
          localStorage.accessToken == undefined ||
          localStorage.accessToken == ""
        ) &&
        userData != ""
      ) {
        return true;
      }
      if (
        next.routeConfig.path.includes(
          "disclaimer-concent" ||
            "success-message" ||
            "login" ||
            "login-phone" ||
            "signup" ||
            "signup-landing"
        )
      ) {
        return true;
      } else {
        if (
          !next.routeConfig.path.includes(
            "disclaimer-concent" ||
              "success-message" ||
              "login" ||
              "login-phone" ||
              "signup"
          ) &&
          userData != ""
        ) {
          this.router.navigateByUrl("/signup-landing");
          return false;
        }

        if (
          !(
            localStorage.accessToken == null ||
            localStorage.accessToken == undefined ||
            localStorage.accessToken == ""
          ) &&
          userData == ""
        ) {
          return true;
        } else {
          localStorage.clear();
          this.router.navigateByUrl("/signup-landing");
          return false;
        }
      }
    }
  }
}
