import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "src/app/services/common/common.service";
import { HttpService } from "src/app/services/http/http.service";
import { environment } from "src/environments/environment";
import * as js from "../../../assets/js/custom.js";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AuthService } from "src/app/services/auth-guard/auth.service";
import { MatOption, MatSelect } from "@angular/material";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TranslateService } from "@ngx-translate/core";
import { element } from "protractor";
import all from "gsap/all";
declare var $: any;

interface days {
  days: string;
  value: any;
  checked: boolean;
}
interface studio {
  name: any;
  _id: any;
}
interface groupOption {
  label: any;
  groupValue: any;
}

@Component({
  selector: "app-tattoo-consultation",
  templateUrl: "./tattoo-consultation.component.html",
  styleUrls: ["./tattoo-consultation.component.scss"],
})
export class TattooConsultationComponent implements OnInit {
  time: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    public auth: AuthService,
    public commonbService: CommonService,
    public fb: FormBuilder,
    private ngxService: NgxUiLoaderService,
    public translate: TranslateService
  ) {
    var minutesToAdd = 15;
    var currentDate = new Date();
    var newDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
    var hours = newDate.getHours();
    var minutes = newDate.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    var minute = minutes < 10 ? '0' + minutes : minutes;
    this.time = hours + ':' + minute + ' ' + ampm;
  }
  lengthvalidation: boolean = false;
  isMatCheck: boolean = false;
  isValidateAsap: boolean = false;
  public proId: String = this.route.snapshot.paramMap.get("proid");
  public professional: any;
  PreferenceSelected: boolean = false;
  otherOption: boolean = false;
  allProfessional: any[] = [];
  allStudios: studio[] = [];
  studioId: any[] = [];
  artistsId: any[] = [];
  selectedDaysId: any[] = [];
  selectedDay: any[] = [];
  isDescription: boolean = false;
  isStudio: boolean = false;
  isArtist: boolean = false;
  totalUploads: any[] = [];
  favourites: any[] = [];
  studioGroup: groupOption[] = [];
  @ViewChild("mySel1", { static: false }) skillSel: MatSelect;
  @ViewChild("mySel", { static: false }) studioSel: MatSelect;
  imageUrl: string = environment.imageUrl;
  bookingForm: FormGroup;
  public innerHeight: any;
  public submitted: Boolean = false;
  fileData: any[] = [];
  allDataStudio: any[] = [];
  fileDataUrl: any[] = [];
  imgBaseUrl = environment.imageUrl;
  prefferdDays: days[] = [
    { days: "Sunday", value: 0, checked: false },
    { days: "Monday", value: 1, checked: false },
    { days: "Tuesday", value: 2, checked: false },
    { days: "Wednesday", value: 3, checked: false },
    { days: "Thursday", value: 4, checked: false },
    { days: "Friday", value: 5, checked: false },
    { days: "Saturday", value: 6, checked: false },
  ];
  selectedValue: any[] = [];
  selectedStudioValue: any[] = [];
  selectedArtistValue: any[] = [];
  lat: any;
  long: any;
  zoom = 4;
  markers: any[] = [];
  textChange: boolean = false;
  userData = localStorage.userProfileData
    ? JSON.parse(localStorage.userProfileData)
    : "";
  studiosGroup: any[] = [];
  changeString(data: any) {
    if (data !== undefined) {
      if (data.length <= 150) {
        this.isDescription = false;
        return data;
      } else {
        this.isDescription = true;
        return data.substr(0, 150) + "...";
      }
    }
  }
  ngOnInit(): void {
    js.customScript();
    this.getAllProfessional("");
    $("#desc").toggleClass("show-hide", false);

    this.innerHeight = window.innerHeight;
    this.bookingForm = this.fb.group({
      tattooType: new FormControl("", Validators.required),
      eighteenPlus: new FormControl("", Validators.required),
      bodyPlacement: new FormControl("", [
        Validators.required,
        Validators.pattern("^.{2,100}$"),
      ]),
      plannedSize: new FormControl("", [
        Validators.required,
       // Validators.min(1),
        //Validators.max(99),
      ]),
      tattooDesignIdea: new FormControl("", [
        Validators.required,
        Validators.pattern("^.{2,100}$"),
      ]),
      days: ["", null],
      fromDate: ["", null],
      toDate: ["", null],
      studios: ["", null],
      artists: ["", null],
      image: ["", null],
      note: new FormControl("", [
        Validators.pattern("^.{2,200}$"),
      ]),
      //description: new FormControl("", Validators.pattern("^.{2,200}$")),
      asapValue: new FormControl("", null),
    });
    hideChat(0);

    $("#prime").click(function () {
      toggleFab();
    });

    //Toggle chat and links
    function toggleFab() {
      $(".prime").toggleClass("zmdi-comment-outline");
      $(".prime").toggleClass("zmdi-close");
      $(".prime").toggleClass("is-active");
      $(".prime").toggleClass("is-visible");
      $("#prime").toggleClass("is-float");
      $(".chat").toggleClass("is-visible");
      $(".fab").toggleClass("is-visible");
    }

    $("#chat_first_screen").click(function (e) {
      hideChat(1);
    });

    $("#chat_second_screen").click(function (e) {
      hideChat(2);
    });

    $("#chat_third_screen").click(function (e) {
      hideChat(3);
    });

    $("#chat_fourth_screen").click(function (e) {
      hideChat(4);
    });

    $("#chat_fullscreen_loader").click(function (e) {
      $(".fullscreen").toggleClass("zmdi-window-maximize");
      $(".fullscreen").toggleClass("zmdi-window-restore");
      $(".chat").toggleClass("chat_fullscreen");
      $(".fab").toggleClass("is-hide");
      $(".header_img").toggleClass("change_img");
      $(".img_container").toggleClass("change_img");
      $(".chat_header").toggleClass("chat_header2");
      $(".fab_field").toggleClass("fab_field2");
      $(".chat_converse").toggleClass("chat_converse2");
    });

    function hideChat(hide) {
      switch (hide) {
        case 0:
          $("#chat_converse").css("display", "bloack");
      }
    }
  }

  slides = [
    { img: "http://placehold.it/350x150/000000" },
    { img: "http://placehold.it/350x150/111111" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/666666" },
  ];
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    infinite: false,
    autoplaySpeed: 1500,
    arrows: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  mainConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    variableWidth: true,
    infinite: false,
    autoplay: false,
    margin: 20,
    autoplaySpeed: 1500,
    arrows: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  toggleShowHide(e: any) {
    e.preventDefault();
    if (this.isDescription == true) {
      $("#desc").toggleClass("show-hide");
      $("#moreTe").toggleClass("allDesc");
      this.textChange = !this.textChange;
    }
  }

  addMarker(lat: any, lang: any) {
    console.log(lat);
  }

  onArtistClick() {
    this.isStudio = true;
  }

  onStudioClick() {
    this.isArtist = true;
  }

  selectMarker(event: any) {
    window.open(
      "https://www.google.com/maps?z=12&t=k&q=loc:" +
      event.latitude +
      "+" +
      event.longitude
    );
  }

  onFileChange(fileInput: any) {
    let fileData = Array.from(fileInput.target.files)
    if (fileData.length <= 5) {
      Array.prototype.forEach.call(fileData, (child) => {
        console.log(child);
        if (child.type.includes("image")) {
          if (this.fileData.length < 5) {
            this.fileData.push(child);
            this.fileData.reverse();
            var reader = new FileReader();
            reader.readAsDataURL(child);
            reader.onload = (_event) => {
              this.fileDataUrl.push(reader.result);
            };
            console.log(this.fileDataUrl);

          }
          else {
            this.commonbService.presentsToast(
              "error",
              "top-end",
              this.translate.instant("ONLY_FIVE_ALLOWED")
            );
          }
        }
        else {
          this.commonbService.presentsToast('error', 'top-end', this.translate.instant("ONLY_IMAGES"))
        }
      })
    }
    else {
      this.commonbService.presentsToast(
        "error",
        "top-end",
        this.translate.instant("ONLY_FIVE_ALLOWED")
      );
      this.fileData = [];
      this.fileDataUrl = [];
    }
  }
  removeImage(index: any) {
    this.fileDataUrl.splice(index, 1);
    this.fileData.splice(index, 1);
  }

  slickInit(event: any) { }

  getFavourite() {
  }

  submitBooking() {
    if (this.bookingForm.controls.asapValue.invalid) {
      $("#toggleDiv").addClass("active");
    } else if (this.bookingForm.controls.asapValue.valid) {
      $("#toggleDiv").removeClass("active");
    }
    this.submitted = true;
    this.isStudio = false;
    this.isArtist = false;
    if (
      this.bookingForm.valid &&
      this.bookingForm.value.eighteenPlus == "false"
    ) {
      this.commonbService.presentsToast(
        "error",
        "top-end",
        this.translate.instant("MINOR_EIGHTEEN")
      );
    }
    if (
      this.bookingForm.valid &&
      this.bookingForm.value.eighteenPlus == "true"
    ) {
      var formData: any = new FormData();
      formData.append("type", "TATTOO");
      formData.append("tattooType", this.bookingForm.value.tattooType);
      formData.append("eighteenPlus", this.bookingForm.value.eighteenPlus);
      if(!(this.bookingForm.value.note == undefined || this.bookingForm.value.note == null || this.bookingForm.value.note.trim() == ''))
      {
      formData.append("note", this.bookingForm.value.note.trim());
      }
      formData.append(
        "bodyPlacement",
        this.bookingForm.value.bodyPlacement.trim()
      );
      formData.append("plannedSize", this.bookingForm.value.plannedSize);
      formData.append(
        "tattooDesignIdea",
        this.bookingForm.value.tattooDesignIdea
      );
      if (this.fileData.length > 0) {
        for (let i = 0; i < this.fileData.length; i++) {
          formData.append("image", this.fileData[i]);
        }
      }
        formData.append("studios", JSON.stringify(this.studioId));
        formData.append("professionals", JSON.stringify(this.artistsId));
      if (this.selectedDaysId.length > 0) {
        formData.append("preferredDays", JSON.stringify(this.selectedDaysId));
      }
      if (
        !(
          this.bookingForm.value.asapValue == "" ||
          this.bookingForm.value.asapValue == undefined ||
          this.bookingForm.value.asapValue == null
        )
      ) {
        formData.append("additionalInfo", this.bookingForm.value.asapValue);
      }
      if (
        !(
          this.bookingForm.value.toDate.trim() == "" ||
          this.bookingForm.value.toDate.trim() == undefined ||
          this.bookingForm.value.toDate.trim() == null
        )
      ) {
        formData.append(
          "preferredToTime",
          this.bookingForm.value.toDate.trim()
        );
      }
      if (
        !(
          this.bookingForm.value.fromDate.trim() == "" ||
          this.bookingForm.value.fromDate.trim() == undefined ||
          this.bookingForm.value.fromDate.trim() == null
        )
      ) {
        formData.append(
          "preferredFromTime",
          this.bookingForm.value.fromDate.trim()
        );
      }
      if (!this.auth.loggedIn) {
        var tattoodata = {
          "tattooType": this.bookingForm.value.tattooType ? this.bookingForm.value.tattooType : '',
          "eighteenPlus": this.bookingForm.value.eighteenPlus ? this.bookingForm.value.eighteenPlus : '',
          "note": this.bookingForm.value.note.trim() ? this.bookingForm.value.note.trim() : '',
          "specificDateTime": this.bookingForm.value.dateTime ? this.bookingForm.value.dateTime : '',
          "studios": this.studioId.length > 0 ? this.studioId : [],
          "professionals": this.artistsId.length > 0 ? this.artistsId : [],
          bodyPlacement: this.bookingForm.value.bodyPlacement ? this.bookingForm.value.bodyPlacement.trim() : '',
          tattooDesignIdea: this.bookingForm.value.tattooDesignIdea ? this.bookingForm.value.tattooDesignIdea : '',
          image: this.fileData && this.fileData.length > 0 ? this.fileData : [],
          preferredToTime: this.bookingForm.value.toDate ? this.bookingForm.value.toDate.trim() : '',
          preferredFromTime: this.bookingForm.value.fromDate ? this.bookingForm.value.fromDate.trim() : '',
          preferredDays: this.selectedDaysId.length > 0 ? this.selectedDaysId : [],
          additionalInfo: this.bookingForm.value.asapValue ? this.bookingForm.value.asapValue : '',
          "plannedSize": this.bookingForm.value.plannedSize ? this.bookingForm.value.plannedSize : ''
        }
        this.commonbService.allTattooData = tattoodata;
        this.commonbService.presentsToast('error', 'top-end', this.translate.instant("LOGIN_FIRST"))
        this.router.navigateByUrl('/signup-landing')
      }
      else {
        this.ngxService.start();
        this.http
          .httpPostWithHeaderFormDataPromise("bookingForm", formData)
          .then((res: any) => {
            this.ngxService.start();
            if (res.status == 200) {
              this.bookingForm.reset();
              this.fileData = [];
              this.fileDataUrl = [];
              this.submitted = false;
              this.ngxService.stop();
              if (res.result.disclaimerFormFilled != true) {
                this.commonbService.confirmToast("/disclaimer-concent/", this.userData._id,"customer", "web") 
             }
              else
              {
                this.commonbService.presentsToast(
                  "success",
                  "top-end",
                  "Inquiry has been successfully submitted and we will get back to you shortly"
                );
                this.router.navigateByUrl('/appointments')
              }
            }
          }).catch(err => {
            this.ngxService.stop();
          });
      }
    }
  }

  getAllProfessional(param: any) {
    var query = "artistType=TATTOO";
    this.http
      .httpostWithoutQueryHeader("professionals", query)
      .subscribe((res: any) => {
        if (res.status == 200) {
          console.log(res);
          this.allProfessional = res.result;
          console.log(this.allProfessional)
          if (!(this.commonbService.allTattooData == undefined || this.commonbService.allTattooData == '' || this.commonbService.allTattooData == null)) {
            let allData = this.commonbService.allTattooData
            var professionalData: any[] = [];
            professionalData = this.allProfessional.filter(val => allData.professionals.includes(val._id));
            var professionalDataArray = {
              value: professionalData
            }
            this.allDataStudio = allData.studios && allData.studios.length > 0 ? allData.studios : [];
            if (!(professionalDataArray == undefined || professionalDataArray == null) && professionalDataArray.value.length > 0) {
              this.onArtistSelection(professionalDataArray);
            }
            if (allData.preferredDays.length > 0) {
              var allSelectedday = this.prefferdDays.filter(days => allData.preferredDays.includes(days.value))
              allSelectedday.forEach((day: any) => {
                for (let i = 0; i < this.prefferdDays.length; i++) {
                  if (this.prefferdDays[i].value == day.value) {
                    this.prefferdDays[i].checked = true;
                    this.selectedDaysId.push(this.prefferdDays[i].value);
                    this.selectedDay.push(this.prefferdDays[i].days);
                  }
                }
              })
            }

            var allFiles = allData.image ? allData.image : ''
            var files = {
              target: {
                files: allFiles
              }
            }
            this.onFileChange(files)
            this.bookingForm.patchValue({
              tattooType: allData.tattooType ? allData.tattooType : '',
              eighteenPlus: allData.eighteenPlus ? allData.eighteenPlus : '',
              note: allData.note ? allData.note : '',
              //description: allData.description ? allData.description : '',
              dateTime: allData.specificDateTime ? new Date(allData.specificDateTime) : '',
              bodyPlacement: allData.bodyPlacement ? allData.bodyPlacement : '',
              plannedSize: allData.plannedSize ? allData.plannedSize : '',
              tattooDesignIdea: allData.tattooDesignIdea ? allData.tattooDesignIdea : '',
              days: allData.preferredDays.length > 0 ? allSelectedday : '',
              fromDate: allData.preferredFromTime ? allData.preferredFromTime : '',
              toDate: allData.preferredToTime ? allData.preferredToTime : '',
              image: allData.image ? allData.image : '',
              asapValue: allData.additionalInfo ? allData.additionalInfo : '',
            })
            console.log(this.bookingForm.value)
          }
        }
      });
  }

  getAllStudios() {
    this.http
      .httpGetWithoutHeader("allprofessionalStudio", "")
      .subscribe((res: any) => {
        if (res.status == 200) {
          if (this.studioGroup.length > 0) {
            this.studioGroup.forEach((element) => {
              if (
                ((this.selectedStudioValue.length == 1 &&
                  this.selectedStudioValue[0] == "No Preferences") ||
                  this.selectedStudioValue.length == 0) &&
                element.label !== "All Studios"
              ) {
                this.studioGroup.push({
                  label: "All Studios",
                  groupValue: res.result,
                });
              }
            });
          } else {
            this.studioGroup.push({
              label: "All Studios",
              groupValue: res.result,
            });
          }
        }
      });
  }

  download(path: any) {
    window.open(path, "_blank");
  }

  addRemoveFavourite(id: any, isFavourite: any, professionalId: any) {
    if (isFavourite == true) {
      var query = "id=" + id;
      this.http.httpDeleteWithid("getFavorite", query).subscribe((res: any) => {
        if (res.status == 200) {
        }
      });
    }
    if (isFavourite == false) {
      var param = {
        professional: professionalId,
        item: id,
      };
      this.http
        .httpPostWithHeader("getFavorite", param)
        .subscribe((res: any) => {
          if (res.status == 200) {
          }
        });
    }
  }

  onStudioSelection(event: any) {
    this.selectedStudioValue = event.value;
    this.studioId = event.value
    console.log(this.studioId)
    var param = {
      studios: this.studioId,
    };
  }

  onArtistSelection(event: any) {
    this.selectedArtistValue = [];
    this.studioGroup = [];
    let studios = [];
    this.artistsId = [];
    let allStudios:any[] = [];
    this.selectedArtistValue = event.value;
    this.selectedArtistValue.forEach((item) => {
      this.artistsId.push(item._id)
      item.studio.forEach(element => {
        studios.push({ label: element.name, groupValue: element._id })
      });
    })
    
    for(let i = 0;i < studios.length; i++)
    {
      if(this.studioGroup.length == 0)
      {
        this.studioGroup.push({label: studios[i].label, groupValue: studios[i].groupValue})
      }
      else{
      allStudios = [];
      allStudios = this.studioGroup.map(val => val.groupValue)
       if(!(allStudios.includes(studios[i].groupValue)))
       {
        this.studioGroup.push({label: studios[i].label, groupValue: studios[i].groupValue})
       }
      }
    }

    if (!(this.commonbService.allTattooData == undefined || localStorage.allTattooData == '' || this.commonbService.allTattooData == null)) {
      var allProfessionals = this.allProfessional.filter(values => this.artistsId.includes(values._id))
      this.bookingForm.controls.artists.setValue(allProfessionals);
      this.bookingForm.controls.artists.updateValueAndValidity();
      let studioValue = [];
      this.studioGroup.forEach(item => {
        studioValue.push(item.groupValue)
      })
      let studioId = []
      this.studioGroup.forEach((c) => {
        if (!studioId.includes(c.groupValue)) {
          studioId.push(c.groupValue)
        }
      });
      if (this.studioGroup.length > 0) {
        var selectedStudio: any[] = [];
        selectedStudio = this.studioGroup.filter(val => this.allDataStudio.includes(val.groupValue))
        var allStudiodata = {
          value: selectedStudio
        }
        if (!(allStudiodata == undefined || allStudiodata == null) && allStudiodata.value.length > 0) {
          this.bookingForm.controls.studios.setValue(this.allDataStudio);
          this.bookingForm.controls.studios.updateValueAndValidity();
          this.onStudioSelection(allStudiodata);
        }
      }
    }

  }

  onOtherOption() {
    this.skillSel.options.forEach((item: MatOption, i: any) => {
      if (item.value == "No Preferences") {
        item.deselect();
      }
    });
  }

  onNoPreferenceSelection() {
    this.PreferenceSelected = true;
    if (this.PreferenceSelected) {
      this.skillSel.options.forEach((item: MatOption, i: any) => {
        if (item.value == "No Preferences") {
          item.select();
        } else {
          item.deselect();
        }
      });
    } else {
      this.skillSel.options.forEach((item: MatOption, i: any) => {
        if (item.value == "No Preferences") {
          item.deselect();
        }
      });
    }
    this.skillSel.close();
  }

  toggleClass = (event) => {
    event.target.classList.toggle("my-class");
  };

  onSelection(event: any, index: any, value: any) {
    if (event.checked) {
      this.selectedDaysId.push(value.value);
      this.selectedDay.push(value.days);
      this.isMatCheck = false;
      this.isValidateAsap = false;
      this.bookingForm.controls.asapValue.reset();
      this.bookingForm.controls.asapValue.setValidators(null);
      this.bookingForm.controls.asapValue.updateValueAndValidity();
      this.prefferdDays[index].checked = true;
    } else {
      this.isValidateAsap = false;
      let getIndex = this.selectedDaysId.findIndex((x) => x == value.value);
      this.selectedDaysId.splice(getIndex, 1);
      this.selectedDay.splice(getIndex, 1);
      this.prefferdDays[index].checked = false;
    }
    console.log(this.selectedDaysId);
    console.log(this.selectedDay);
  }

  selectionCheckBox(event: any) {
    if (event.checked) {
      this.selectedDaysId = [];
      this.selectedDay = [];
      this.isMatCheck = true;
      this.bookingForm.controls.asapValue.setValidators(Validators.required);
      this.bookingForm.controls.asapValue.updateValueAndValidity();
      this.prefferdDays.forEach((res) => {
        res.checked = false;
      });
      if (this.bookingForm.controls["asapValue"].hasError("required")) {
        this.isValidateAsap = true;
      } else {
        this.isValidateAsap = false;
      }
    } else {
      this.isMatCheck = false;
      this.isValidateAsap = false;
      this.bookingForm.controls.asapValue.reset();
      this.bookingForm.controls.asapValue.setValidators(null);
      this.bookingForm.controls.asapValue.updateValueAndValidity();
    }
    console.log(this.selectedDaysId);
  }

  checkValidation(event: any) {
    if (
      !(
        this.bookingForm.value.asapValue == "" ||
        this.bookingForm.value.asapValue == undefined ||
        this.bookingForm.value.asapValue == null
      )
    ) {
      this.isValidateAsap = false;
      if (this.bookingForm.value.asapValue.toString().length > 60) {
        this.lengthvalidation = true;
      } else {
        this.lengthvalidation = false;
      }
    } else {
      this.isValidateAsap = true;
    }
  }

  avoidMinusAndDecimal(event:any)
  {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      charCode == 45 || charCode == 46 ||
      charCode == 101 
    ) {    
      return false;
    }
  }

  avoidMinusAndDecimalandRestrictTo(event:any)
  {
    const number = this.bookingForm.value.plannedSize;
    const charCode = event.which ? event.which : event.keyCode;
    if (
      charCode == 45 || charCode == 46 ||
      charCode == 101 || (!(this.bookingForm.value.plannedSize == undefined || this.bookingForm.value.plannedSize == null || this.bookingForm.value.plannedSize == '') && number.toString().length > 3)
    ) {    
      return false;
    }
  }

  toggleTaegetDiv() {
    $(document).ready(function (ex) {
      $(document)
        .off()
        .on("click", function (e) {
          if (
            e &&
            e.target &&
            e.target.className &&
            e.target.className == "form-control preffrd_day_chsse prefferedDayP"
          ) {
            $("#toggleDiv").toggleClass("active");
          } else if (
            (e &&
              e.target &&
              e.target.className &&
              e.target.className == "mat-checkbox-inner-container") ||
            (e &&
              e.target &&
              e.target.className &&
              e.target.className == "preffrd_day_chsse_innr mat_check_div") ||
            (e &&
              e.target &&
              e.target.className &&
              e.target.className == "prfrdday_chslst mat_check_innerdiv") ||
            (e &&
              e.target &&
              e.target.className &&
              e.target.className == "example-margin mat_check_custom") ||
            (e &&
              e.target &&
              e.target.className &&
              e.target.className == "prfrdday_chslst prfrdday_chslst_input") ||
            (e &&
              e.target &&
              e.target.className &&
              e.target.className == "example-margin custom_check") ||
            (e &&
              e.target &&
              e.target.className &&
              e.target.className ==
              "prfrdday_chslst mat_check_innerdiv ng-star-inserted") ||
            (e &&
              e.target &&
              e.target.className &&
              e.target.className == "mat-checkbox-label") ||
            (e &&
              e.target &&
              e.target.className &&
              e.target.className ==
              "mat-checkbox-inner-container mat-checkbox-inner-container-no-side-margin") ||
            (e &&
              e.target &&
              e.target.className &&
              e.target.className.includes("inside_check_input"))
          ) {
            $("#toggleDiv").addClass("active");
          } else if (
            e &&
            e.target &&
            e.target.className &&
            e.target.className == "btn btn-primary"
          ) {
            $("#submit_btn").click()
          } else {
            $("#toggleDiv").removeClass("active");
          }
        });
    });
  }
}
