import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { param } from "jquery";
import { HttpService } from "src/app/services/http/http.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.scss"],
})
export class GalleryComponent implements OnInit {
  chatId: any;
  professionalId:any;
  imageVideo: any[] = [];
  docFile: any[] = [];
  bookings: any[] = [];
  imagebaseUrl: any = environment.imageUrl;
  constructor(private http: HttpService, private route: ActivatedRoute, public translate: TranslateService,private router:Router) {
    this.chatId = this.route.snapshot.paramMap.get("chatId");
    this.professionalId = this.route.snapshot.paramMap.get("id")
  }

  ngOnInit() {
    this.getAllChatImageMedia();
    this.getAllBookings();
  }

  download(path: any) {
    window.open(path, "_blank");
  }

  getAllChatVideoMedia() {
    var param = "type=VIDEO";
    this.http
      .httpGetWithHeaderParamandQuery("getAllChatMedia", this.chatId, param)
      .subscribe((res: any) => {
        if (res.status == 200) {
          res.result.forEach((item: any) => {
            item.uploads.forEach((data: any) => {
              this.imageVideo.push(data);
            });
          });
        }
      });
  }

  getAllChatImageMedia() {
    var param = "type=IMAGE";
    this.http
      .httpGetWithHeaderParamandQuery("getAllChatMedia", this.chatId, param)
      .subscribe((res: any) => {
        if (res.status == 200) {
          console.log(res);
          res.result.forEach((item: any) => {
            item.uploads.forEach((data: any) => {
              this.imageVideo.push(data);
            });
          });
          this.getAllChatVideoMedia();
        }
      });
  }

  spliceCharacter(data: any) {
    return data.toString().slice(11);
  }

  getAllBookings() {
    this.http
      .httpGetWithHeaderParam("getAllBokkings", this.professionalId)
      .subscribe((res: any) => {
        if (res.status == 200) {
          console.log(res);
          this.bookings = res.result;
        }
      });
  }

  onScroll2() {
  }

  onScrollUp2() { }

  onScroll3() {
  }

  onScrollUp3() { }
  navigate(data:any)
  {
    this.router.navigate(['/bookingdetail/' + data._id]);
  }
}
