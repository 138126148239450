import { Component, OnInit, NgZone } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  SearchCountryField,
  CountryISO,
} from "ngx-intl-tel-input";
import { AuthService } from "../../services/auth-guard/auth.service";
import { CommonService } from "src/app/services/common/common.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { HttpService } from "src/app/services/http/http.service";
import { JwtHelperService } from "@auth0/angular-jwt";
declare var window: any;

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  jwtHelper = new JwtHelperService();
  timeLeft: number = 60;
  interval;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = false;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  otpType: "phone";
  deviceType: "WEB";
  deviceToken: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
  submitted: boolean = false;
  isPhoneVerify: Boolean = false;
  signUpForm: FormGroup;
  public innerHeight: any;
  pattern: any = /^.*(?=.{8,15})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!-_]).\S*$/;

  constructor(
    private ngZone: NgZone,
    private http: HttpService,
    private auth: AuthService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    public translate: TranslateService
  ) {  }

  ngOnInit(): void {
    if (this.auth.loggedIn) {
      this.router.navigate(["/"]);
    }
    this.innerHeight = window.innerHeight;
    this.signUpForm = this.fb.group(
      {
        email: [
          "",
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")
          ],
        ],

        phone: ["", Validators.required],
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(this.pattern),
          ],
        ],
        cpassword: ["", Validators.required],
      },
      { validators: this.checkPasswords }
    );
    this.googleLogin();
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 60;
      }
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  signupSendOtp() {
    this.submitted = true;
    if (this.signUpForm.valid) {
      var phoneCode: any;
      var diaCode = this.signUpForm.value.phone.dialCode;
      if (diaCode.includes('+')) {
        phoneCode = diaCode.slice(1);
      } else {
        phoneCode = diaCode;
      }
      var codeLength = this.signUpForm.value.phone.dialCode.length;
      var param = $.extend(
        {},
        {
          phone: this.signUpForm.value.phone.e164Number.slice(codeLength),
          countryCode: phoneCode,
          email: this.signUpForm.value.email.trim()
        }
      );
      this.auth.signupSendOtp(param.phone, param.countryCode, param.email,'');
      this.commonService.modalData = this.signUpForm;
    }
  }

  signup() {
    var phoneCode: any;
    var diaCode = this.signUpForm.value.phone.dialCode;
    if (diaCode.includes('+')) {
      phoneCode = diaCode.slice(1);
    } else {
      phoneCode = diaCode;
    }
    var codeLength = this.signUpForm.value.phone.dialCode.length;
    var params = $.extend(
      {},
      {
        phone: this.signUpForm.value.phone.e164Number.slice(codeLength),
        countryCode: phoneCode,
        email: this.signUpForm.value.email,
        password: this.signUpForm.value.password,
        code: "1234",
        deviceType: "IOS",
        deviceToken: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      }
    );
    this.auth.signupVerifyOtp(params);
  }

  checkPasswords(group: FormGroup) {
    const password = group.get("password").value;
    const confirmPassword = group.get("cpassword").value;
    return password === confirmPassword ? null : { notSame: true };
  }

  googleLogin() {
    window.google.accounts.id.initialize({
      client_id:
        "752663413722-r847f254bha3scnb71vpt5u9g1ek04fq.apps.googleusercontent.com",
      scope: " email profile",
      callback: ((window as any)["handleCredentialResponse"] = (
        response: any
      ) =>
        this.ngZone.run(
          () => {
            console.log(
              "this response holds the token for the logged in user information",
              response
            );
            const decodedToken = this.jwtHelper.decodeToken(
              response.credential
            );
            console.log(decodedToken);
            const { given_name, family_name, email, picture, sub } =
              decodedToken;
            let body = {
              firstName: given_name,
              lastName: family_name,
              email: email,
              image: picture,
              id: sub,
              provider: "GOOGLE",
              deviceType: "web",
            };
            if (
              !(
                localStorage.fcmDeviceToken == null ||
                localStorage.fcmDeviceToken == undefined ||
                localStorage.fcmDeviceToken == ""
              )
            ) {
              this.deviceToken = localStorage.fcmDeviceToken;
              console.log(this.deviceToken);
            }
            this.http.httpPost("socialLogin", body).subscribe((res: any) => {
                localStorage.setItem("accessToken", res.result.Authorization);
                this.router.navigateByUrl('/')
            });
          },
          (error) => {
            alert("error" + JSON.stringify(error));
          }
        )),
    });
    window.google.accounts.id.renderButton(
      document.getElementById("googleButton"),
      {
        type: "icon",
        shape: "square",
        width: "60px",
      }
    );
  }
}
