import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../../services/auth-guard/auth.service";


@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public innerHeight: any;
  loginForm: FormGroup;
  submitted: boolean = false;
  deviceToken: any = "";
  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private router: Router,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.innerHeight = window.innerHeight;
    this.loginForm = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
          ),
        ],
      ],
      password: ["", Validators.required],
    });
  }

  login() {
    if (
      !(
        localStorage.fcmDeviceToken == null ||
        localStorage.fcmDeviceToken == undefined ||
        localStorage.fcmDeviceToken == ""
      )
    ) {
      this.deviceToken = localStorage.fcmDeviceToken;
      console.log(this.deviceToken);
    }
    if (this.auth.loggedIn) {
      this.router.navigate(["/"]);
    }
    this.submitted = true;
    if (this.loginForm.valid) {
      var param = $.extend(
        {},
        {
          email: this.loginForm.value.email,
          password: this.loginForm.value.password,
          deviceType: "WEB",
          deviceToken: this.deviceToken,
        }
      );
      this.auth.logInEmail(param);
    }
  }



  avoidSpace(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 32) return false;
  }
}
