import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/services/auth-guard/auth.service";
import { ChatService } from "src/app/services/chat/chat.service";
import { CommonService } from "src/app/services/common/common.service";
import { HttpService } from "src/app/services/http/http.service";
import { NotifySocketService } from "src/app/services/notify-socket.service";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit,OnDestroy {
  linkArr = [];
  timeLeft: number = 60;
  interval;
  isNotificationCount: any = "";
  isNotification: boolean = false;
  userData: any = localStorage.userProfileData
    ? JSON.parse(localStorage.userProfileData)
    : {};
  imgBaseUrl = environment.imageUrl;
  userImage: string = this.userData.image;
  loggedIn: boolean = this.auth.loggedIn == true ? true : false;
  isUserData: any = localStorage.userData
    ? JSON.parse(localStorage.userData)
    : "";
    navigationSubscription:any;
  constructor(
    private router: Router,
    private auth: AuthService,
    private commonService: CommonService,
    private http: HttpService,
    private notifySocke: NotifySocketService,
    public translate: TranslateService,
    private chatService: ChatService
  ) {
    this.http.isUserDataupdated.subscribe((res: any) => {
      if (res == true) {
        this.userData = localStorage.userProfileData
          ? JSON.parse(localStorage.userProfileData)
          : {};
        this.userImage = this.userData.image;
      }
    });
    if (
      !(
        localStorage.accessToken == undefined ||
        localStorage.accessToken == null ||
        localStorage.accessToken == ""
      )
    ) {
      this.notifySocke.initSocket();
      this.chatService.initSocket('', {})
    }
    this.notifySocke.isNotificationtruefalse.subscribe((response) => {
      if (response == true) {
        this.isNotification = true;
        this.notifySocke.isNotificationData.subscribe((res: any) => {
          this.isNotificationCount = res.count;
        });
      }
      else {
        this.isNotification = false;
        this.isNotificationCount = "";
      }
    });

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });

    this.auth.isLoggedIn.subscribe((res:any)=>{
      if(res == true)
      {
        this.auth.isUserDataSubject.subscribe((response:any)=>{
          this.isUserData = response;
        })
      }
    })
  }



  initialiseInvites() {
    if (
      !(
        localStorage.accessToken == undefined ||
        localStorage.accessToken == null ||
        localStorage.accessToken == ""
      )
    ) {
      this.notifySocke.initSocket();
      this.chatService.initSocket('', {})
    }
    // Set default values and re-fetch any data you need.
  }
  ngOnDestroy() {
     // avoid memory leaks here by cleaning up after ourselves. If we
     // don't then we will continue to run our initialiseInvites()
     // method on every navigationEnd event.
     if (this.navigationSubscription) {
        this.navigationSubscription.unsubscribe();
     }
   }

   viewProfile() {
    this.router.navigate(['/edit-profile'])
   }


  goToNotification() {
    if (this.loggedIn) {
      this.router.navigate(["/notification"]);
    } else {
      this.commonService.presentsToast(
        "error",
        "top-end",
        this.translate.instant("LOGIN_FIRST")
      );
    }
  }

  navigateToChat() {
    if (this.loggedIn) {
      this.router.navigate(["/chat"]);
    } else {
      this.commonService.presentsToast(
        "error",
        "top-end",
        this.translate.instant("LOGIN_FIRST")
      );
    }
  }

  ngOnInit() {
    this.linkArr = [
      { name: "Home", isActive: false, route: "/home" },
      { name: "Instruction", isActive: false, route: "/instruction" },
      { name: "Get Started", isActive: false, route: "/getstart" },
      { name: "Contact Us", isActive: false, route: "" },
    ];
  }

  navChange(data) {
    this.linkArr.forEach((element) => {
      element.isActive = false;
    });
    data.isActive = true;
  }

  goToContactSection() {
    this.commonService.scrollToContactUs.next(true);
  }

  goTogetstart() {
    this.router.navigate(["/getstart"]);
  }
  goToinstruction() {
    this.router.navigate(["/instruction"]);
  }
  goTohome() {
    this.router.navigate(["/home"]);
  }
  onLogout() {
    if (localStorage.accessToken !== undefined) {
      this.http.httpGetWithHeader("logout", "").subscribe((res: any) => {
        if (res) {
          var deviceToken;
          if (
            !(
              localStorage.fcmDeviceToken == null ||
              localStorage.fcmDeviceToken == undefined ||
              localStorage.fcmDeviceToken == ""
            )
          ) {
            deviceToken = localStorage.fcmDeviceToken;
          }
          this.auth._loggedIn = false;
          this.loggedIn = false;
          localStorage.clear();
          localStorage.setItem("fcmDeviceToken", deviceToken);
          this.commonService.presentsToast(
            "success",
            "top-end",
            this.translate.instant("USER_LOGGED_OUT_SUCCESSFULLY")
          );
          this.router.navigate(["/"]);
          this.auth.isLoogedOut.next(true)
        }
      });
    } else {
      var deviceToken;
      if (
        !(
          localStorage.fcmDeviceToken == null ||
          localStorage.fcmDeviceToken == undefined ||
          localStorage.fcmDeviceToken == ""
        )
      ) {
        deviceToken = localStorage.fcmDeviceToken;
      }
      this.auth._loggedIn = false;
      localStorage.clear();
      localStorage.setItem("fcmDeviceToken", deviceToken);
      this.commonService.presentsToast(
        "success",
        "top-end",
        this.translate.instant("USER_LOGGED_OUT_SUCCESSFULLY")
      );
      this.router.navigate(["/"]);
      this.auth.isLoogedOut.next(true)
    }
  }
}
