import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from "src/app/services/auth-guard/auth.service";
import { CommonService } from "src/app/services/common/common.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-modal-popup",
  templateUrl: "./modal-popup.component.html",
  styleUrls: ["./modal-popup.component.scss"],
})
export class ModalPopupComponent implements OnInit {
  @Input() message: string;
  timeLeft: number = 60;
  interval;
  submitted: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<MatDialog>,
    private commonService: CommonService,
    private auth: AuthService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.startTimer();
  }
  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 0;
      }
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  signupSendOtp(text:any) {
    this.dialogRef.close()
    this.submitted = true;

    var phoneCode: any;
      var diaCode = this.commonService.modalData.value.phone.dialCode;
      if (diaCode.includes('+')) {
        phoneCode = diaCode.slice(1);
      } else {
        phoneCode = diaCode;
      }
    var codeLength = this.commonService.modalData.value.phone.dialCode.length;
    var param = $.extend(
      {},
      {
        phone: this.commonService.modalData.value.phone.e164Number.slice(
          codeLength
        ),
        countryCode: phoneCode,
        email:this.commonService.modalData.value.email.trim()
      }
    );

    this.auth.signupSendOtp(param.phone, param.countryCode,param.email,text);
    this.startTimer();
  }

  onOtpInput(otp) {
    if (otp.length === 4) {
      if(this.commonService.modalData && this.commonService.modalData.isFor && this.commonService.modalData.isFor == 'phone'){
        var param = $.extend(
          {},
          {
            phone: this.commonService.modalData.phone,
            countryCode: this.commonService.modalData.countryCode,
            code: otp
          }
        );
        this.auth.VerifyProfileOtp(param,'phone');
      }
      if(this.commonService.modalData && this.commonService.modalData.isFor && this.commonService.modalData.isFor == 'email'){
        var data = $.extend(
          {},
          {
            email:this.commonService.modalData.email,
            code: otp
          }
        );
        this.auth.VerifyProfileOtp(data,'email');
      }
      else
      {
      var phoneCode: any;
      var diaCode = this.commonService.modalData.value.phone.dialCode;
      if (diaCode.includes('+')) {
        phoneCode = diaCode.slice(1);
      } else {
        phoneCode = diaCode;
      }
      var codeLength = this.commonService.modalData.value.phone.dialCode.length;
      var params = $.extend(
        {},
        {
          phone: this.commonService.modalData.value.phone.e164Number.slice(
            codeLength
          ),
          countryCode: phoneCode,
          email: this.commonService.modalData.value.email,
          password: this.commonService.modalData.value.password,
          code: otp,
          deviceType: "WEB",
          deviceToken: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        }
      );
      this.auth.signupVerifyOtp(params);
      }
    }
  }
}
