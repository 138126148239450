import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CommonService } from "src/app/services/common/common.service";
import { HttpService } from "src/app/services/http/http.service";
import { environment } from "src/environments/environment";
import { Location } from "@angular/common";
import { SocketService } from "src/app/services/socket.service";
import { FormControl } from "@angular/forms";
import { CroppedEvent } from "ngx-photo-editor";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
declare var $: any;

@Component({
  selector: "app-chat2",
  templateUrl: "./chat2.component.html",
  styleUrls: ["./chat2.component.scss"],
})
export class Chat2Component implements OnInit,OnDestroy {
  imageChangedEvent: any;
  base64: any;
  allUserList: any[] = [];
  userChat: any[] = [];
  newMessage: string = "";
  messageList: string[] = [];
  imageBaseUrl: any = environment.imageUrl;
  userId: any;
  chatId: any;
  chatResponse: any;
  userChatResponse: any;
  fileData: File;
  file: any[] = [];
  imageUrl: any = "";
  fileDataArray: any[] = [];
  imageFileData: any[] = [];
  page: any = 1;
  index: any;
  totalPage: any;
  editedFile: any;
  alldatapage: any;
  professionaldata: any;
  professionalid: any;
  bookingId: any;
  @ViewChild("scroll", { static: true }) scroll: any;
  imgBaseUrl = environment.imageUrl;
  userData: any = localStorage.userProfileData
    ? JSON.parse(localStorage.userProfileData)
    : {};
  previousScrollHeightMinusTop: any = 0;
  previousScrollupHeightMinusTop: any = 0;
  pageNo: any = 1;
  fileDataUrl: any[] = [];
  imgUrl: any;
  search: FormControl = new FormControl("", null);
  @ViewChild("myfile", { static: false }) myfile: ElementRef;
  allOffer:any[]=[];
  bookingData:any;
  isUserClicked:boolean = false;
  isBookingSelected:boolean = false;
  unreadCount: any;

  constructor(
    private _location: Location,
    private http: HttpService,
    private socket: SocketService,
    private common: CommonService,
    private router: Router,
    public translate: TranslateService,
    private _cdr: ChangeDetectorRef
  ) {
    this.socket.isUserData.subscribe((res: any) => {
      this.userChat.push(res);
    });
  }

  goSearch() {
   this.isUserClicked = false;
   this.userChat = [];
    this.pageNo = 1;
    this.getAllClientData(this.pageNo,this.search.value)
  }

  selectedProfessioanl(index:any)
  {
    this.allUserList.forEach((element,i) => {
      $('#list' + i).removeClass('activeList')
    });
    $('#list'+index).addClass('activeList')
  }

  download(path: any) {
    window.open(path, "_blank");
  }

  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: CroppedEvent) {
    this.base64 = event.base64;
  }

  ngOnInit(): void {
    this.userId = this.userData._id;
    var connection = "connectToChat";
    var payload = {
      chatId: 5664545,
    };
    this.socket.initSocket(connection, payload);
    this.getAllClientData(this.pageNo,'');
  }

  onScroll() { }

  changeText(text: string) {
    if (!text.includes("\n") && text.length > 50) {
      let textDataNo = text.length / 50;
    }
  }

  onScrollUp() {
    this.page = this.page + 1;
    if (this.totalPage >= this.page) {
      var param = "id=" + this.chatId + "&" + "page=" + this.page + "&" + "limit=1000";
      this.http
        .httpGetWithHeader("getAllProfessioanlChatList", param)
        .subscribe((res: any) => {
          let chatData: any[] = [];
          chatData = res.result.messages;
          chatData.forEach((item: any) => {
            this.userChat.unshift(item);
          });
          console.log(this.userChat);
          this.totalPage = res.result.count;
        });
    }
  }

  onScrollchat() {
    this.pageNo = this.pageNo + 1;
    if (this.alldatapage >= this.pageNo) {
      var param = "page=" + this.pageNo;
      if(!(this.search.value == undefined || this.search.value == null || this.search.value == ''))
      {
        param = param + '&' + 'search=' + this.search.value
      }
      this.http
        .httpGetWithHeader("getAllUserChat", param)
        .subscribe((res: any) => {
            res.result.forEach((item: any) => {
            this.allUserList.push(item);
          });
          this.alldatapage = res.count;
        });
    }
  }

  navigate() {
    this.router.navigate([
      "disclaimer-concent/" + this.userId + "/" + "customer/" + "web",
    ]);
  }

  onScrollUpchat() { }

  getUpload(event) {
    if (event.target.files && event.target.files) {
      let fileData: any[] = [];
      fileData = event.target.files;
      this.file = Array.from(fileData);
      if (this.file.length <= 5) {
        Array.prototype.forEach.call(this.file, (child) => {
          console.log(child);
          if (child.type.includes("image")) {
            if (this.imageFileData.length < 3) {
              var reader = new FileReader();
              this.imageFileData.push(child);
              reader.readAsDataURL(child);
              reader.onload = (_event) => {
                this.fileDataUrl.push(reader.result);
              };
            } else {
              this.common.presentsToast(
                "error",
                "top-end",
                this.translate.instant("THREE_IMAGE_ALLOWED")
              );
            }
          }

          else if (child.type.includes("video")) {
            // reader.readAsDataURL(child);
            // reader.onload = (_event) => {
              var duration: any;
							new Audio(URL.createObjectURL(child)).onloadedmetadata = (e: any) => {
								duration = e.currentTarget.duration;
								console.log('duration', duration)
							  };
              if (duration > 30) {
                this.common.presentsToast(
                  "error",
                  "top-end",
                  this.translate.instant("MAXIMUM_THIRTY_SECOND_VIDEO")
                );
              }
              else {
                let formData = new FormData();
                formData.append("file", child);
                formData.append("chatId", this.chatId);
                this.http
                  .httpPostWithHeaderFormData("chatImageUpload", formData)
                  .subscribe((res: any) => {
                    if (res.status == 200) {
                      console.log(res);
                      this.myfile.nativeElement.value = "";
                      this.file = [];
                    }
                  });
              }
           // };
          }

          else {
            let formData = new FormData();
            formData.append("file", child);
            formData.append("chatId", this.chatId);
            this.http
              .httpPostWithHeaderFormData("chatImageUpload", formData)
              .subscribe((res: any) => {
                if (res) {
                  console.log("success", res);
                  this.myfile.nativeElement.value = "";
                  this.file = [];
                }
              });
          }
        });
      } else {
        this.common.presentsToast(
          "error",
          "top-end",
          this.translate.instant("ONLY_FIVE_IMAGE")
        );
      }
    }
  }

  getAllClientData(page: any,search:any) {
    var param = "page=" + page + "&search=" + search;
    this.http
      .httpGetWithHeader("getAllUserChat", param)
      .subscribe((res: any) => {
        this.allUserList = res.result.chats;
        this.alldatapage = res.count;
        this.page = 1;

      if (this.allUserList.length > 0) {
        this.chatId = this.allUserList[0]._id;

        // Loop through all users to get unreadCount
        for (const user of this.allUserList) {
          const unreadCount = user.unreadCount;
          console.log(`Unread count for user ${user._id}: ${unreadCount}`, "UNNNNREADDD6756275");
        }

       // $('#list'+0).addClass('activeList')
        this.getSingleUserChat(
          this.allUserList[0]._id,
          this.page,
          this.allUserList[0].professional
        );
        }
      });
  }

  getSingleUserChat(id: any, page: any, professional: any) {
    this.isUserClicked = true;
    this.professionaldata = professional;
    console.log(this.professionaldata, "PROFESSIONAL");

    this.professionalid = professional._id;
    this.page = 1;
    if (this.chatId !== id) {
      var query = {
        chatId: this.chatId,
      };
      this.socket.emitAction("disConnectToChat", query);
    }
    this.chatId = id;
    var param = "id=" + id + "&" + "page=" + page + "&" + "limit=1000";
    this.http
      .httpGetWithHeader("getAllProfessioanlChatList", param)
      .subscribe((res: any) => {
        this.userChat = res.result.messages.reverse();
        this.userChatResponse = res.result;
        console.log(this.userChatResponse, "USER-CHAT");

        this.totalPage = res.result.count;
        this.bookingId = res.result.messages['bookingId'];
        this.bookingId = null; // Initialize bookingId as null.

      // Loop through the messages to find and store bookingId if it exists in any one object.
      for (const message of this.userChat) {
        if (message.hasOwnProperty('bookingId')) {
          this.bookingId = message.bookingId;
          break; // Once we find the bookingId, break out of the loop.
        }
      }

      // Now, you have the bookingId in the 'bookingId' variable.
      console.log(this.bookingId, "MNBVCFGHJIOUY");
        var connection = "connectToChat";
        var payload = {
          chatId: this.chatId,
        };
        this.socket.emitAction(connection, payload);
        var elem = document.getElementById("chat_converse");
        $("#chat_converse").animate(
          {
            scrollTop: (elem.scrollTop = elem.scrollHeight * (this.page * 1000)),
          },
          1000
        );
      });
  }

  sendMessage() {
    if (
      !(
        this.newMessage.trim() == "" ||
        this.newMessage == undefined ||
        this.newMessage == null
      ) &&
      this.imageFileData.length == 0
    ) {
      var connect = "sendMessage";
      var data = {
        chatId: this.chatId,
        text: this.newMessage.trim(),
        type: "TEXT",
      };
      this.socket.emitAction(connect, data);
      this.newMessage = "";
    } else if (
      (this.newMessage.trim() == "" ||
        this.newMessage == undefined ||
        this.newMessage == null) &&
      this.imageFileData.length > 0
    ) {
      let formData = new FormData();
      for (let i = 0; i < this.imageFileData.length; i++) {
        formData.append("file", this.imageFileData[i]);
      }
      formData.append("chatId", this.chatId);
      this.http
        .httpPostWithHeaderFormData("chatImageUpload", formData)
        .subscribe((res: any) => {
          if (res) {
            console.log("success", res);
            this.file = [];
            this.imageFileData = [];
            this.fileDataUrl = [];
          }
        });
    } else {
      this.newMessage = "";
      this.common.presentsToast(
        "success",
        "top-end",
        this.translate.instant("ONLY_WHITE_SPACES")
      );
    }
    this.socket.isUserDatafetched.subscribe((res: any) => {
      if (res == true) {
        var elem = document.getElementById("chat_converse");
        $("#chat_converse").animate(
          {
            scrollTop: (elem.scrollTop = elem.scrollHeight * (this.page * 10)),
          },
          1000
        );
      }
    });
  }

  back() {
    this._location.back();
  }

  offerAccept(mesages: any) {
    var connect = "sendMessage";
    var data = {
      chatId: this.chatId,
      messageId: mesages._id ? mesages._id : "",
      type: "OFFER_ACTION",
      bookingId: mesages.bookingId ? mesages.bookingId : "",
      action: "ACCEPTED",
    };
    this.socket.emitAction(connect, data);
    console.log(data, "DATAAAAA");

    this.getSingleUserChat(this.chatId, 1, this.professionaldata);
  }

  offerDeclined(mesages: any) {
    var connect = "sendMessage";
    var data = {
      chatId: this.chatId,
      messageId: mesages._id ? mesages._id : "",
      type: "OFFER_ACTION",
      bookingId: mesages.bookingId ? mesages.bookingId : "",
      action: "DECLINED",
    };
    this.socket.emitAction(connect, data);
    this.getSingleUserChat(this.chatId, 1, this.professionaldata);
  }

  modelOpen(url: any, i: any) {
    this.imgUrl = '';
    setTimeout(()=>{
      this.imgUrl = url;
    },500);
    this.index = i;
    $("#fileUpload").modal("show");
  }

  save(event: any) {
    console.log(event);
    var b: any = event;
    b.lastModifiedDate = new Date();
    b.name = "new-image.jpeg";
    this.editedFile = <File>event;
    this.imageFileData[this.index] = this.editedFile;
    setTimeout(() => {
      var reader = new FileReader();
      reader.readAsDataURL(this.editedFile);
      reader.onload = (_event) => {
        this.fileDataUrl[this.index] = reader.result;
        this._cdr.detectChanges();
      };
      $("#fileUpload").modal("hide");
    },500);
  }

  removeImage(data: any, i: any) {
    this.fileDataUrl.splice(i, 1);
    this.imageFileData.splice(i, 1);
  }

  cancel() {
    $("#fileUpload").modal("hide");
  }

  getOfferStatus()
  {
    this.allOffer = [];
    this.http.httpGetWithHeaderParam("offers",this.bookingId).subscribe((response:any) =>{
      // this.router.navigate(['/payment/' + this.professionalid])

      if(response.status == 200)
      {
        response.result.forEach((element:any) => {
          if(!element.booking.paymentMethod ||  element.booking.paymentMethod == null)
          {
            this.allOffer.push(element)
          }
        });
        console.log(this.allOffer, "ASXCV4567")
        if(this.allOffer.length == 0)
        {
          this.common.presentsToast('error','top-end','You need to accept offer first before proceeding to payment.')
        }
        if(this.allOffer.length == 1)
        {
          localStorage.setItem('professionalDetails',JSON.stringify(this.professionaldata));
          localStorage.setItem('bookingData',JSON.stringify(this.allOffer[0]))
          this.router.navigate(['/payment/' + this.allOffer[0].booking._id])
        }
        if(this.allOffer.length > 1)
        {
          this.isBookingSelected = false;
          $('#bookingModal').modal('show')
        }
      }
    }
    )
  }
  closeModal()
  {
    for(let i = 0; i < this.allOffer.length; i++)
    {
      $('#bookingList' + i).removeClass('activeBooking')
    }
    $('#bookingModal').modal('hide')
  }
  changeIntoGMT(time:any)
  {
    var gmtTime = time.toString();
    return gmtTime;
  }

  navigateToPayment()
  {
    localStorage.setItem('professionalDetails',JSON.stringify(this.professionaldata));
    localStorage.setItem('bookingData',JSON.stringify(this.bookingData))
    $('#bookingModal').modal('hide')
    this.router.navigate(['/payment/' + this.bookingData.booking._id]);
  }
  selectBooking(data:any,i:any)
  {
    this.isBookingSelected = true;
    this.bookingData = data;
    for(let i = 0; i < this.allOffer.length; i++)
    {
      $('#bookingList' + i).removeClass('activeBooking')
    }
    $('#bookingList' + i).addClass('activeBooking')
  }


  ngOnDestroy()
  {
    var query = {
      chatId: this.chatId,
    };
    this.socket.emitAction("disConnectToChat", query);
  }
}
