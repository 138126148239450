import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common/common.service";
import { HttpService } from "src/app/services/http/http.service";
import { environment } from "src/environments/environment";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AuthService } from "src/app/services/auth-guard/auth.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: 'app-piercing-professional',
  templateUrl: './piercing-professional.component.html',
  styleUrls: ['./piercing-professional.component.scss']
})
export class PiercingProfessionalComponent implements OnInit {
  constructor(
    public commonbService: CommonService,
    private auth: AuthService,
    private http: HttpService,
    private router: Router,
    private fb: FormBuilder,
    public translate: TranslateService
  ) { }
  professionalSort: FormGroup;
  public professionals: any = [];
  public innerHeight: any;
  public submitted: Boolean = false;
  allStudios: any[] = [];
  studioId: any[] = [];
  categories: any[] = [];
  selectedStudioValue: any;
  categoryId: any;
  selected: any;
  name: string = "asc";
  imageUrl: string = environment.imageUrl;
  userData: any = localStorage.userProfileData
    ? JSON.parse(localStorage.userProfileData)
    : {};


  slides = [
    { img: "http://placehold.it/350x150/000000" },
    { img: "http://placehold.it/350x150/111111" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/666666" }
  ];

  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    variableWidth: true,
    infinite: true,
    autoplay: true,
    margin: 20,
    autoplaySpeed: 1000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 2
        }
      },
    ]
  };

  slickInit(event: any) {

  }

  onCategorySelection(id: any) {
    this.selectedStudioValue = '';
    this.categoryId = id;
    var query = 'category=' + id
    this.http.httpPostWithQuery("professionals", query, '').subscribe((res: any) => {
      if (res.status == 200) {
        this.professionals = res.result;
      }
    })
  }

  ngOnInit() {
    if (!this.auth.loggedIn) {
      this.router.navigate(["/"]);
    }
    this.getAllStudios();
    this.getAllCategories();

    hideChat(0);

    $("#prime").click(function () {
      toggleFab();
    });

    //Toggle chat and links
    function toggleFab() {
      $(".prime").toggleClass("zmdi-comment-outline");
      $(".prime").toggleClass("zmdi-close");
      $(".prime").toggleClass("is-active");
      $(".prime").toggleClass("is-visible");
      $("#prime").toggleClass("is-float");
      $(".chat").toggleClass("is-visible");
      $(".fab").toggleClass("is-visible");
    }

    $("#chat_first_screen").click(function (e) {
      hideChat(1);
    });

    $("#chat_second_screen").click(function (e) {
      hideChat(2);
    });

    $("#chat_third_screen").click(function (e) {
      hideChat(3);
    });

    $("#chat_fourth_screen").click(function (e) {
      hideChat(4);
    });

    $("#chat_fullscreen_loader").click(function (e) {
      $(".fullscreen").toggleClass("zmdi-window-maximize");
      $(".fullscreen").toggleClass("zmdi-window-restore");
      $(".chat").toggleClass("chat_fullscreen");
      $(".fab").toggleClass("is-hide");
      $(".header_img").toggleClass("change_img");
      $(".img_container").toggleClass("change_img");
      $(".chat_header").toggleClass("chat_header2");
      $(".fab_field").toggleClass("fab_field2");
      $(".chat_converse").toggleClass("chat_converse2");
    });

    function hideChat(hide) {
      switch (hide) {
        case 0:
          $("#chat_converse").css("display", "bloack");
      }
    }
  }

  getAllCategories() {
    this.http.httpGetWithHeader("getCategory", '').subscribe((res: any) => {
      if (res.status == 200) {
        this.categories = res.result;
      }
    })
  }
  onStudioSelections(event: any) {
    this.categoryId = '';
    this.selectedStudioValue = event;
    this.selected = event;
    this.studioId = [];
    this.studioId.push(this.selectedStudioValue)
    var param = {
      studios: this.studioId
    }
    this.getAllProfessional(param)
  }
  getAllProfessional(param: any) {
    this.http
      .httpPost("professionals", param)
      .subscribe((res: any) => {
        if (res.status == 200) {
          console.log(res)
          this.professionals = res.result;
        } 
      });
  }

  getAllStudios() {
    this.http
      .httpGetWithHeader("allprofessionalStudio", "")
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.allStudios = res.result;
          this.onStudioSelections(res.result[0]._id)
        }
      });
  }
}