import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/services/auth-guard/auth.service";
import { CommonService } from "src/app/services/common/common.service";
import { HttpService } from "src/app/services/http/http.service";
import { NotifySocketService } from "src/app/services/notify-socket.service";
interface notification {
  title: any;
  message: any;
}

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit {
  userId: any;
  allNotiofications: any[] = [];
  page: any = 1;
  totalPage: any;
  previousScrollHeightMinusTop: any = 0;
  loggedIn: boolean = this.auth.loggedIn == true ? true : false;
  constructor(
    private http: HttpService,
    private notifySocke: NotifySocketService,
    public translate: TranslateService,
    private router:Router,
    private auth:AuthService,
    private commonService:CommonService
  ) {
    let Data = JSON.parse(localStorage.userProfileData);
    this.userId = Data._id;
  }

  ngOnInit() {
    this.getAllNotifications(this.page);
  }
  getAllNotifications(page: any) {
    var param = "page=" + page;
    this.http
      .httpGetWithHeaderParamandQuery("allNotification", this.userId, param)
      .subscribe((res: any) => {
        if (res.status == 200) {
          res.result.data.forEach((element:any) => {
            this.allNotiofications.push(element);
          });
          console.log(this.allNotiofications)
          this.totalPage = res.result.count;
          this.notifySocke.isNotificationtruefalse.next(false);
        }
      });
  }

  onScroll() {
    this.page = this.page + 1;
    if (this.totalPage >= this.page) {
      this.getAllNotifications(this.page)
    }
  }

  onScrollUp() { }

  navigate(data:any)
  {
    if(data.pushType == 1 || data.pushType == 3 || data.pushType == 6)
    {
      if (this.loggedIn) {
        this.router.navigate(['/bookingdetail/' + data.bookingId]);
      } else {
        this.commonService.presentsToast(
          "error",
          "top-end",
          this.translate.instant("LOGIN_FIRST")
        );
      }
     
    }  
    else if(data.pushType == 2 || data.pushType == 5)
    {
      if (this.loggedIn) {
        this.router.navigate(["/chat"]);
      } else {
        this.commonService.presentsToast(
          "error",
          "top-end",
          this.translate.instant("LOGIN_FIRST")
        );
      }
    }
    else
    {
      this.router.navigate(["/home"]);
    }
  }
}
