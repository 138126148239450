import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  scrollToContactUs = new BehaviorSubject<boolean>(false);
  getScrollToContactUs = this.scrollToContactUs.asObservable();
  instructionScroll = new Subject();
  getScroll = this.instructionScroll.asObservable();
  modalData: any;
  modalEmailData: any;
  allTattooData:any;
  allPiercingData:any;
  isCountryCode:any;
  allArtistData:any;
  profeId:any;

  constructor(private router:Router) {}
  presentsToast(type: any, position: any, message: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: position,
      timerProgressBar: true,
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      timer: 3000,
    });
    Toast.fire({
      title: message,
      icon: type,
    });
  }

  // confirmToast() {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     //text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Ok',
  //     cancelButtonText:"Skip"
  //   }).then((result) => {
  //     if (result.value) {
  //       Swal.fire('submiited !', 'Inquiry has been successfully submitted and we will get back to you shortly.', 'success');
  //     }
  //   });
  // }

  confirmToast(page:any,id:any,isCustomer:any,web:any) {
    Swal.fire({
      title: 'Please fill the disclaimer-concent form',
      text: "",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#212529',
      confirmButtonText: 'Ok',
      cancelButtonText:'Skip'
    }).then((result) => {
      if (result.value) {
        // this.https
        //   .httpDeleteHeader('getCollaboration', id)
        //   .subscribe((res: any) => {
            //this.getAllData(this.pageNo);
          //});
          this.presentsToast(
            "success",
            "top-end",
            "Inquiry has been successfully submitted and we will get back to you shortly"
          );
          this.router.navigate([
            page +
            id +
            "/" +
            isCustomer +
            "/" +
            web,
          ]);
      }
      else{
        this.presentsToast(
          "success",
          "top-end",
          "Inquiry has been successfully submitted and we will get back to you shortly"
        );
        this.router.navigateByUrl('/appointments')
      }
    });
  }
}
