import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
  loading: boolean = false;
  constructor(public translate: TranslateService) {}

  ngOnInit() {}
  setLoading(loading) {
    console.log("asdsadsd");
    this.loading = loading;
  }
}
