import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { SwPush, SwUpdate } from "@angular/service-worker";
import firebase from "firebase/app";
import "firebase/messaging";
import { environment } from "src/environments/environment";
import { NotifySocketService } from "./services/notify-socket.service";
import { TranslateService } from "@ngx-translate/core";
import { GoogleAuthService } from "./services/GoogleAuthService";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "Baronart web";
  displayToken: string = "";
  serviceWorkerAttempt = 0;
  attempts = 0;
  constructor(
    translate: TranslateService,
    private googleAuthService: GoogleAuthService,
    private updates: SwUpdate,
    private push: SwPush,
    private router: Router,
    private notifySocke: NotifySocketService
  ) {
    this.getToken();
    translate.addLangs(["en", "ar"]);
    translate.setDefaultLang("en");
    translate.use("en");
  }

  permitToNotify() {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() =>
        messaging.getToken().then((token: any) => {
          this.displayToken = token;
          localStorage.setItem("fcmDeviceToken", this.displayToken);
          if (this.displayToken !== "") {
          }
          console.log(this.displayToken);
        })
      )
      .catch((err: any) => {
        console.log("Unable to get permission to notify.", err);
      });
  }

  getToken() {
    navigator.serviceWorker.register("ngsw-worker.js");
    firebase.initializeApp(environment.firebase);
    const setInt = () => {
      navigator.serviceWorker.getRegistration().then((swr: any) => {
        this.serviceWorkerAttempt++;
        console.log("swr", swr);
        if (swr != undefined) {
          firebase.messaging().useServiceWorker(swr);
        } else {
          if (this.serviceWorkerAttempt > 0 && this.serviceWorkerAttempt < 3) {
            setInt();
          }
        }
      });
    };
    setInt();
    this.updates.available.subscribe((_) =>
      this.updates.activateUpdate().then(() => {
        console.log("reload for update");
        document.location.reload();
      })
    );
    this.push.messages.subscribe(
      (msg: any) => {
        console.log(msg)
        const audio = new Audio();
        audio.src = 'assets/sounds/notification-sound1.mp3';
        audio.load();
        audio.play();
      }
    );
    this.push.notificationClicks.subscribe((click) => {
      console.log("notification click", click);
      //this.router.navigate(['/notification'])
    });

    setTimeout(() => {
      this.permitToNotify();
    }, 2000);
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.googleAuthService.initGoogleAuth();
  }
}
