import { Component, NgZone, OnInit } from "@angular/core";
import { HttpService } from "src/app/services/http/http.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
declare var window: any;
@Component({
  selector: "app-signup",
  templateUrl: "./signup-landing.component.html",
  styleUrls: ["./signup-landing.component.scss"],
})
export class SignupLandingComponent implements OnInit {
  jwtHelper = new JwtHelperService();
  deviceToken: any = "";

  constructor(
    private ngZone: NgZone,
    private http: HttpService,
    private router: Router
  ) {}

  ngOnInit() {
    this.googleLogin();
  }

  googleLogin() {
    window.google.accounts.id.initialize({
      client_id:
        "752663413722-r847f254bha3scnb71vpt5u9g1ek04fq.apps.googleusercontent.com",
      scope: " email profile",
      callback: ((window as any)["handleCredentialResponse"] = (
        response: any
      ) =>
        this.ngZone.run(
          () => {
            console.log(
              "this response holds the token for the logged in user information",
              response
            );
            const decodedToken = this.jwtHelper.decodeToken(
              response.credential
            );
            console.log(decodedToken);
            const { given_name, family_name, email, picture, sub } =
              decodedToken;
            let body = {
              firstName: given_name,
              lastName: family_name,
              email: email,
              image: picture,
              id: sub,
              provider: "GOOGLE",
              deviceType: "web",
            };
            if (
              !(
                localStorage.fcmDeviceToken == null ||
                localStorage.fcmDeviceToken == undefined ||
                localStorage.fcmDeviceToken == ""
              )
            ) {
              this.deviceToken = localStorage.fcmDeviceToken;
              console.log(this.deviceToken);
            }
            this.http.httpPost("socialLogin", body).subscribe((res: any) => {
                localStorage.setItem("accessToken", res.result.Authorization);
                this.router.navigateByUrl('/')
            });
          },
          (error) => {
            alert("error" + JSON.stringify(error));
          }
        )),
    });
    window.google.accounts.id.renderButton(
      document.getElementById("googleButton"),
      {
        type: "icon",
        shape: "square",
        width: "60px",
      }
    );
  }
}
