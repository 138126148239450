import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpService } from "src/app/services/http/http.service";
import { environment } from "src/environments/environment";
import * as js from "../../../assets/js/custom.js";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AuthService } from "src/app/services/auth-guard/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "src/app/services/common/common.service";
import { MarkerManager } from "@agm/core";
import { MatOption, MatSelect } from "@angular/material";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";

interface days {
  days: string;
  value: any;
}
interface marker {
  lat: any;
  lng: any;
  label: any;
}

interface studio {
  name: any;
  _id: any;
}
interface groupOption {
  label: any;
  groupValue: any[];
}

@Component({
  selector: "app-piercing-consultation",
  templateUrl: "./piercing-consultation.component.html",
  styleUrls: ["./piercing-consultation.component.scss"],
})
export class PiercingConsultationComponent implements OnInit {
  public proId: String = this.route.snapshot.paramMap.get("proid");
  public professional: String = "";
  allProfessional: any[] = [];
  PreferenceSelected: boolean = false;
  otherOption: boolean = false;
  studioGroup: groupOption[] = [];
  allStudios: studio[] = [];
  studioId: any[] = [];
  artistsId: any[] = [];
  selectedDaysId: any[] = [];
  @ViewChild("mySel1", { static: false }) skillSel: MatSelect;
  isStudio: boolean = false;
  isDescription: boolean = false;
  totalUploads: any[] = [];
  imageUrl: string = environment.imageUrl;
  bookingForm: FormGroup;
  public innerHeight: any;
  public submitted: Boolean = false;
  fileData: any[] = [];
  fileDataUrl: any = "";
  imgBaseUrl = environment.imageUrl;
  prefferdDays: days[] = [
    { days: "Sunday", value: 0 },
    { days: "Monday", value: 1 },
    { days: "Tuesday", value: 2 },
    { days: "Wednesday", value: 3 },
    { days: "Thursday", value: 4 },
    { days: "Friday", value: 5 },
    { days: "Saturday", value: 6 },
  ];
  selectedValue: any[] = [];
  selectedStudioValue: any[] = [];
  selectedArtistValue: any[] = [];
  allpiercinng: any[] = [];
  isArtist: boolean = false;
  lat: any;
  long: any;
  zoom = 4;
  markers = [];
  textChange: boolean = false;
  tattooTypeOption: any[] = ["Nose", "Ear"];
  userData = localStorage.userProfileData
    ? JSON.parse(localStorage.userProfileData)
    : "";

  mainConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    variableWidth: true,
    infinite: true,
    autoplay: true,
    margin: 20,
    autoplaySpeed: 1500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  studios: any[] = [];
  todatDateTime: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    public auth: AuthService,
    public commonbService: CommonService,
    private fb: FormBuilder,
    private ngxService: NgxUiLoaderService,
    public translate: TranslateService
  ) {
    var date = new Date();
    var yy = date.getFullYear();
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0');
    var minutesToAdd = 15;
    var newDate = new Date(date.getTime() + minutesToAdd * 60000);
    var hours = newDate.getHours();
    var minutes = newDate.getMinutes();
    var minute = minutes < 10 ? '0' + minutes : minutes;
    var time = hours + ':' + minute;
    this.todatDateTime = yy + '-' + mm + '-' + dd + 'T' + time;
    console.log(this.todatDateTime)
  }

  addMarker(lat: any, lang: any) {
    console.log(lat);
  }

  selectMarker(event: any) {
    window.open(
      "https://www.google.com/maps?z=12&t=k&q=loc:" +
      event.latitude +
      "+" +
      event.longitude
    );
  }

  onArtistClick() {
    this.isStudio = true;
  }

  onStudioClick() {
    this.isArtist = true;
  }

  changeString(data: any) {
    if (data !== undefined) {
      if (data.length <= 150) {
        this.isDescription = false;
        return data;
      } else {
        this.isDescription = true;
        return data.substr(0, 150) + "...";
      }
    }
  }

  ngOnInit(): void {
    js.customScript();
    this.getAllProfessional("");
    //this.getAllStudios();
    $("#desc").toggleClass("show-hide", false);

    this.innerHeight = window.innerHeight;
    this.bookingForm = this.fb.group({
      tattooType: new FormControl("", Validators.required),
      eighteenPlus: new FormControl("", Validators.required),
      studios: new FormControl("", null),
      artists: new FormControl("", Validators.required),
      note: new FormControl("", [
        Validators.pattern("^.{2,200}$"),
      ]),
      description: new FormControl("", [Validators.pattern("^.{2,200}$")]),
      dateTime: new FormControl("", Validators.required),
    });
    hideChat(0);

    $("#prime").click(function () {
      toggleFab();
    });

    //Toggle chat and links
    function toggleFab() {
      $(".prime").toggleClass("zmdi-comment-outline");
      $(".prime").toggleClass("zmdi-close");
      $(".prime").toggleClass("is-active");
      $(".prime").toggleClass("is-visible");
      $("#prime").toggleClass("is-float");
      $(".chat").toggleClass("is-visible");
      $(".fab").toggleClass("is-visible");
    }

    $("#chat_first_screen").click(function (e) {
      hideChat(1);
    });

    $("#chat_second_screen").click(function (e) {
      hideChat(2);
    });

    $("#chat_third_screen").click(function (e) {
      hideChat(3);
    });

    $("#chat_fourth_screen").click(function (e) {
      hideChat(4);
    });

    $("#chat_fullscreen_loader").click(function (e) {
      $(".fullscreen").toggleClass("zmdi-window-maximize");
      $(".fullscreen").toggleClass("zmdi-window-restore");
      $(".chat").toggleClass("chat_fullscreen");
      $(".fab").toggleClass("is-hide");
      $(".header_img").toggleClass("change_img");
      $(".img_container").toggleClass("change_img");
      $(".chat_header").toggleClass("chat_header2");
      $(".fab_field").toggleClass("fab_field2");
      $(".chat_converse").toggleClass("chat_converse2");
    });

    function hideChat(hide) {
      switch (hide) {
        case 0:
          $("#chat_converse").css("display", "bloack");
      }
    }
  }

  getAllPiercing() {
    this.http
      .httpGetWithoutHeader("getAllpiercingType", '')
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.allpiercinng = res.result;

          if (!(this.commonbService.allPiercingData == undefined || this.commonbService.allPiercingData == '' || this.commonbService.allPiercingData == null)) {
            let allData = this.commonbService.allPiercingData
            var piercingIndex = this.allpiercinng.findIndex(val => val._id == allData.piercingType)
            var artistIndex = this.allProfessional.findIndex(item => item._id = allData.professionals[0])
            var atistid: any;
            atistid = this.allProfessional[artistIndex]
            var artistData = {
              value: atistid
            }
            if (!(artistData == undefined || artistData == null) && !(artistData.value == undefined || artistData.value == null)) {
              this.onArtistSelection(artistData);
            }
            this.bookingForm.patchValue({
              tattooType: allData.piercingType ? this.allpiercinng[piercingIndex]._id : '',
              eighteenPlus: allData.eighteenPlus ? allData.eighteenPlus : '',
              note: allData.note ? allData.note : '',
              description: allData.description ? allData.description : '',
              dateTime: allData.specificDateTime ? new Date(allData.specificDateTime) : '',
            })
            console.log(this.bookingForm.value)
          }
        }
      });
  }

  addRemoveFavourite(id: any, isFavourite: any, professionalId: any) {
    if (isFavourite == true) {
      var query = "id=" + id;
      this.http.httpDeleteWithid("getFavorite", query).subscribe((res: any) => {
        if (res.status == 200) {
        }
      });
    }
    if (isFavourite == false) {
      var param = {
        professional: professionalId,
        item: id,
      };
      this.http
        .httpPostWithHeader("getFavorite", param)
        .subscribe((res: any) => {
          if (res.status == 200) {
          }
        });
    }
  }

  toggleShowHide(e: any) {
    e.preventDefault();
    if (this.isDescription == true) {
      $("#desc").toggleClass("show-hide");
      $("#moreTe").toggleClass("allDesc");
      this.textChange = !this.textChange;
    }
  }

  onFileChange(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      if (this.fileData.length < 5) {
        this.fileDataUrl = [];
        this.fileData.push(fileInput.target.files[0]);
        this.fileData.reverse();
        this.fileData.forEach((res: any) => {
          var reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onload = (_event) => {
            this.fileDataUrl.push(reader.result);
          };
        });
      } else {
        this.commonbService.presentsToast(
          "error",
          "top-end",
          this.translate.instant("ONLY_FIVE_ALLOWED")
        );
      }
    }
  }

  submitBooking() {
    this.submitted = true;
    this.isStudio = false;
    this.isArtist = false;
    if (
      this.bookingForm.valid &&
      this.bookingForm.value.eighteenPlus == "false"
    ) {
      this.commonbService.presentsToast(
        "error",
        "top-end",
        this.translate.instant("MINOR_EIGHTEEN")
      );
    }
    if (
      this.bookingForm.valid &&
      this.bookingForm.value.eighteenPlus == "true"
    ) {
      var formData: any = new FormData();
      formData.append("type", "PIERCING");
      formData.append("piercingType", this.bookingForm.value.tattooType);
      formData.append("eighteenPlus", this.bookingForm.value.eighteenPlus);
      if(!(this.bookingForm.value.note == undefined || this.bookingForm.value.note == null || this.bookingForm.value.note.trim() == ''))
      {
      formData.append("note", this.bookingForm.value.note.trim());
      }
      if (
        !(
          this.bookingForm.value.dateTime == "" ||
          this.bookingForm.value.dateTime == undefined ||
          this.bookingForm.value.dateTime == null
        )
      ) {
        var utcDate = (moment(this.bookingForm.value.dateTime).format('YYYY-MM-DDTHH:mm:ss[Z]'))
        console.log("utcString",utcDate)
        formData.append("specificDateTime", utcDate);
      }
        formData.append("studios", JSON.stringify(this.studioId));
        formData.append("professionals", JSON.stringify(this.artistsId));
      if (
        !(
          this.bookingForm.value.description.trim() == "" ||
          this.bookingForm.value.description.trim() == undefined ||
          this.bookingForm.value.description.trim() == null
        )
      ) {
        formData.append(
          "description",
          this.bookingForm.value.description.trim()
        );
      }

      if (!this.auth.loggedIn) {
        var piercingdata = {
          "piercingType": this.bookingForm.value.tattooType,
          "eighteenPlus": this.bookingForm.value.eighteenPlus,
          "note": this.bookingForm.value.note.trim(),
          "specificDateTime": this.bookingForm.value.dateTime,
          "studios": this.studioId,
          "professionals": this.artistsId,
          "description": this.bookingForm.value.description.trim()
        }
        this.commonbService.allPiercingData = piercingdata
        this.commonbService.presentsToast('error', 'top-end', this.translate.instant("LOGIN_FIRST"))
        this.router.navigateByUrl('/signup-landing')
      }
      else {
        this.ngxService.start();
        this.http
          .httpPostWithHeaderFormDataPromise("bookingForm", formData)
          .then((res: any) => {
            this.ngxService.stop()
            if (res.status == 200) {
              this.bookingForm.reset();
              this.submitted = false;
              if (res.result.disclaimerFormFilled != true) {
                this.commonbService.confirmToast("/disclaimer-concent/", this.userData._id,"customer", "web") 
             }
              else
              {
                this.commonbService.presentsToast(
                  "success",
                  "top-end",
                  "Inquiry has been successfully submitted and we will get back to you shortly"
                );
                this.router.navigateByUrl('/appointments')
              }
            }
          }).catch(err => {
            this.ngxService.stop()
          });
      }
    }
  }

  onPirrcingSelection(event: any) { }
  getAllProfessional(param: any) {
    var query = "artistType=PIERCING";
    this.http
      .httpostWithoutQueryHeader("professionals", query)
      .subscribe((res: any) => {
        if (res.status == 200) {
          console.log(res);
          this.allProfessional = res.result;
          this.getAllPiercing();
        }
      });
  }

  download(path: any) {
    window.open(path, "_blank");
  }

  onStudioSelection(event: any) {
    this.selectedStudioValue = event.value;
    this.studioId = [];
    this.selectedStudioValue.forEach((item: any) => {
      this.studioId.push(item._id);
    });
    var param = {
      studios: this.studioId,
    };
  }

  onArtistSelection(event: any) {
    this.selectedArtistValue = [];
    this.studioGroup = [];
    this.selectedArtistValue.push(event.value);
    console.log(this.selectedArtistValue);
    if (this.selectedArtistValue.length > 0) {
      this.selectedArtistValue.forEach((res: any, i: any) => {
        this.artistsId.push(res._id);
        var artistIndex = this.allProfessional.findIndex(item => item._id == this.artistsId[0])
        this.bookingForm.controls.artists.setValue(this.allProfessional[artistIndex])
        this.bookingForm.controls.artists.updateValueAndValidity();
        this.studioGroup.push({
          label: res.firstName + " " + res.lastName,
          groupValue: res.studio,
        });
      });
    }

    if (!(this.commonbService.allPiercingData == undefined || this.commonbService.allPiercingData == '' || this.commonbService.allPiercingData == null)) {
      let allData = this.commonbService.allPiercingData
      let selectecStudio: any[] = []
      selectecStudio = this.studioGroup[0].groupValue.filter(val => allData.studios.includes(val._id))
      var selectedStudio: any[] = [];
      selectecStudio.forEach(element => {
        selectedStudio.push(element)
      });
      this.bookingForm.controls.studios.setValue(selectedStudio);
      this.bookingForm.controls.studios.updateValueAndValidity();
      var studioData = {
        value: selectecStudio
      }
      this.onStudioSelection(studioData);
    }
  }

  onOtherOption() {
    this.skillSel.options.forEach((item: MatOption, i: any) => {
      if (item.value == "No Preferences") {
        item.deselect();
      }
    });
  }

  onNoPreferenceSelection() {
    this.PreferenceSelected = true;
    if (this.PreferenceSelected) {
      this.skillSel.options.forEach((item: MatOption, i: any) => {
        if (item.value == "No Preferences") {
          item.select();
        } else {
          item.deselect();
        }
      });
    } else {
      this.skillSel.options.forEach((item: MatOption, i: any) => {
        if (item.value == "No Preferences") {
          item.deselect();
        }
      });
    }
    this.skillSel.close();
  }

  async onSelection(event: any) {
    this.selectedDaysId = [];
    this.selectedValue = [];
    event.value.forEach((res: any) => {
      this.selectedValue.push(res.days);
      this.selectedDaysId.push(res.value);
    });
  }
}
