import { AfterViewInit, Component, OnInit, HostListener } from "@angular/core";
import { Router, RoutesRecognized } from "@angular/router";
import { TweenLite, Power2, TimelineMax, TweenMax } from "gsap";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
declare let ScrollMagic: any;
import { CommonService } from "src/app/services/common/common.service";
import { HttpService } from "src/app/services/http/http.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/services/auth-guard/auth.service";

declare var anime: any;
declare var $: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, AfterViewInit {
  aboutUs: string = "";
  tatoos = [];
  slides = [];
  lat: any;
  long: any;
  zoom = 6;
  markers: any[] = [];
  adminProfileDetails: any;

  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 999,
    arrows: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  slideConfig1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 999,
    arrows: false,
  };
  userData: any = localStorage.userData
    ? JSON.parse(localStorage.userData)
    : "";
  loggedIn: boolean = !(localStorage.accessToken == '' || localStorage.accessToken == null || localStorage.accessToken == undefined) ? true : false;
  baseUrl: string = environment.imageUrl;
  submitted: boolean = false;
  collaborationForm: FormGroup;
  allProfessionalStudio: any[] = [];
  isChatRender: boolean = false;

  constructor(
    private http: HttpService,
    private commonbService: CommonService,
    private router: Router,
    private fb: FormBuilder,
    public translate: TranslateService,
    private auth: AuthService
  ) {

    var params = {
      country: "United States",
      state: "New York",
      city: "New York",
    };
    this.http.httpGet("category").subscribe((res: any) => {
      if (res.status === 200) {
        this.tatoos = res.result;
      }
    });

  }

  ngAfterViewInit() {
    this.isChatRender = true;
  }

  bookNow() {
    this.router.navigate(["/professional"]);
  }

  getAdminDetails() {
    this.http.httpGet("getAdminDetail").subscribe((res: any) => {
      if (res.status == 200) {
        this.adminProfileDetails = res.result.data;
        this.markers = [];
        this.markers.push({
          lat: res.result.data.latitude,
          lng: res.result.data.longitude,
          label: res.result.data.city,
        });
        this.lat = res.result.data.latitude;
        this.long = res.result.data.longitude;
      }
    });
  }
  professional() { }
  addMarker(lat: any, lang: any) {
    console.log(lat);
  }

  selectMarker(event: any) {
    window.open(
      "https://www.google.com/maps?z=12&t=k&q=loc:" +
      event.latitude +
      "+" +
      event.longitude
    );
  }

  bookPiercingNow() {
    $("#piercingModal").modal("show");
  }

  navigateToPiercing() {
    $("#piercingModal").modal("hide");
    this.router.navigate(["/piercingConsultation"]);
  }

  getAllProfessional() {
    var query = "artistType=PIERCING";
    this.http
      .httpGetWithoutHeader("allprofessionalStudio", "")
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.allProfessionalStudio = res.result;
        }
      });
  }

  bookTattooNow() {
    this.router.navigate(["/tattooConsultaion"]);
  }

  submitCollabForm() {
    this.submitted = true;
    if (this.collaborationForm.valid) {
      if (
        !(
          this.collaborationForm.value.name.trim() == "" ||
          this.collaborationForm.value.message.trim() == ""
        )
      ) {
        var params = $.extend(
          {},
          {
            email: this.collaborationForm.value.email,
            name: this.collaborationForm.value.name.trim(),
            message: this.collaborationForm.value.message.trim(),
          }
        );
        this.http.httpPost("collab", params).subscribe((res: any) => {
          if (res.status === 200) {
            this.collaborationForm.reset();
            this.commonbService.presentsToast(
              "success",
              "top-end",
              'Thanks for submitting the form'
            );
            this.submitted = false;
          }
        });
      } else {
        if (
          this.collaborationForm.value.name.trim() == "" &&
          this.collaborationForm.value.message.trim() != ""
        ) {
          this.commonbService.presentsToast(
            "error",
            "top-end",
            "Only white-spaces is not allowed in name."
          );
        }
        if (
          this.collaborationForm.value.message.trim() == "" &&
          this.collaborationForm.value.name.trim() != ""
        ) {
          this.commonbService.presentsToast(
            "error",
            "top-end",
            "Only white-spaces is not allowed in message."
          );
        }
        if (
          this.collaborationForm.value.message.trim() == "" &&
          this.collaborationForm.value.name.trim() == ""
        ) {
          this.commonbService.presentsToast(
            "error",
            "top-end",
            "Only white-spaces is not allowed in name and message."
          );
        }
      }
    }
  }

  ngOnInit() {

    this.getAdminDetails();
    this.getAllProfessional();
    this.collaborationForm = this.fb.group({
      name: ["", [Validators.required, Validators.pattern("^.{1,50}$")]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
          ),
        ],
      ],
      message: ["", [Validators.required, Validators.pattern("^.{2,200}$")]],
    });
  }
}
