import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-main-chat',
  templateUrl: './main-chat.component.html',
  styleUrls: ['./main-chat.component.scss']
})
export class MainChatComponent implements OnInit {

  constructor(private _location: Location,public translate: TranslateService) { }

  ngOnInit() {
  }

  back()
  {
    this._location.back();
  }
}
