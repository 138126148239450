export class apiList {
  socialLogin: string = "/socialLogin"
  professionals: string = "/professionals";
  allprofessionalStudio : string ="/professional/studioDetails";
  category: string = "/category";
  login: string = "/login";
  sendOtp: string = "/sendOtp";
  signup: string = "/signup";
  completeProfile: string = "/completeProfile";
  logout: string = "/logout";
  forgotPassword: string = "/forgotPassword";
  resetPassword: string = "/resetPassword";
  sendVerifyEmail: string = "/sendVerifyEmail";
  verifyEmail : string = "/verifyEmail";
  verifyEmailForProfessional: string = "/professional/verifyEmail";
  verifyEmailForCustomer : string = "/verifyEmail";
  professional: string = "/professional";
  bookingForm: string = "/booking/form";
  collab: string = "/admin/collaboration";
  adminCms: string = "/admin/cms";
  changePassword: string = "/changePassword";
  bookings: string = "/bookings";
  allNotification : string = "/customerNotification";
  getSupportChatHistory : string = "/supportHistory";
  supportChatFileUpload : string = "/supportUpload";
  updateProfile : string = "/profile";
  getAllUserChat : string = "/chatList";
  getAllProfessioanlChatList : string = "/chatHistory";
  chatImageUpload : string = "/chatUpload";
  getAllpiercingType : string = "/piercing";
  getCategory : string = "/category";
  getAdminDetail : string = "/adminDetail";
  getBokkingSlot: string = "/booking/slots";
  getFavorite : string = '/favourite';
  getAllChatMedia : string = "/chatMedia";
  getAllBokkings : string = "/bookingsWith";
  getBookingDetails : string = "/booking";
  sendOtpForProfile : string = "/sendOtpForProfile";
  verifyOtpForProfile : string ="/verifyOtpForProfile";
  offers:string = "/offers";
  search:string = "/search";
  getClientToken:string = "/getClientToken";
  bookingPayment:string = "/booking/payment"
  getBookingById:string = "/bookingById";
}
