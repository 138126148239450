import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common/common.service";
import * as $ from "jquery";
import { HttpService } from "src/app/services/http/http.service";
import { TranslateService } from "@ngx-translate/core";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  aboutUs: String = "";
  constructor(
    private commonService: CommonService,
    private http: HttpService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.commonService.getScrollToContactUs.subscribe((res: boolean) => {
      if (res) {
        document.getElementById("footer_view").scrollIntoView();
        this.commonService.scrollToContactUs.next(false);
      }
    });

    this.http.httpGet("adminCms").subscribe((res: any) => {
      if (res.status == 200) {
        this.aboutUs = res.result.aboutUs;
      } else {
        this.commonService.presentsToast("error", "top-end", res.message);
      }
    });

    $(window).scroll(function () {
      if ($(this).scrollTop() > 50) {
        $(".scrolltop:hidden").stop(true, true).fadeIn();
      } else {
        $(".scrolltop").stop(true, true).fadeOut();
      }
    });
  }

  goToTop() {
    $("html,body").animate({ scrollTop: $(".top").offset().top }, "1000");
  }

  openWidow(url:any)
  {
    window.open(url,'_blank')
  }
}
