export const environment = {
  production: false,
  // baseUrl: "https://api.baronarttattoo.com/api/v1",
  // imageUrl: "https://api.baronarttattoo.com",
  // socketUrl: "https://api.baronarttattoo.com",
  baseUrl: "https://baronapi.appgrowthcompany.com/api/v1",
  imageUrl: "https://baronapi.appgrowthcompany.com",
  socketUrl: "https://baronapi.appgrowthcompany.com",
  storageKey: 'Key',
  token: 'token',
  FACEBOOK_PROVIDER_ID: '323116799766419',
  googleProvider1 : '1033381186817-nt9vu8f0budhjfsljin0h3kfr6p8st1b.apps.googleusercontent.com',
  googleProvider:'559517457699-57m8ug14lth0evlasuvhpc2l887vtq4d.apps.googleusercontent.com',
  GOOGLE_PROVIDER_ID:'559517457699-57m8ug14lth0evlasuvhpc2l887vtq4d.apps.googleusercontent.com',
  // firebase: {
  //   apiKey: "AIzaSyAksmNka30t3rXDmjVzf51pvGenCqohhcM",
  //   authDomain: "baron-c3406.firebaseapp.com",
  //   projectId: "baron-c3406",
  //   storageBucket: "baron-c3406.appspot.com",
  //   messagingSenderId: "328045884634",
  //   appId: "1:328045884634:web:3912673691c391984961e3",
  //   measurementId: "G-2G96MYS9M5",
  // },

  firebase : {
    apiKey: "AIzaSyDk5r0tyuWUg77l6AVz5k49dDDpBOrLrws",
    authDomain: "baron-art-app.firebaseapp.com",
    projectId: "baron-art-app",
    storageBucket: "baron-art-app.appspot.com",
    messagingSenderId: "752663413722",
    appId: "1:752663413722:web:ea0a3a4b119ff65699b6e3"
  }
};
