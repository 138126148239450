import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/services/auth-guard/auth.service";
import { CommonService } from "src/app/services/common/common.service";
@Component({
  selector: "app-modal-popup-email",
  templateUrl: "./modal-popup-email.component.html",
  styleUrls: ["./modal-popup-email.component.scss"],
})
export class ModalPopupEmailComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MatDialog>,
    private commonService: CommonService,
    private router:Router,
    public translate: TranslateService,
    public auth:AuthService
  ) {}

  email: String = this.commonService.modalEmailData;

  ngOnInit() {}

  close()
  {
    this.dialogRef.close();
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }
}
