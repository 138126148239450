import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-verify-msg',
  templateUrl: './verify-msg.component.html',
  styleUrls: ['./verify-msg.component.scss']
})
export class VerifyMsgComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  ngOnInit() {
  }

}
