import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "src/app/services/common/common.service";
import { HttpService } from "src/app/services/http/http.service";

@Component({
  selector: "app-privarypolicy",
  templateUrl: "./privarypolicy.component.html",
  styleUrls: ["./privarypolicy.component.scss"],
})
export class PrivarypolicyComponent implements OnInit {
  privacy: string = "";
  constructor(
    private http: HttpService,
    private commonbService: CommonService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.http.httpGet("adminCms").subscribe((res: any) => {
      if (res.status == 200) {
        this.privacy = res.result.privacyPolicy;
      } else {
        this.commonbService.presentsToast("error", "top-end", res.message);
      }
    });
  }
}
