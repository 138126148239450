import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  EventSettingsModel, ScheduleComponent, View, EventRenderedArgs, DayService, WeekService, WorkWeekService, MonthService, AgendaService, ResizeService, DragAndDropService
} from '@syncfusion/ej2-angular-schedule';
import * as moment from 'moment';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit {
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  @ViewChild("scheduleObj", { static: false }) scheduleObj: ScheduleComponent;
  selectedDate: Date = new Date();
  public eventSettings: any = { dataSource: [] };
  public currentView: View = 'Week';
  userId: any;
  allSlots: any[] = [];
  professional: any;
  imageBaseurl: any = environment.imageUrl;

  constructor(private _formBuilder: FormBuilder, private https: HttpService, private routes: ActivatedRoute, public translate: TranslateService) {
    this.userId = this.routes.snapshot.paramMap.get("id");
  }
  public month: number = new Date().getMonth();
  public fullYear: number = new Date().getFullYear();
  public dateValue: Date = new Date();
  public minDate: Date = new Date(this.fullYear, this.month, 9);
  public maxDate: Date = new Date(this.fullYear, this.month, 15);
  ngOnInit() {
    this.getBokkingSlot(this.dateValue)
    this.getProfessional();
  }

  getProfessional() {
    this.https
      .httpGetWithHeader("professional", "id=" + this.userId)
      .subscribe((res: any) => {
        if (res.status == 200) {
          console.log(res.result)
          this.professional = res.result;
        }
      });
  }

  getBokkingSlot(date: any) {
    var param = "id=" + this.userId + '&' + "date=" + date;
    this.https.httpGetWithQueryandHeader("getBokkingSlot", param).subscribe((res: any) => {
      if (res.status == 200) {
        console.log(res)
        this.allSlots = res.result;
      }
    })
  }
  onCellClick(event: any) {
  }

  changeCalenderdate(event: any) {
    console.log(event)
    this.getBokkingSlot(event.value)
  }
  onActionComplete(): void {
  }

  getMonth() {
    let my_date = new Date();
    let firstDate = this.format(new Date(my_date.getFullYear(), my_date.getMonth(), 1));
    let lastDate = this.format(new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0));
  }

  format(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  getScheduledNotices(fromDate, toDate) {
    let param = 'fromDate=' + fromDate + '&toDate=' + toDate;
    return;
  }
}
