import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth-guard/auth.service";
import { HttpService } from "src/app/services/http/http.service";
import { environment } from "src/environments/environment";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
declare var $:any;

@Component({
  selector: "app-appointments",
  templateUrl: "./appointments.component.html",
  styleUrls: ["./appointments.component.scss"],
})
export class AppointmentsComponent implements OnInit {
  constructor(
    private http: HttpService,
    private auth: AuthService,
    private router: Router,
    public translate: TranslateService
  ) { 
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  public appointments: any;
  public history: any[] = [];
  public ongoing: any[] = [];
  public onloading: any;
  isDescription: boolean = false;
  imageUrl: string = environment.imageUrl;
  textChange: boolean = false;
  moment: any = moment();
  page: any = 1;
  totalPage: any;
  page2: any = 1;
  totalPage2: any;
  allOngoing: any[] = [];
  allHistory: any[] = [];
  ongoingPage:any = 1;
  pastPage:any = 1;
  onGoingAppointments:any[] = [];
  pastAppointments:any[] = [];
  onGoingCount:any;
  pastCount:any;
  ngOnInit() {
    $('#desc').toggleClass('show-hide', false);
    this.getOnGoingBookings();
    this.getPastAppointments();
  }

  toggleShowHide(e: any) {
    e.preventDefault()
    $('#desc').toggleClass('show-hide');
    $('#moreTe').toggleClass('allDesc');
    this.textChange = !this.textChange;
  }

  changeString(data: any) {
    if (data !== undefined) {
      if (data.length <= 60) {
        this.isDescription = false;
        return data;
      }
      else {
        this.isDescription = true;
        return data.substr(0, 60) + '...'
      }
    }
  }

  getPastAppointments()
  {
    var param = 'status=IGNORED,EXPIRED,COMPLETED,CANCELED&page=' + this.pastPage;
    this.http.httpGetWithHeader("bookings", param).subscribe((res: any) => {
      if (res.status == 200) {
        res.result.forEach((element:any) => {
          this.pastAppointments.push(element)
        });
        this.totalPage2 = res.totalPages;
        this.onGoingCount = res.ongoing;
        this.pastCount = res.past;
      }
    });
  }

  getOnGoingBookings() {
    var param = 'status=ACCEPTED,PENDING,ONGOING&page=' + this.ongoingPage;
    this.http.httpGetWithHeader("bookings", param).subscribe((res: any) => {
      if (res.status == 200) {
        res.result.forEach((element:any) => {
          this.onGoingAppointments.push(element)
        });
        this.totalPage = res.totalPages;
        this.onGoingCount = res.ongoing;
        this.pastCount = res.past;
        //this.appointments = res.result;
       /// console.log(this.appointments)
        // this.allOngoing = this.appointments.filter(
        //   (appoint: any) =>
        //     appoint.professional &&
        //     (appoint.bookingStatus == "PENDING" ||
        //       appoint.bookingStatus == "ONGOING" ||
        //       appoint.bookingStatus == "ACCEPTED")
        // );
        // this.allHistory = this.appointments.filter(
        //   (appoint: any) =>
        //     appoint.professional &&
        //     (appoint.bookingStatus == "IGNORED" ||
        //       appoint.bookingStatus == "EXPIRED" ||
        //       appoint.bookingStatus == "COMPLETED" ||
        //       appoint.bookingStatus == "CANCELED")
        // );
        // var totalOngoingPages = this.allOngoing.length;
        // this.totalPage = Math.ceil(totalOngoingPages / 6)
        // var totalHistorypages = Math.ceil(this.allHistory.length / 6);
        // this.totalPage2 = totalHistorypages;
        // if (this.allOngoing.length > 0) {
        //   for (let i = 0; i < (this.allOngoing.length > 6 ? 6 : this.allOngoing.length); i++) {
        //     this.ongoing.push(this.allOngoing[i]);
        //     this.allOngoing.splice(i, 1)
        //   }
        // }
        // if (this.allHistory.length > 0) {
        //   for (let i = 0; i < (this.allHistory.length > 6 ? 6 : this.allHistory.length); i++) {
        //     this.history.push(this.allHistory[i]);
        //     this.allHistory.splice(i, 1);
        //   }
        // }
      }
    });
  }

  navigateWithData(data: any) {
    //localStorage.setItem('appointmentData', JSON.stringify(data))
    this.router.navigate(['/bookingdetail/' + data._id]);
  }

  changeIntoGMT(time: any) {
    var gmtTime = time.toString();
    return gmtTime;
  }

  onScroll() {
    this.ongoingPage = this.ongoingPage + 1;
    if (this.totalPage > this.ongoingPage) {
      this.getOnGoingBookings();
  }
  }

  onScrollUp() { }

  activetab(tab:any)
  {
    if(tab == 'home')
    {
      this.onGoingAppointments = [];
      this.ongoingPage = 1;
      this.getOnGoingBookings();
    }
    if(tab == 'profile')
    {
      this.pastAppointments = [];
      this.pastPage = 1;
      this.getPastAppointments();
    }
    $('.nav-tabs a[href="#' + tab + '"]').tab('show');
  }

  onScroll2() {
    this.pastPage = this.pastPage + 1;
    if (this.totalPage2 > this.pastPage) {
      this.getPastAppointments();
  }
  }

  onScrollUp2() { }
}
