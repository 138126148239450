import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-seccess-message",
  templateUrl: "./seccess-message.component.html",
  styleUrls: ["./seccess-message.component.scss"],
})
export class SeccessMessageComponent implements OnInit {
  isDevice: boolean = false;
  constructor(private router: Router, public translate: TranslateService) {
    this.isDevice = localStorage.isDevice ? localStorage.isDevice : false;
  }

  ngOnInit() { }

  goToHome() {
    if (this.isDevice == true) {
      this.router.navigateByUrl("/home");
    } else {
      return;
    }
  }
}
