import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MaterialModule } from "./material/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgOtpInputModule } from "ng-otp-input";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ModalPopupComponent } from "./shared/modal-popup/modal-popup.component";
import { HeaderComponent } from "./component/header/header.component";
import { FooterComponent } from "./component/footer/footer.component";
import { HomeComponent } from "./pages/home/home.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { SliderComponent } from "./pages/slider/slider.component";
import { DemoComponent } from "./demo/demo.component";
import { TermsservicesComponent } from "./pages/termsservices/termsservices.component";
import { PrivarypolicyComponent } from "./pages/privarypolicy/privarypolicy.component";
import { CookiepolicyComponent } from "./pages/cookiepolicy/cookiepolicy.component";
import { apiList } from "./services/http/apilist";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoginComponent } from "./component/login/login.component";
import { ForgotComponent } from "./component/forgot/forgot.component";
import { SignupComponent } from "./component/signup/signup.component";
import { VerifyComponent } from "./component/verify/verify.component";
import { ProfileSetupComponent } from "./component/profile-setup/profile-setup.component";
import { ProfessionalComponent } from "./pages/professional/professional.component";
import { ArtistAppoimtmentComponent } from "./pages/artist-appoimtment/artist-appoimtment.component";
import { CleandarBookingComponent } from "./pages/cleandar-booking/cleandar-booking.component";
import { SignupLandingComponent } from "./component/signup-landing/signup-landing.component";
import { HttpInterceptorService } from "./services/interceptor/http-interceptor.service";
import { NgxUiLoaderModule, NgxUiLoaderService } from "ngx-ui-loader";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatDialogRef } from "@angular/material/dialog";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgxPhotoEditorModule } from "ngx-photo-editor";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { MatMomentDatetimeModule } from "@mat-datetimepicker/moment";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

import {
  AuthGuardService,
  AuthService,
} from "./services/auth-guard/auth.service";
import { ForgetPasswordResetComponent } from "./component/forget-password-reset/forget-password-reset.component";
import { LoginPhoneComponent } from "./component/login-phone/login-phone.component";
import { CongraultionComponent } from "./component/congraultion/congraultion.component";
import { VerifyMsgComponent } from "./component/verify-msg/verify-msg.component";
import { ModalPopupEmailComponent } from "./shared/modal-popup-email/modal-popup-email.component";
import { LoaderComponent } from "./shared/loader/loader.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { ResetPasswordComponent } from "./component/reset-password/reset-password.component";
import { NotificationComponent } from "./pages/notification/notification.component";
import { AppointmentsComponent } from "./component/appointments/appointments.component";
import { ChatComponent } from "./component/chat/chat.component";
import { MainChatComponent } from "./chat/main-chat.component";
import { environment } from "src/environments/environment";
import { VerificationComponent } from "./components/verification/verification.component";
import { Chat2Component } from "./chat2/chat2.component";
import { EditProfileComponent } from "./component/edit-profile/edit-profile.component";
import { AgmCoreModule } from "@agm/core";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { PiercingProfessionalComponent } from "./pages/piercing-professional/piercing-professional.component";
import { ScrollDirective } from "./services/directive.directive";
import { CalenderComponent } from "./pages/calender/calender.component";
import { CalendarModule } from "@syncfusion/ej2-angular-calendars";
import { ImageDrawingModule } from "ngx-image-drawing";
import { ServiceWorkerModule } from "@angular/service-worker";

import {
  AgendaService,
  DayService,
  DragAndDropService,
  MonthService,
  ResizeService,
  ScheduleModule,
  WeekService,
  WorkWeekService,
} from "@syncfusion/ej2-angular-schedule";
import { GalleryComponent } from "./pages/gallery/gallery.component";
import { BookingdetailComponent } from "./pages/bookingdetail/bookingdetail.component";
import { FavretComponent } from "./pages/favret/favret.component";
import { DisclaimerConcentComponent } from "./component/disclaimer-concent/disclaimer-concent.component";
import { TattooConsultationComponent } from "./pages/tattoo-consultation/tattoo-consultation.component";
import { PiercingConsultationComponent } from "./pages/piercing-consultation/piercing-consultation.component";
import { SeccessMessageComponent } from "./component/seccess-message/seccess-message.component";
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { PaymentComponent } from './pages/payment/payment.component';
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { PaymentCompletedComponent } from './pages/payment-completed/payment-completed.component';
import { JwtHelperService } from '@auth0/angular-jwt';

const config: SocketIoConfig = { url: environment.baseUrl, options: {} };

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SliderComponent,
    DemoComponent,
    TermsservicesComponent,
    PrivarypolicyComponent,
    CookiepolicyComponent,
    LoginComponent,
    ForgotComponent,
    SignupLandingComponent,
    SignupComponent,
    VerifyComponent,
    ProfileSetupComponent,
    ProfessionalComponent,
    ArtistAppoimtmentComponent,
    CleandarBookingComponent,
    ModalPopupComponent,
    ForgetPasswordResetComponent,
    CongraultionComponent,
    VerifyMsgComponent,
    LoginPhoneComponent,
    ModalPopupEmailComponent,
    LoaderComponent,
    NotFoundComponent,
    ResetPasswordComponent,
    NotificationComponent,
    AppointmentsComponent,
    ChatComponent,
    VerificationComponent,
    MainChatComponent,
    Chat2Component,
    EditProfileComponent,
    PiercingProfessionalComponent,
    ScrollDirective,
    CalenderComponent,
    GalleryComponent,
    BookingdetailComponent,
    FavretComponent,
    DisclaimerConcentComponent,
    TattooConsultationComponent,
    PiercingConsultationComponent,
    SeccessMessageComponent,
    PaymentComponent,
    PaymentCompletedComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    NgOtpInputModule,
    NgxIntlTelInputModule,
    NgbModule,
    NgxUiLoaderModule,
    SocketIoModule.forRoot(config),
    GooglePlaceModule,
    NgxPhotoEditorModule,
    ImageDrawingModule,
    CalendarModule,
    InfiniteScrollModule,
    MatDatetimepickerModule,
    MatMomentDatetimeModule,
    AgmCoreModule.forRoot({
      // apiKey: "AIzaSyBtQM4mMC_2ymYKqLY72gj8vxYnhNlKYh4",
      apiKey: "AIzaSyBWIFKaRjrCO5mkqj59TwGoLoQGG7FEDeY",
      libraries: ["places"],
    }),
    ScheduleModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      registrationStrategy: "registerWhenStable:30000",
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    JwtHelperService,
    NgxUiLoaderService,
    apiList,
    AuthService,
    AuthGuardService,
    LoaderComponent,
    AgendaService,
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    DragAndDropService,
    ResizeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    { provide: MatDialogRef, useValue: {} },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ModalPopupComponent, ModalPopupEmailComponent],
})
export class AppModule {}
