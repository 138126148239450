import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/services/auth-guard/auth.service";

@Component({
  selector: "app-verify",
  templateUrl: "./verify.component.html",
  styleUrls: ["./verify.component.scss"],
})
export class VerifyComponent implements OnInit {
  constructor(private auth: AuthService, private route: ActivatedRoute, public translate: TranslateService) { }

  ngOnInit() {
    console.log(
      "this.route.snapshot.paramMap.get",
      this.route.snapshot.paramMap.get("code")
    );
    this.auth.verifyEmail(this.route.snapshot.paramMap.get("code"));
  }
}
