import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth-guard/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {

  constructor(private auth: AuthService, private route: ActivatedRoute, private http: HttpService, private router: Router, private commonbService: CommonService, public translate: TranslateService) { }
  code: any;
  type: any;
  verifiactionsuccess: boolean = false;
  ngOnInit() {
    this.code = this.route.snapshot.paramMap.get("code");
    this.type = this.route.snapshot.paramMap.get("type");
    if (this.type == "professional") {
      this.http.httpGetWithParamWithoutObservable("verifyEmailForProfessional", this.code).then((res: any) => {
        if (res.status === 200) {
          this.commonbService.presentsToast("success", "top-end", res.message);
          this.verifiactionsuccess = true;
        } else {
          this.commonbService.presentsToast("error", "top-end", res.message);
          this.verifiactionsuccess = false;
        }
      }, error => {
        this.verifiactionsuccess = false;
      })
    }
    if (this.type == "customer") {
      this.http.httpGetWithParamWithoutObservable("verifyEmailForCustomer", this.code).then((res: any) => {
        if (res.status === 200) {
          this.commonbService.presentsToast("success", "top-end", res.message);
          this.verifiactionsuccess = true;
        } else {
          this.commonbService.presentsToast("error", "top-end", res.message);
          this.verifiactionsuccess = false;
        }
      }, error => {
        this.verifiactionsuccess = false;
      })
    }
  }

}
