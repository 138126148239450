// google-auth.service.ts
import { Injectable } from '@angular/core';

declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {
  private auth2: any;

  constructor() {}

  initGoogleAuth() {
    console.log('Initializing Google Auth');
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: '752663413722-r847f254bha3scnb71vpt5u9g1ek04fq.apps.googleusercontent.com',
        scope: 'profile email'
      });
    });
  }

  signInWithGoogle() {
    return this.auth2.signIn().then(user => user.getBasicProfile());
  }
}
