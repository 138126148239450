import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FooterComponent } from "./component/footer/footer.component";
import { ForgotComponent } from "./component/forgot/forgot.component";
import { HeaderComponent } from "./component/header/header.component";
import { LoginComponent } from "./component/login/login.component";
import { ProfileSetupComponent } from "./component/profile-setup/profile-setup.component";
import { SignupComponent } from "./component/signup/signup.component";
import { VerifyComponent } from "./component/verify/verify.component";
import { ArtistAppoimtmentComponent } from "./pages/artist-appoimtment/artist-appoimtment.component";
import { CleandarBookingComponent } from "./pages/cleandar-booking/cleandar-booking.component";

import { CookiepolicyComponent } from "./pages/cookiepolicy/cookiepolicy.component";

import { HomeComponent } from "./pages/home/home.component";
import { PrivarypolicyComponent } from "./pages/privarypolicy/privarypolicy.component";
import { ProfessionalComponent } from "./pages/professional/professional.component";

import { TermsservicesComponent } from "./pages/termsservices/termsservices.component";
import { SignupLandingComponent } from "./component/signup-landing/signup-landing.component";
import { ForgetPasswordResetComponent } from "./component/forget-password-reset/forget-password-reset.component";
import { LoginPhoneComponent } from "./component/login-phone/login-phone.component";
import { CongraultionComponent } from "./component/congraultion/congraultion.component";
import { VerifyMsgComponent } from "./component/verify-msg/verify-msg.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { ResetPasswordComponent } from "./component/reset-password/reset-password.component";
import { NotificationComponent } from "./pages/notification/notification.component";
import { AppointmentsComponent } from "./component/appointments/appointments.component";
import { VerificationComponent } from "./components/verification/verification.component";
import { MainChatComponent } from "./chat/main-chat.component";
import { Chat2Component } from "./chat2/chat2.component";
import { EditProfileComponent } from "./component/edit-profile/edit-profile.component";
import { PiercingProfessionalComponent } from "./pages/piercing-professional/piercing-professional.component";
import { CalenderComponent } from "./pages/calender/calender.component";
import { GalleryComponent } from "./pages/gallery/gallery.component";
import { BookingdetailComponent } from "./pages/bookingdetail/bookingdetail.component";
import { FavretComponent } from "./pages/favret/favret.component";
import { DisclaimerConcentComponent } from "./component/disclaimer-concent/disclaimer-concent.component";
import { TattooConsultationComponent } from "./pages/tattoo-consultation/tattoo-consultation.component";
import { PiercingConsultationComponent } from "./pages/piercing-consultation/piercing-consultation.component";
import { SeccessMessageComponent } from "./component/seccess-message/seccess-message.component";
import { AuthGuardService } from "./services/auth-guard/auth.service";
import { PaymentComponent } from "./pages/payment/payment.component";
import { PaymentCompletedComponent } from "./pages/payment-completed/payment-completed.component";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "termsservices",
    component: TermsservicesComponent,
  },
  {
    path: "privarypolicy",
    component: PrivarypolicyComponent,
  },
  {
    path: "edit-profile",
    component: EditProfileComponent,
  },
  {
    path: "cookiepolicy",
    component: CookiepolicyComponent,
  },
  {
    path: "footer",
    component: FooterComponent,
  },
  {
    path: "header",
    component: HeaderComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "login-phone",
    component: LoginPhoneComponent,
  },
  {
    path: "404",
    component: NotFoundComponent,
  },
  {
    path: "congraultions",
    component: CongraultionComponent,
  },
  {
    path: "verify-msg",
    component: VerifyMsgComponent,
  },
  {
    path: "forgot",
    component: ForgotComponent,
  },
  {
    path: "forgotPassword/:code",
    component: ForgetPasswordResetComponent,
  },
  {
    path: "forgotPassword/:type/:code",
    component: ForgetPasswordResetComponent,
  },
  {
    path: "signup-landing",
    component: SignupLandingComponent,
  },
  {
    path: "signup",
    component: SignupComponent,
  },
  {
    path: "verifyEmail/:code",
    component: VerifyComponent,
  },
  {
    path: "verify/:type/:code",
    component: VerificationComponent,
  },
  {
    path: "profile-set",
    component: ProfileSetupComponent,
    canActivate:[AuthGuardService]
  },
  {
    path: "professional",
    component: ProfessionalComponent,
  },
  {
    path: "artist-appointment/:proid",
    component: ArtistAppoimtmentComponent,
  },
  {
    path: "notification",
    component: NotificationComponent,
  },
  {
    path: "booking",
    component: CleandarBookingComponent,
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
  },
  {
    path: "appointments",
    component: AppointmentsComponent,
  },
  {
    path: "404",
    component: NotFoundComponent,
  },
  {
    path: "chat",
    component: Chat2Component,
  },
  {
    path: "calender/:id",
    component: CalenderComponent,
  },
  {
    path: "gallery/:id/:chatId",
    component: GalleryComponent,
  },
  {
    path: "bookingdetail/:id",
    component: BookingdetailComponent,
  },
  {
    path: "favourite",
    component: FavretComponent,
  },
  {
    path: "disclaimer-concent/:id/:type",
    component: DisclaimerConcentComponent,
  },

  {
    path: "disclaimer-concent/:id/:type/:device",
    component: DisclaimerConcentComponent,
  },

  {
    path: "tattooConsultaion",
    component: TattooConsultationComponent,
  },
  {
    path: "piercingConsultation",
    component: PiercingConsultationComponent,
  },
  {
    path: "success-message/:status",
    component: SeccessMessageComponent,
  },
  {
    path: "payment/:id",
    component: PaymentComponent,
  },
  {
    path: "payment-completed",
    component: PaymentCompletedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
