import { Injectable } from "@angular/core";
import * as socketIo from "socket.io-client";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class NotifySocketService {
  socket: any;
  recievedData: any;
  accessAuthToken: any;
  public isNotificationtruefalse: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public listenNotification: BehaviorSubject<any> = new BehaviorSubject<any>(
    {}
  );
  public isNotificationData: BehaviorSubject<object> =
    new BehaviorSubject<object>({});
  constructor() {
    this.accessAuthToken =
      localStorage.accessToken !== undefined ? localStorage.accessToken : "";
  }

  initSocket() {
    if (
      !(localStorage.accessToken == undefined ||
      localStorage.accessToken == null || localStorage.accessToken == '')
    ) {
      var jswtoken = this.accessAuthToken;

      this.socket = socketIo.connect(environment.imageUrl, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 3000,
        reconnectionAttempts: Infinity,
        query: {
          token: jswtoken,
        },
      });

      this.socket.on("connect", (res: any) => {
        console.log("debug connect", res);
        this.socket.on("unreadNotificationCount", (item: any) => {
          console.log("connectionokforrecieved", item);
          this.isNotificationtruefalse.next(true);
          this.isNotificationData.next(item);
        });
      });

      this.socket.on("disconnect", (message: any) => {
        console.log("debug disconnect", message);
      });
    }
  }

  emitAction(action: any, payload: any): void {
    this.socket.emit(action, payload);
    console.log(payload);
  }

  public onEvent(event: any): Observable<any> {
    return new Observable<any>((observer) => {
      this.socket.on(event, (data) => observer.next(data));
    });
  }
}
