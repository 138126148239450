import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "../../services/http/http.service";
import {
  SearchCountryField,
  CountryISO,
} from "ngx-intl-tel-input";
import { environment } from "src/environments/environment";
import { Location } from "@angular/common";
import { CommonService } from "src/app/services/common/common.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TranslateService } from "@ngx-translate/core";
declare var $: any;
interface inputData {
  type: string;
  label: string;
  checked: boolean;
}

interface formDataModel {
  ques: any;
  ans: boolean;
}
interface diesesFormDataModel {
  diesesQues: any;
  diesesAns: boolean;
}
@Component({
  selector: "app-disclaimer-concent",
  templateUrl: "./disclaimer-concent.component.html",
  styleUrls: ["./disclaimer-concent.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DisclaimerConcentComponent implements OnInit {
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  separateDialCode = false;
  addForm: any;
  submitStatus: boolean = false;
  submitdieses: boolean = false;
  addDiesesForm: any;
  todo: inputData[] = [];
  diesesInput: inputData[] = [];
  formData: formDataModel[] = [];
  diesesFormData: diesesFormDataModel[] = [];
  showAddRemoveButtons: boolean = true;
  disclaimerConcentForm: FormGroup;
  acceptanceReason: any;
  medicalChecked: any = undefined;
  careChecked: any = undefined;
  medicationChecked: any = undefined;
  acceptanceChecked: any = undefined;
  concentAcceptance: any = undefined;
  allergyChecked: any = undefined;
  submmited: boolean = false;
  fileData: File;
  trueFormData: any[] = [];
  trueDiesesFormData: any[] = [];
  isShowConsent: boolean = false;
  isDisclaimer: boolean = false;
  medicationReason: any;
  allergyReason: any;
  medicalReason: any;
  selectedIndex: any = 0;
  userId: any;
  type: any;
  device: any;
  imageUrl: any;
  imageBaseUrl: any = environment.imageUrl;
  statements: any;
  acceptances: any;
  eighteenYearBackFromNow: any;
  date: any;
  finalDate: any;
  isCustomerFilled: boolean = false;
  imageFullUrl: any;

  constructor(
    private fb: FormBuilder,
    private http: HttpService,
    private routes: ActivatedRoute,
    private _location: Location,
    private router: Router,
    private common: CommonService,
    private ngxService: NgxUiLoaderService,
    public translate: TranslateService,
    private commonService:CommonService
  ) {
    this.eighteenYearBackFromNow = new Date();
    this.eighteenYearBackFromNow.setFullYear(
      this.eighteenYearBackFromNow.getFullYear() - 18
    );
    console.log(this.eighteenYearBackFromNow);
    this.date = new Date(this.eighteenYearBackFromNow);
    var month = "" + (this.date.getMonth() + 1);
    var day = "" + this.date.getDate();
    var year = this.date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    this.finalDate = year + "-" + month + "-" + day;
    console.log(this.finalDate);
    this.userId = this.routes.snapshot.paramMap.get("id");
    console.log(this.userId);
    this.type = this.routes.snapshot.paramMap.get("type");
    console.log(this.type);
    this.device = this.routes.snapshot.paramMap.get("device")
      ? this.routes.snapshot.paramMap.get("device")
      : "";
  }

  ngOnInit(): void {
    this.disclaimerConcentForm = this.fb.group({
      prefix: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]+$/),
      ]),
      given: new FormControl("", [Validators.pattern(/^[a-zA-Z ]+$/)]),
      family: new FormControl("", [Validators.pattern(/^[a-zA-Z ]+$/)]),
      suffix: new FormControl("", [Validators.pattern(/^[a-zA-Z ]+$/)]),
      birthDate: new FormControl("", Validators.required),
      phoneNo: new FormControl("", Validators.required),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(
          "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
        ),
      ]),
      file: new FormControl("", Validators.required),
      //allergy: new FormControl("", Validators.pattern("^.{2,250}$")),
      // medi: new FormControl("", Validators.pattern("^.{2,250}$")),
      // care: new FormControl("", Validators.pattern("^.{2,250}$")),
      acceptance: new FormControl("", Validators.required),
      statement: new FormControl("", Validators.required),
    });
    this.getAllInput();
  }

  getAllDataForPatch() {
    this.http
      .httpGetWithHeaderforForm(
        "https://appgrowthcompany.com:1920/api/v1/admin/disclaimerConcent?customerId=" +
        this.userId
      )
      .subscribe((res: any) => {
        if (res.status == 200) {
          let allData = res.result;
          let birthDate = new Date(allData.birthDate);
          var month = "" + (birthDate.getMonth() + 1);
          var day = "" + birthDate.getDate();
          var year = birthDate.getFullYear();
          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          let finalDate = year + "-" + month + "-" + day;
          console.log(finalDate);
          this.disclaimerConcentForm.patchValue({
            prefix: allData.prefixName,
            given: allData.givenName,
            family: allData.familyName,
            suffix: allData.suffixName,
            birthDate: finalDate,
            phoneNo: allData.countryCode + allData.phoneNo,
            email: allData.email,
            //allergy: allData.allergyData.reason,
            //medi: allData.medicationData.reason,
            //care: allData.medicalData.reason,
            acceptance: allData.acceptance,
            statement: allData.consentAcceptance,
          });
          this.allInputs.questions = allData.questions;
          // this.allergyChecked = allData.allergyData.condition;
          // this.medicalChecked = allData.medicalData.condition;
          // this.medicationChecked = allData.medicationData.condition;
          // this.careChecked = allData.care;
          this.imageUrl = this.imageBaseUrl + allData.file;
          this.concentAcceptance = allData.consentAcceptance;
          this.acceptanceChecked = allData.acceptance;
          this.todo.forEach((item) => {
            allData.disclaimer.forEach((element) => {
              if (item.label == element.ques) {
                item.checked = true;
              }
            });
          });
          this.diesesInput.forEach((data) => {
            allData.concent.forEach((ele) => {
              if (data.label == ele.diesesQues) {
                data.checked = true;
              }
            });
          });
        }
        var switching = true;
        if (this.type == "professional") {
          switching = !switching;
          $(":radio").attr("disabled", switching);
        }
      });
  }
  allInputs:any;
  onQuestionInputChange(event:Event,data,index:any)
  {
    event.stopPropagation();
    data = true;
    //this.allInputs.questions[index]['answer'] = true;
    //event.preventDefault();
  }
  onQuestionNoInputChange(event:any,data,index:any)
  {
    event.stopPropagation();
    data = false;
    //this.allInputs.questions[index]['answer'] = false;
    this.allInputs.questions[index]['reason'] = '';
    //event.preventDefault();
  }
  getAllInput() {
    this.http
      .httpGetWithHeaderforForm(
        "https://appgrowthcompany.com:1920/api/v1/admin/form"
      )
      .subscribe((res: any) => {
        if (res.status == 200) {
          console.log(res.result);
          this.allInputs = res.result[0];
          this.allInputs['questions'].forEach((response:any)=>{
            delete response['_id']
          })
          res.result[0].disclaimer.forEach((res: any) => {
            this.formData;
            this.todo.push({
              type: res.type,
              label: res.label,
              checked: false,
            });
          });
          res.result[0].concent.forEach((item: any) => {
            this.diesesInput.push({
              type: item.type,
              label: item.label,
              checked: false,
            });
            if (this.type == "professional") {
              this.getAllDataForPatch();
            }
          });
        }
      });
  }


  avoidSpace(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 32) return false;
  }


  checkBoxes(event: any, label: any, index: any) {
    this.trueFormData = [];
    this.formData.forEach((res: any) => {
      if (res.ques === label) {
        this.formData.splice(res, index);
      }
    });
    if (event.target.checked == true) {
      this.formData.push({
        ques: label,
        ans: event.target.checked,
      });
    }
  }

  checkDiesesBoxes(event: any, label: any, index: any) {
    this.diesesFormData.forEach((res: any) => {
      if (res.diesesQues === label) {
        this.diesesFormData.splice(res, index);
      }
    });
    if (event.target.checked == true) {
      this.diesesFormData.push({
        diesesQues: label,
        diesesAns: event.target.checked,
      });
    }
  }

  renderUserSide() {
    this.showAddRemoveButtons = false;
  }

  onFormSubmit() {
    this.submmited = true;
    let isValid = true;
    for(let i = 0; i < this.allInputs.questions.length; i++)
    {
      if((this.allInputs.questions[i]['answer'] == undefined || this.allInputs.questions[i]['answer'] == null) || ((this.allInputs.questions[i]['answer'] == true || this.allInputs.questions[i]['answer'] == 'true') && (this.allInputs.questions[i]['reason'] == undefined || this.allInputs.questions[i]['reason'] == null || this.allInputs.questions[i]['reason'] == '')))
      {
        isValid = false;
        break;
      }
    }
    // this.medicationReason = this.disclaimerConcentForm.value.medi.trim();
    // this.allergyReason = this.disclaimerConcentForm.value.allergy.trim();
    // this.medicalReason = this.disclaimerConcentForm.value.care.trim();
    if (
      this.submmited && isValid &&
      this.disclaimerConcentForm.valid &&
      this.formData.length > 0 &&
      !(
        // this.medicalChecked == undefined &&
        // this.medicationChecked == undefined &&
        // this.careChecked == undefined &&
        (this.acceptanceChecked == undefined ||
          this.acceptanceChecked == false) &&
        (this.concentAcceptance == undefined ||
          this.concentAcceptance == false)
          //&&
        //this.allergyChecked == undefined
      )
    ) {

      // var medicationData = {
      //   condition: this.medicationChecked,
      //   reason:
      //     this.medicationChecked == true
      //       ? this.disclaimerConcentForm.value.medi.trim()
      //       : "",
      // };
      // var allergyData = {
      //   condition: this.allergyChecked,
      //   reason:
      //     this.allergyChecked == true
      //       ? this.disclaimerConcentForm.value.allergy.trim()
      //       : "",
      // };
      // var medicalData = {
      //   condition: this.medicalChecked,
      //   reason:
      //     this.medicalChecked == true
      //       ? this.disclaimerConcentForm.value.care.trim()
      //       : "",
      // };
      let formDataValue = new FormData();
      formDataValue.append(
        "prefixName",
        this.disclaimerConcentForm.value.prefix
      );
      formDataValue.append("givenName", this.disclaimerConcentForm.value.given);
      formDataValue.append(
        "familyName",
        this.disclaimerConcentForm.value.family
      );
      formDataValue.append(
        "suffixName",
        this.disclaimerConcentForm.value.suffix
      );
      formDataValue.append(
        "birthDate",
        this.disclaimerConcentForm.value.birthDate
      );
      var phoneCode: any;
      var diaCode = this.disclaimerConcentForm.value.phoneNo.dialCode;
      if (diaCode.includes("+")) {
        phoneCode = diaCode.slice(1);
      } else {
        phoneCode = diaCode;
      }
      var codeLength = this.disclaimerConcentForm.value.phoneNo.dialCode.length;
      formDataValue.append(
        "phoneNo",
        this.disclaimerConcentForm.value.phoneNo.e164Number.slice(codeLength)
      );
      formDataValue.append("countryCode", phoneCode);
      formDataValue.append("email", this.disclaimerConcentForm.value.email);
      // formDataValue.append("allergyData", JSON.stringify(allergyData));
      // formDataValue.append("medicationData", JSON.stringify(medicationData));
      // formDataValue.append("medicalData", JSON.stringify(medicalData));
      formDataValue.append("file", this.fileData);
      //formDataValue.append("care", this.careChecked);
      formDataValue.append("acceptance", this.acceptanceChecked);
      formDataValue.append("consentAcceptance", this.concentAcceptance);
      formDataValue.append("disclaimer", JSON.stringify(this.formData));
      formDataValue.append("concent", JSON.stringify(this.diesesFormData));
      formDataValue.append("questions", JSON.stringify(this.allInputs.questions));
      formDataValue.append("userId", this.userId);
      console.log("formSubmitted");
      this.ngxService.start();
      this.http
        .httppostPromise(
          "https://appgrowthcompany.com:1920/api/v1/disclaimerConcent",
          formDataValue
        )
        .then((res: any) => {
          this.ngxService.stop();
          if (res.status == 200) {
            if (this.device == "web") {
              localStorage.setItem("isDevice", "true");
              this.router.navigateByUrl("/home");
              this.disclaimerConcentForm.reset();
              this.submmited = false;
              this.isDisclaimer = false;
              this.formData = [];
              this.diesesFormData = [];
              this.medicalChecked = undefined;
              this.medicationChecked = undefined;
              this.allergyChecked = undefined;
              this.careChecked = undefined;
              this.acceptanceChecked = undefined;
              this.concentAcceptance = undefined;
            } else {
              this.disclaimerConcentForm.reset();
              this.submmited = false;
              this.isDisclaimer = false;
              this.formData = [];
              this.diesesFormData = [];
              this.medicalChecked = undefined;
              this.medicationChecked = undefined;
              this.allergyChecked = undefined;
              this.careChecked = undefined;
              this.acceptanceChecked = undefined;
              this.concentAcceptance = undefined;
              this.router.navigate(['success-message/true']);
            }
          }
        }).catch((err: any) => {
          this.ngxService.stop();
        });
    }
  }

  onFileChange(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      if (
        !(
          fileInput.target.files[0].type == "image/jpeg" ||
          fileInput.target.files[0].type == "image/jpg" ||
          fileInput.target.files[0].type == "image/png"
        )
      ) {
        this.common.presentsToast(
          "error",
          "top-end",
          this.translate.instant("DRIVING_LICENCE_OR_PASSWORD")
        );
      } else {
        this.fileData = <File>fileInput.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(fileInput.target.files[0]);
        reader.onload = (event) => {
          this.imageUrl = reader.result as string;
        };
      }
    }
  }

  onAllergyChange(event: any, value: boolean) {
    // var eventValue = event.target.value;
    // if (eventValue == "on") {
    //   this.allergyChecked = value;
    //   if (this.allergyChecked == true) {
    //     this.disclaimerConcentForm.controls["allergy"].setValidators(
    //       Validators.required
    //     );
    //     this.disclaimerConcentForm.controls["allergy"].updateValueAndValidity();
    //   }
    // }
  }

  onMedicationChange(event: any, value: boolean) {
    // var eventValue = event.target.value;
    // if (eventValue == "on") {
    //   this.medicationChecked = value;
    //   if (this.medicationChecked == true) {
    //     this.disclaimerConcentForm.controls["medi"].setValidators(
    //       Validators.required
    //     );
    //     this.disclaimerConcentForm.controls["medi"].updateValueAndValidity();
    //   }
    // }
  }

  onCareChange(event: any, value: boolean) {
    // var eventValue = event.target.value;
    // if (eventValue == "on") {
    //   this.careChecked = value;
    // }
  }

  onMedicalCahnge(event: any, value: boolean) {
    // var eventValue = event.target.value;
    // if (eventValue == "on") {
    //   this.medicalChecked = value;
    //   if (this.medicalChecked == true) {
    //     this.disclaimerConcentForm.controls["care"].setValidators(
    //       Validators.required
    //     );
    //     this.disclaimerConcentForm.controls["care"].updateValueAndValidity();
    //   }
    // }
  }

  onAcceptanceChange(event: any) {
    this.acceptanceChecked = event.target.checked;
  }

  onConsentAcceptanceChange(event: any) {
    this.concentAcceptance = event.target.checked;
  }
  consentForm() {
    if (this.type == "customer") {
      this.isDisclaimer = true;
      if (
        this.isDisclaimer &&
        this.formData.length > 0 &&
        this.disclaimerConcentForm.controls["prefix"].valid &&
        this.disclaimerConcentForm.controls["given"].valid &&
        this.disclaimerConcentForm.controls["family"].valid &&
        this.disclaimerConcentForm.controls["suffix"].valid &&
        this.disclaimerConcentForm.controls["birthDate"].valid &&
        this.disclaimerConcentForm.controls["phoneNo"].valid &&
        this.disclaimerConcentForm.controls["email"].valid &&
        this.disclaimerConcentForm.controls["file"].valid &&
        this.concentAcceptance == true
      ) {
        this.isShowConsent = true;
        this.selectedIndex = this.selectedIndex + 1;
      } else {
        this.isShowConsent = false;
      }
    } else {
      this.isDisclaimer = false;
      this.isShowConsent = true;
      this.selectedIndex = this.selectedIndex + 1;
    }
  }

  disclaimerForm() {
    this.isShowConsent = false;
    this.selectedIndex = this.selectedIndex - 1;
  }

  back() {
    if (this.selectedIndex == 0) {
      this.commonService.allTattooData = null;
      this.commonService.allPiercingData = null;
      this.commonService.allArtistData = null;
      this._location.back();
    }
    else {
      this.selectedIndex = 0;
    }
  }

  onPopOpen(url: any) {
    this.imageFullUrl = url;
    $("#fileUpload").modal("show");
  }
}
