import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AuthService } from "../../services/auth-guard/auth.service";
import { CommonService } from "src/app/services/common/common.service";
import { HttpService } from "src/app/services/http/http.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-forget-password-reset",
  templateUrl: "./forget-password-reset.component.html",
  styleUrls: ["./forget-password-reset.component.scss"],
})
export class ForgetPasswordResetComponent implements OnInit {
  userCode: string;
  public innerHeight: any;
  forgetResetForm: FormGroup;
  submitted: boolean = false;
  type: any;
  pattern: any = /^.*(?=.{8,15})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!-_]).\S*$/;

  constructor(
    private auth: AuthService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private http: HttpService,
    private router: Router,
    public translate: TranslateService
  ) {
    this.userCode = this.route.snapshot.paramMap.get("code");
    this.type = this.route.snapshot.paramMap.get("type");
  }

  ngOnInit(): void {
    this.innerHeight = window.innerHeight;
    this.forgetResetForm = this.fb.group(
      {
        password: [
          "",
          [
            Validators.required,
            Validators.pattern(this.pattern),
          ],
        ],
        cpassword: ["", Validators.required],
      },
      { validators: this.checkPasswords }
    );
  }
  sendEmail() {
    this.submitted = true;
    if (this.forgetResetForm.valid) {
      var param = $.extend(
        {},
        {
          password: this.forgetResetForm.value.password.trim(),
        }
      );

      this.auth.forgetPasswordReset(this.userCode, param);
      this.http.httpPostWithHeaderParam("resetPassword", this.userCode, param).subscribe((res: any) => {
        if (res.status == 200) {
          if (this.type == "customer") {
            this.commonService.presentsToast("success", "top-end", res.message);
            this.router.navigate(["/login"]);
          }

          if (this.type == "professional") {
            this.commonService.presentsToast("success", "top-end", res.message);
          }
        }
        else {
          this.commonService.presentsToast("error", "top-end", res.message);
        }
      })
    }
  }

  checkPasswords(group: FormGroup) {
    const password = group.get("password").value;
    const confirmPassword = group.get("cpassword").value;
    return password === confirmPassword ? null : { notSame: true };
  }
}
