import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  SearchCountryField,
  CountryISO,
} from "ngx-intl-tel-input";
import { CanActivate, Router, ActivatedRouteSnapshot, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../services/auth-guard/auth.service";
import { CommonService } from "src/app/services/common/common.service";
import { environment } from "src/environments/environment";
import { HttpService } from "src/app/services/http/http.service";
import { TranslateService } from "@ngx-translate/core";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  isEditMode = false;
  isDisclaimerFill = false;
  SearchCountryField = SearchCountryField;
  separateDialCode = false;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  deviceType: "WEB";
  deviceToken: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
  submitted: boolean = false;
  email: any;
  phone: any;
  countryCode: any;
  city: String = "Mumbai";
  state: String = "Maharashtra";
  country: String = "India";
  address: String = "Ward no. 9 , Andesri east , Mumbai , Maharastra";
  isPhoneVerify: boolean = false;
  isEmailVerify: boolean = false;
  profileComplete: FormGroup;
  public innerHeight: any;
  fileData: any = "";
  fileDataUrl: any = "";
  imgBaseUrl = environment.imageUrl;
  location: any = '';
  longitude = '';
  latitude = '';
  options = {
    types: ['(regions)'],
    componentRestrictions: { country: "us" }
  };

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private common: CommonService,
    private http: HttpService,
    public translate: TranslateService,
    private commonService: CommonService,
    private authSvc: AuthService
  ) {
    this.innerHeight = window.innerHeight;
    this.profileComplete = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")
        ],
      ],
      phone: ["", Validators.required],
      image: [""],
      firstName: ["", [Validators.required, Validators.pattern(/^[a-zA-Z ]{2,20}$/)]],
      lastName: ["", [Validators.required, Validators.pattern(/^[a-zA-Z ]{2,20}$/)]],
      location: ["", Validators.required],
    });

    this.authSvc.isEmailVerified.subscribe(res => {
      this.isEmailVerify = res;
    })
    this.authSvc.isPhoneVerified.subscribe(res => {
      this.isPhoneVerify = res;
    })
  }


  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        var x = position.coords.latitude;
        var y = position.coords.longitude;
        this.getFullLocation(x, y)
        console.log(position);
      });

    } else {
      alert(this.translate.instant("GEOCODE_ERROR"));
    }
  }

  getFullLocation(lat: any, lang: any) {
    var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lang + '&sensor=true' + '&key=AIzaSyBWIFKaRjrCO5mkqj59TwGoLoQGG7FEDeY';
    this.http.httpGetForGeoLocation(url).subscribe((res: any) => {
      if (res.status == "OK") {
        this.AddressChange(res.results[0], 'locate')
      }
      else if (res.status == 'REQUEST_DENIED') {
        this.common.presentsToast('error', 'top-end', this.translate.instant("PERMISSION_DENIED"))
      }
    })
  }

  ngOnInit() {
    // if (this.route.snapshot.queryParams.id) {
    //   this.isEditMode = true;
    //   this.getProfileDetails();
    // }
    this.getProfileDetails();
  }



  onFileChange(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      if (fileInput.target.files[0].type == "image/jpeg" || fileInput.target.files[0].type == "image/jpg" || fileInput.target.files[0].type == "image/png") {
        this.fileData = fileInput.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(this.fileData);
        reader.onload = (_event) => {
          this.fileDataUrl = reader.result;
        };
      }
      else {
        this.common.presentsToast(
          "error",
          "top-end",
          this.translate.instant("PROFILE_PIC_FORMAT")
        );
      }
    }
  }

  AddressChange(address: any, type: any) {
    address.address_components.forEach((res) => {
      if (
        res.types.includes('locality') ||
        res.types.includes('postal_town') ||
        res.types.includes('sublocality') ||
        res.types.includes('sublocality_level_1') ||
        res.types.includes('sublocality_level_2') ||
        res.types.includes('sublocality_level_3') ||
        res.types.includes('sublocality_level_4') ||
        res.types.includes('sublocality_level_5')
      ) {
        this.city = res.long_name;
      }
      if (
        res.types.includes('administrative_area_level_1') ||
        res.types.includes('administrative_area_level_2') ||
        res.types.includes('administrative_area_level_3') ||
        res.types.includes('administrative_area_level_4') ||
        res.types.includes('administrative_area_level_5')
      ) {
        this.state = res.long_name;
      }
      if (res.types[0] == 'country') {
        if (res.long_name !== 'United States') {
          this.country = '';
          this.city = '';
          this.state = '';
        }
        else {
          this.country = res.long_name;
        }
      }
    });
    if (this.country !== '') {
      this.address = address.formatted_address;
      if (type == 'change') {
        this.longitude = address.geometry.location.lng();
        this.latitude = address.geometry.location.lat();
      }
      if (type == 'locate') {
        this.longitude = address.geometry.location.lng;
        this.latitude = address.geometry.location.lat;
      }
      this.profileComplete.controls['location'].patchValue(this.address);
    }
    else {
      this.latitude = '';
      this.longitude = '';
      this.address = '';
      this.profileComplete.controls['location'].reset();
      this.common.presentsToast("error", "top-end", this.translate.instant("ONLY_FOR_UNITED_STATES"))
    }
  }

  onInputChange(event) {
    var locationName: any = '';
    locationName = this.profileComplete.value.location;
    if (locationName != this.address) {
      this.profileComplete.controls['location'].reset();
      this.city = '';
      this.state = '';
      this.country = '';
      this.address = '';
      this.longitude = '';
      this.latitude = '';
    }
  }

  signupCompleteProfile() {
    this.submitted = true;
    if (this.submitted && this.profileComplete.valid) {
      if (this.profileComplete.value.lastName.trim() !== '' && this.profileComplete.value.firstName.trim() == '') {
        this.common.presentsToast("error", "top-end", this.translate.instant("PLEASE_ENTER_FIRST_NAME"))
      }
      else if (this.profileComplete.value.firstName.trim() !== '' && this.profileComplete.value.lastName.trim() == '') {
        this.common.presentsToast("error", "top-end", this.translate.instant("PLEASE_ENTER_LAST_NAME"))
      }
      else if (this.profileComplete.value.firstName.trim() == '' && this.profileComplete.value.lastName.trim() == '') {
        this.common.presentsToast("error", "top-end", this.translate.instant("PLEASE_ENTER_FIRST_AND_LAST_NAME"))
      }
      else {
        var formData: any = new FormData();
        formData.append("firstName", this.profileComplete.value.firstName.trim());
        formData.append("lastName", this.profileComplete.value.lastName.trim());
        formData.append("address", this.profileComplete.value.location.trim());
        if (!(this.city == '' || this.city == undefined || this.city == null)) {
          formData.append("city", this.city);
        }
        if (!(this.state == '' || this.state == undefined || this.state == null)) {
          formData.append("state", this.state);
        }
        if (!(this.country == '' || this.country == undefined || this.country == null)) {
          formData.append("country", this.country);
        }
        if (!(this.longitude == '' || this.longitude == undefined || this.longitude == null)) {
          formData.append("longitude", this.longitude);
        }
        if (!(this.latitude == '' || this.latitude == undefined || this.latitude == null)) {
          formData.append("latitude", this.latitude);
        }
        if (this.fileData) {
          formData.append("profilePic", this.fileData);
        }

        var phoneCode: any;
        var diaCode = this.profileComplete.value.phone.dialCode;
        if (diaCode.includes('+')) {
          phoneCode = diaCode.slice(1);
        } else {
          phoneCode = diaCode;
        }
        var codeLength = this.profileComplete.value.phone.dialCode.length;
        formData.append('phone', this.profileComplete.value.phone.e164Number.slice(codeLength))
        formData.append('countryCode', phoneCode)
        formData.append('email', this.profileComplete.value.email.trim())
        this.http.httpPutWithHeaderFormData("updateProfile", formData).subscribe((res: any) => {
          if (res.status == 200) {
            this.getProfileDetails();
            this.common.presentsToast("success", "top-end", this.translate.instant("PROFILE_UPDATED_SUCCESSFULLY"))
            this.router.navigate(['/'])
          }
        })
      }
    }
  }

  getProfileDetails() {
    this.http.httpGetWithHeader("updateProfile", "").subscribe((res: any) => {
      if (res.status == 200) {
        this.isDisclaimerFill = res.result.isDisclaimerFill;
        console.log(this.isDisclaimerFill, "ASDE#$");

        this.profileComplete.patchValue({
          firstName: res.result.firstName,
          lastName: res.result.lastName,
          location: res.result.address,
          phone: res.result.countryCode
            ? "+" + res.result.countryCode + res.result.phone
            : res.result.phone,
          email: res.result.email
        })
        this.city = res.result.city;
        this.state = res.result.state;
        this.country = res.result.country;
        this.address = res.result.address;
        this.longitude = res.result.longitude;
        this.latitude = res.result.latitude;
        this.fileDataUrl = res.result.image ? this.imgBaseUrl + res.result.image : '';
        this.phone = res.result.phone;
        this.countryCode = res.result.countryCode;
        this.email = res.result.email;
        this.isEmailVerify = res.result && res.result.isEmailVerify && res.result.isEmailVerify == true ? true : false;
        this.isPhoneVerify = res.result && res.result.isPhoneVerify && res.result.isPhoneVerify == true ? true : false;
        var dataParam = {
          firstName: res.result.firstName,
          lastName: res.result.lastName,
          _id: res.result._id,
          email: res.result.email,
          phone: res.result.phone,
          countryCode: res.result.countryCode,
          address: res.result.address,
          city: res.result.city,
          state: res.result.state,
          country: res.result.country,
          image: res.result.image,
          latitude: res.result.latitude,
          longitude: res.result.longitude,
        };
        localStorage.setItem("userProfileData", JSON.stringify(dataParam));
        this.http.isUserDataupdated.next(true);
      }
    })
  }

  checkPhone() {
    let countryPhone = this.countryCode ? '+' + this.countryCode + this.phone : this.phone;
    if (countryPhone == this.profileComplete.value.phone) {
      this.isPhoneVerify = true;
    }
    else {
      this.isPhoneVerify = false;
    }
  }

  checkEmail() {
    if (this.email == this.profileComplete.value.email) {
      this.isEmailVerify = true;
    }
    else {
      this.isEmailVerify = false;
    }
  }

  sendOtpForVeify(type: any) {
    this.submitted = true;
    if (type == 'phone') {
      var phoneCode: any;
      var diaCode = this.profileComplete.value.phone.dialCode;
      if (diaCode.includes('+')) {
        phoneCode = diaCode.slice(1);
      } else {
        phoneCode = diaCode;
      }
      var codeLength = this.profileComplete.value.phone.dialCode.length;
      var param = $.extend(
        {},
        {
          phone: this.profileComplete.value.phone.e164Number.slice(codeLength),
          countryCode: phoneCode,
        }
      );
      this.auth.signupSendOtpForProfile(param);
      this.commonService.modalData = {
        phone: this.profileComplete.value.phone.e164Number.slice(codeLength),
        countryCode: phoneCode, isFor: 'phone'
      };
    }
    if (type == 'email') {
      var data = $.extend(
        {},
        {
          email: this.profileComplete.value.email.trim()
        })
      this.auth.signupSendOtpForProfile(data);
      this.commonService.modalData = { email: this.profileComplete.value.email.trim(), isFor: 'email' };
    }
  }

  avoidSpace(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 32) return false;
  }
  avoidSpaceandalphabaticNo(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 32 || !(charCode >= 48 && charCode <= 57)) { return false };
  }
}
