import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/common/common.service";
import { HttpService } from "src/app/services/http/http.service";
import { environment } from "src/environments/environment";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AuthService } from "src/app/services/auth-guard/auth.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-professional",
  templateUrl: "./professional.component.html",
  styleUrls: ["./professional.component.scss"],
})
export class ProfessionalComponent implements OnInit {
  constructor(
    public commonbService: CommonService,
    public auth: AuthService,
    private http: HttpService,
    private router: Router,
    private fb: FormBuilder,
    public translate: TranslateService
  ) { }
  professionalSort: FormGroup;
  public professionals: any = [];
  public innerHeight: any;
  public submitted: Boolean = false;
  categoryId: any;
  name: string = "asc";
  allStudios: any[] = [];
  studioId: any[] = [];
  selectedStudioValue: any;
  categories: any[] = [];
  selected: any;
  imageUrl: string = environment.imageUrl;
  userData: any = localStorage.userProfileData
    ? JSON.parse(localStorage.userProfileData)
    : {};
  showHideProfessional: boolean = true;
  showHideCategory: boolean = true;
  showHideStudio: boolean = true;

  slides = [
    { img: "http://placehold.it/350x150/000000" },
    { img: "http://placehold.it/350x150/111111" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/666666" },
  ];

  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    variableWidth: true,
    infinite: false,
    autoplay: false,
    margin: 20,
    autoplaySpeed: 1000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  search: FormControl = new FormControl('', null);
  slickInit(event: any) { }
  searchData()
  {
    this.professionals = [];
    if(!(this.search.value.trim() == '' || this.search.value == undefined || this.search.value == null))
    {
      var query = 'key=' + this.search.value.trim();
      this.http.httpGetWithoutHeader('search',query).subscribe((res:any)=>{
        if(res.status == 200)
        {
          this.allStudios = res.result.studios;
          this.categories = res.result.categories;
          if(res.result.professionals.length > 0)
          {
            res.result.professionals.forEach((element:any) => {
              if(element.artistType == 'TATTOO')
              this.professionals.push(element)
            });
          }
        }
      })
    }

    else if(!(this.search.value == undefined || this.search.value == null) && this.search.value.trim() == ''){
      this.getAllStudios();
      this.getAllCategories();
    }
  }

  ngOnInit() {
    this.getAllStudios();
    this.getAllCategories();

    hideChat(0);

    $("#prime").click(function () {
      toggleFab();
    });
    //Toggle chat and links
    function toggleFab() {
      $(".prime").toggleClass("zmdi-comment-outline");
      $(".prime").toggleClass("zmdi-close");
      $(".prime").toggleClass("is-active");
      $(".prime").toggleClass("is-visible");
      $("#prime").toggleClass("is-float");
      $(".chat").toggleClass("is-visible");
      $(".fab").toggleClass("is-visible");
    }

    $("#chat_first_screen").click(function (e) {
      hideChat(1);
    });

    $("#chat_second_screen").click(function (e) {
      hideChat(2);
    });

    $("#chat_third_screen").click(function (e) {
      hideChat(3);
    });

    $("#chat_fourth_screen").click(function (e) {
      hideChat(4);
    });

    $("#chat_fullscreen_loader").click(function (e) {
      $(".fullscreen").toggleClass("zmdi-window-maximize");
      $(".fullscreen").toggleClass("zmdi-window-restore");
      $(".chat").toggleClass("chat_fullscreen");
      $(".fab").toggleClass("is-hide");
      $(".header_img").toggleClass("change_img");
      $(".img_container").toggleClass("change_img");
      $(".chat_header").toggleClass("chat_header2");
      $(".fab_field").toggleClass("fab_field2");
      $(".chat_converse").toggleClass("chat_converse2");
    });

    function hideChat(hide) {
      switch (hide) {
        case 0:
          $("#chat_converse").css("display", "bloack");
      }
    }
  }

  getAllCategories() {
    this.http.httpGetWithoutHeader("getCategory", "").subscribe((res: any) => {
      if (res.status == 200) {
        this.categories = res.result;
      }
    });
  }

  onCategorySelection(id: any) {
    this.selectedStudioValue = "";
    this.categoryId = id;
    var query = "category=" + id + "&" + "artistType=TATTOO";
    this.http
      .httpPostWithQuery("professionals", query, "")
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.professionals = res.result;
        }
      });
  }
  onStudioSelections(event: any) {
    this.categoryId = "";
    this.selectedStudioValue = event;
    this.selected = event;
    this.studioId = [];
    this.studioId.push(this.selectedStudioValue);
    var param = {
      studios: this.studioId,
    };
    this.getAllProfessional(param);
  }
  getAllProfessional(param: any) {
    var query = "artistType=TATTOO";
    this.http
      .httpPostWithQuery("professionals", query, param)
      .subscribe((res: any) => {
        if (res.status == 200) {
          console.log(res);
          this.professionals = res.result;
        }
      });
  }

  getAllStudios() {
    this.http
      .httpGetWithoutHeader("allprofessionalStudio", "")
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.allStudios = res.result;
          this.onStudioSelections(res.result[0]._id);
        }
      });
  }
  showCrowsel(type: any) {
    if (type == 'professional') {
      this.showHideProfessional = true;
    }
    if (type == 'category') {
      this.showHideCategory = true;
    }
    if (type == 'studio') {
      this.showHideStudio = true;
    }
  }
  hideCrowsel(type: any) {
    if (type == 'professional') {
      this.showHideProfessional = false;
    }
    if (type == 'category') {
      this.showHideCategory = false;
    }
    if (type == 'studio') {
      this.showHideStudio = false;
    }
  }
  resetInput()
  {
    this.search.reset();
    this.getAllStudios();
    this.getAllCategories();
  }
}
