import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { apiList } from "./apilist";
import { Observable, BehaviorSubject } from "rxjs";
import { CommonService } from "../common/common.service";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  baseUrl: string = environment.baseUrl;
  socket: any;
  appointmentsData: any;

  public isUserDataupdated: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isLoogedOutClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
      false
    );
  constructor(
    private http: HttpClient,
    private apiList: apiList,
    private common: CommonService
  ) { }

  httpPost(url: string, params): Observable<Object> {
    return this.http.post(this.baseUrl + this.apiList[url], params);
  }

  httpPostPromise(url: string, params) {
    return this.http.post(this.baseUrl + this.apiList[url], params).toPromise().then();
  }

  httpPostWithQuery(url: string, query, params): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url] + "?" + query,
      params
    );
  }

  httpGetWithHeaderforForm(url: string): Observable<Object> {
    return this.http.get(url);
  }

  httpGetWithHeaderParam(url: string, param: any): Observable<Object> {
    return this.http.get(
      this.baseUrl + this.apiList[url] + "/" + param,
      this.header()
    );
  }

  httpGetWithHeaderOnly(url: string): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url], this.header());
  }

  httppost(url: any, param: any): Observable<Object> {
    return this.http.post(url, param);
  }
  httppostPromise(url: any, param: any) {
    return this.http.post(url, param).toPromise().then();
  }

  httpGetWithHeaderParamandQuery(
    url: string,
    param: any,
    query: any
  ): Observable<Object> {
    return this.http.get(
      this.baseUrl + this.apiList[url] + "/" + param + "?" + query,
      this.header()
    );
  }

  httpGetWithParam(url: string, param: any): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url] + "/" + param);
  }

  httpGetWithParamWithoutObservable(url: string, param: any) {
    return this.http
      .get(this.baseUrl + this.apiList[url] + "/" + param)
      .toPromise()
      .then();
  }

  httpPutWithHeader(url: string, params: any): Observable<Object> {
    return this.http.put(
      this.baseUrl + this.apiList[url],
      params,
      this.header()
    );
  }

  httpPutWithHeaderFormData(url: string, params: any): Observable<Object> {
    return this.http.put(
      this.baseUrl + this.apiList[url],
      params,
      this.headerFormData()
    );
  }

  httpPostWithHeaderFormData(url: string, params: any): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      params,
      this.headerFormData()
    );
  }

  httpPostWithHeaderFormDataPromise(url: string, params: any) {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      params,
      this.headerFormData()
    ).toPromise().then();
  }

  httpPostWithHeader(url: string, params: any): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      params,
      this.header()
    );
  }

  httpPostWithoutHeader(url: string, params: any): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      params
    );
  }

  httpPostWithHeaderWithoutParam(url: string): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url],
      this.header()
    );
  }
  httpPostWithHeaderParam(
    url: string,
    param: any,
    params: any
  ): Observable<Object> {
    return this.http.post(
      this.baseUrl + this.apiList[url] + "/" + param,
      params,
      this.header()
    );
  }

  httpGetWithHeader(url: string, params): Observable<Object> {
    var q = params ? "?" : "";
    return this.http.get(
      this.baseUrl + this.apiList[url] + q + params,
      this.header()
    );
  }
  httpGetForGeoLocation(url: string): Observable<Object> {
    return this.http.get(
      url
    );
  }

  httpGetWithoutHeader(url: string, params): Observable<Object> {
    var q = params ? "?" : "";
    return this.http.get(
      this.baseUrl + this.apiList[url] + q + params
    );
  }

  httpostWithQueryHeader(url: string, params): Observable<Object> {
    var q = params ? "?" : "";
    return this.http.post(
      this.baseUrl + this.apiList[url] + q + params,
      this.header()
    );
  }

  httpostWithoutQueryHeader(url: string, params): Observable<Object> {
    var q = params ? "?" : "";
    return this.http.post(
      this.baseUrl + this.apiList[url] + q + params, {}
    );
  }

  httpGetWithQueryandHeader(url: any, params: any): Observable<Object> {
    var q = params ? "?" : "";
    return this.http.get(
      this.baseUrl + this.apiList[url] + q + params,
      this.header()
    );
  }

  httpDeleteWithid(url: any, id: any): Observable<Object> {
    return this.http.delete(
      this.baseUrl + this.apiList[url] + "?" + id,
      this.header()
    );
  }

  httpDeleteWithidwithouHeader(url: any, id: any): Observable<Object> {
    return this.http.delete(
      this.baseUrl + this.apiList[url] + "?" + id
    );
  }

  httpGet(url: string): Observable<Object> {
    return this.http.get(this.baseUrl + this.apiList[url]);
  }

  fileUpload(fileData: any) {
    var formData = new FormData();
    formData.append("image", fileData, fileData.name);
    const headers = new HttpHeaders({
      mimeType: "multipart/form-data",
      authorization: localStorage.accessToken,
    });
    const headersObj = { headers };
    return this.http.post(
      this.baseUrl + this.apiList["uploadFile"],
      formData,
      headersObj
    );
  }

  header() {
    if (
      localStorage.accessToken != undefined ||
      localStorage.accessToken != null
    ) {
      const headers = new HttpHeaders({
        "cache-control": "no-cache",
        "content-type": "application/json",
        authorization: localStorage.accessToken,
      });
      const option = {
        headers,
      };
      return option;
    }
  }

  headerFormData() {
    if (
      localStorage.accessToken != undefined ||
      localStorage.accessToken != null
    ) {
      const headers = new HttpHeaders({
        mimeType: "multipart/form-data",
        authorization: localStorage.accessToken,
      });
      const option = {
        headers,
      };
      return option;
    }
  }
}
