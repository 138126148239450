import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, BehaviorSubject, throwError, of } from "rxjs";
import {
  catchError,
  filter,
  take,
  switchMap,
  finalize,
  retry,
  tap,
} from "rxjs/operators";
import { CommonService } from "../common/common.service";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../auth-guard/auth.service";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";
  private token: string;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private commonbService: CommonService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    public translate: TranslateService,
    private auth:AuthService ) {
    this.ngxService.start();
    if (
      localStorage.accessToken != undefined ||
      localStorage.accessToken != null
    ) {
      this.token = localStorage.accessToken;
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      //retry(2),
      catchError((error: HttpErrorResponse) => {
        this.ngxService.stop();
        if (error.status == 401) {
          this.commonbService.presentsToast(
            "warning",
            "top-end",
            this.translate.instant("YOU_HAVE_BEEN_LOGGED_OUT")
          );
          var deviceToken;
          if (
            !(
              localStorage.fcmDeviceToken == null ||
              localStorage.fcmDeviceToken == undefined ||
              localStorage.fcmDeviceToken == ""
            )
          ) {
            deviceToken = localStorage.fcmDeviceToken;
          }
          localStorage.clear();
          localStorage.setItem("fcmDeviceToken",deviceToken)
          this.router.navigate(['/signup-landing']);
        }
        return throwError(error);
      }),
      finalize(() => {
        this.ngxService.stop();
      }),
      tap(
        (event) => {
          var eventRes = JSON.parse(JSON.stringify(event));
          if (eventRes.body) {
            if (eventRes.body.statusCode === 400) {
              if (eventRes.body.error) {
                this.commonbService.presentsToast(
                  "error",
                  "top-end",
                  eventRes.body.error
                );
              } else if (eventRes.body.message) {
                this.commonbService.presentsToast(
                  "error",
                  "top-end",
                  eventRes.body.message
                );
              }
            }
          }
        },
        (error) => {
          switch (error.status) {
            case 401:
              /***  Auto LogOut if Api response 401 ** */
              this.commonbService.presentsToast(
                "warning",
                "top-end",
                this.translate.instant("YOU_HAVE_BEEN_LOGGED_OUT")
              );
              var deviceToken;
          if (
            !(
              localStorage.fcmDeviceToken == null ||
              localStorage.fcmDeviceToken == undefined ||
              localStorage.fcmDeviceToken == ""
            )
          ) {
            deviceToken = localStorage.fcmDeviceToken;
          }
          localStorage.clear();
          localStorage.setItem("fcmDeviceToken",deviceToken)
              this.router.navigate(['/signup-landing']);
              break;
            case 500:
              /*** If api does not respond  ** */
              this.commonbService.presentsToast(
                "warning",
                "top-end",
                this.translate.instant("API_NOT_WORKING")
              );
              break;

            default:
              if (error.error) {
                if(error.error.message == "jwt malformed")
                {
                  localStorage.clear();
                  this.router.navigate(['/signup-landing'])
                }
                else
                {
                this.commonbService.presentsToast(
                  "error",
                  "top-end",
                  error.error.message
                );
                }
              } else {
                this.commonbService.presentsToast(
                  "error",
                  "top-end",
                  error.message
                );
              }
          }
        }
      )
    );
  }
}
