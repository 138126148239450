import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "src/app/services/common/common.service";
import { AuthService } from "../../services/auth-guard/auth.service";

@Component({
  selector: "app-forgot",
  templateUrl: "./forgot.component.html",
  styleUrls: ["./forgot.component.scss"],
})
export class ForgotComponent implements OnInit {
  public innerHeight: any;
  forgetForm: FormGroup;
  submitted: boolean = false;
  constructor(
    private auth: AuthService,
    private commonService: CommonService,
    private fb: FormBuilder,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.innerHeight = window.innerHeight;
    this.forgetForm = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
        ],
      ],
    });
  }
  sendEmail() {
    this.submitted = true;
    if (this.forgetForm.valid) {
      var param = $.extend(
        {},
        {
          email: this.forgetForm.value.email.trim(),
        }
      );
      console.log(this.forgetForm.value.email);
      this.commonService.modalEmailData = this.forgetForm.value.email;
      this.auth.forgetPassword(param);
    }
  }
}
