import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AuthService } from "../../services/auth-guard/auth.service";
import { CommonService } from "src/app/services/common/common.service";
import { HttpService } from "src/app/services/http/http.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  submitted: boolean = false;
  pattern: any = /^.*(?=.{8,15})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!-_]).\S*$/;

  constructor(
    private auth: AuthService,
    private commonService: CommonService,
    private router: Router,
    private http: HttpService,
    private fb: FormBuilder,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group(
      {
        oldPassword: ["", [Validators.required]],
        newPassword: [
          "",
          [
            Validators.required,
            Validators.pattern(this.pattern),
          ],
        ],
        cpassword: ["", Validators.required],
      },
      { validators: this.checkPasswords }
    );
  }
  resetPassword() {
    this.submitted = true;
    if (this.resetPasswordForm.valid) {
      var params = $.extend(
        {},
        {
          oldPassword: this.resetPasswordForm.value.oldPassword.trim(),
          newPassword: this.resetPasswordForm.value.newPassword.trim(),
        }
      );

      this.http
        .httpPostWithHeader("changePassword", params)
        .subscribe((res: any) => {
          if (res.status == 200) {
            this.commonService.presentsToast("success", "top-end", res.message);
            this.resetPasswordForm.reset();
            this.submitted = false;
            this.router.navigate(["/"]);
          } else {
            this.commonService.presentsToast("error", "top-end", res.message);
          }
        });
    }
  }

  checkPasswords(group: FormGroup) {
    const newPassword = group.get("newPassword").value;
    const confirmPassword = group.get("cpassword").value;
    return newPassword === confirmPassword ? null : { notSame: true };
  }
}
