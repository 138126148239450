import { Injectable } from "@angular/core";
import * as socketIo from "socket.io-client";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  socket: any;
  recievedData: any;
  public isUserData: BehaviorSubject<object> = new BehaviorSubject<object>({});
  public isUserDatafetched: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isSocketConnectData: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  constructor() { }

  initSocket(event: any, data: any) {
    var authToken = !(localStorage.accessToken == '' || localStorage.accessToken == undefined ||  localStorage.accessToken == null) ? localStorage.accessToken : "";
    if (authToken != "") {
      this.socket = socketIo(environment.socketUrl + "/support", {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 3000,
        reconnectionAttempts: Infinity,
        query: {
          token: authToken,
        },
      }); 
      this.socket.on("connect", (res: any) => {
        console.log("debug connect", res);
       });
      this.socket.on("disconnect", (message: any) => {
        console.log("debug disconnect", message);
      });
    }
  }

  emitAction(action: any, payload: any): void {
    this.socket.emit(action, payload);
    console.log(payload);
  }

  public onEvent(event: any): Observable<any> {
    return new Observable<any>((observer) => {
      this.socket.on(event, (data) => observer.next(data));
    });
  }
}
