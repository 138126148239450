import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-favret',
  templateUrl: './favret.component.html',
  styleUrls: ['./favret.component.scss']
})
export class FavretComponent implements OnInit {
  allfavourite: any[] = [];
  imagebaseurl: any = environment.imageUrl;
  constructor(private http: HttpService, public translate: TranslateService) { }

  ngOnInit() {
    this.getAllFavourite()
  }

  getAllFavourite() {
    this.http.httpGetWithHeaderOnly("getFavorite").subscribe((res: any) => {
      if (res.status == 200) {
        this.allfavourite = res.result;
      }
    })
  }

  removeFavourite(id: any) {
    var query = 'id=' + id;
    this.http.httpDeleteWithid("getFavorite", query).subscribe((res: any) => {
      if (res.status == 200) {
        this.getAllFavourite();
      }
    })
  }

  download(path: any) {
    window.open(path, "_blank")
  }

  onScroll2() {
  }

  onScrollUp2() { }
}
