import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  SearchCountryField,
  CountryISO,
} from "ngx-intl-tel-input";
import { CommonService } from "src/app/services/common/common.service";
import { AuthService } from "../../services/auth-guard/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login-phone.component.html",
  styleUrls: ["./login-phone.component.scss"],
})
export class LoginPhoneComponent implements OnInit {
  separateDialCode = false;
  public innerHeight: any;
  loginForm: FormGroup;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  submitted: boolean = false;
  deviceToken: any = "";
  constructor(private CommonSvc:CommonService, private auth: AuthService, private fb: FormBuilder, public translate: TranslateService) { }

  ngOnInit(): void {
    this.innerHeight = window.innerHeight;
    this.loginForm = this.fb.group({
      phone: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  login() {
    if (
      !(
        localStorage.fcmDeviceToken == null ||
        localStorage.fcmDeviceToken == undefined ||
        localStorage.fcmDeviceToken == ""
      )
    ) {
      this.deviceToken = localStorage.fcmDeviceToken;
      console.log(this.deviceToken);
    }
    this.submitted = true;
    var phoneCode: any;
    var diaCode = this.loginForm.value.phone.dialCode;
    if (diaCode.includes('+')) {
      phoneCode = diaCode.slice(1);
    } else {
      phoneCode = diaCode;
    }
    var codeLength = this.loginForm.value.phone.dialCode.length;
    if (this.loginForm.valid) {
      var param = $.extend(
        {},
        {
          phone: this.loginForm.value.phone.e164Number.slice(codeLength),
          countryCode: phoneCode,
          password: this.loginForm.value.password,
          deviceType: "WEB",
          deviceToken: this.deviceToken,
        }
      );
      this.auth.logInPhone(param);
    }
  }
}
