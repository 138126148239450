import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-completed',
  templateUrl: './payment-completed.component.html',
  styleUrls: ['./payment-completed.component.scss']
})
export class PaymentCompletedComponent implements OnInit {
  professionaldetails:any;
  bookingDetails:any;
  imageBaseUrl:any = environment.imageUrl;
  isLinear = true;
  constructor() { }

  ngOnInit() {
    if(!(localStorage.professionalDetails == '' || localStorage.professionalDetails == undefined || localStorage.professionalDetails == null))
    {
      this.professionaldetails = JSON.parse(localStorage.professionalDetails)
      console.log(this.professionaldetails)
    }
    if(!(localStorage.bookingData == '' || localStorage.bookingData == undefined || localStorage.bookingData == null))
    {
      this.bookingDetails = JSON.parse(localStorage.bookingData)
      console.log(this.bookingDetails)
    }
  }

  changeIntoGMT(time:any)
  {
    var gmtTime = time.toString();
    return gmtTime;
  }

}
