import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bookingdetail',
  templateUrl: './bookingdetail.component.html',
  styleUrls: ['./bookingdetail.component.scss']
})
export class BookingdetailComponent implements OnInit {
  bookingId:any;
  bookingDetails:any;
  imageBaseUrl:any = environment.imageUrl;
  userData = localStorage.userProfileData
  ? JSON.parse(localStorage.userProfileData)
  : "";

  constructor(private http:HttpService, private route: ActivatedRoute, private router:Router,public translate: TranslateService) {
    this.bookingId = this.route.snapshot.paramMap.get("id")
  }

  ngOnInit() {
    // var bookData = JSON.parse(localStorage.appointmentData)
    // this.bookingDetails = bookData;
    // console.log(this.bookingDetails)
    this.getBookingDetails()
  }

  getBookingDetails()
  {
    this.http.httpGetWithHeaderParam("getBookingById",this.bookingId).subscribe((res:any)=>{
      if(res.status == 200)
      {
        this.bookingDetails = res.result;
        this.bookingId = res.result._id
        console.log(this.bookingId, "BOOKING-ID");
      }
    })
  }

  changeIntoGMT(time:any)
  {
    var gmtTime = time.toString();
    return gmtTime;
  }

  navigateToDisclaimer()
  {
    this.router.navigate([
      '/disclaimer-concent/' +
      this.userData._id +
      "/" +
      'customer' +
      "/" +
      'web',
    ]);
  }
}
