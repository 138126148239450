import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  SearchCountryField,
  CountryISO,
} from "ngx-intl-tel-input";
import { CanActivate, Router, ActivatedRouteSnapshot } from "@angular/router";
import { AuthService } from "../../services/auth-guard/auth.service";
import { CommonService } from "src/app/services/common/common.service";
import { environment } from "src/environments/environment";
import { HttpService } from "src/app/services/http/http.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-profile-setup",
  templateUrl: "./profile-setup.component.html",
  styleUrls: ["./profile-setup.component.scss"],
})
export class ProfileSetupComponent implements OnInit {
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.India, CountryISO.Canada];
  deviceType: "WEB";
  deviceToken: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX";
  submitted: boolean = false;
  email: String =
    localStorage.userProfile && JSON.parse(localStorage.userProfile).email;
  phone: any =
    localStorage.userProfile && JSON.parse(localStorage.userProfile).phone;
  countryCode: any =
    localStorage.userProfile &&
    JSON.parse(localStorage.userProfile).countryCode;
  city: String = "Mumbai";
  state: String = "Maharashtra";
  country: String = "India";
  address: String = "Ward no. 9 , Andesri east , Mumbai , Maharastra";
  isPhoneVerify: Boolean = false;
  profileComplete: FormGroup;
  public innerHeight: any;
  fileData: any = "";
  fileDataUrl: any = "";
  imgBaseUrl = environment.imageUrl;
  location: any = '';
  longitude = '';
  latitude = '';
  options = {
    types: ['(regions)'],
    componentRestrictions: { country: "us" }
  };
  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private common: CommonService,
    private http: HttpService,
    public translate: TranslateService
  ) {
    this.innerHeight = window.innerHeight;
    this.profileComplete = this.fb.group({
      image: [""],
      firstName: ["", [Validators.required, Validators.pattern(/^[a-zA-Z ]{2,20}$/)]],
      lastName: ["", [Validators.required, Validators.pattern(/^[a-zA-Z ]{2,20}$/)]],
      location: ["", Validators.required]
    });
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        var x = position.coords.latitude;
        var y = position.coords.longitude;
        this.getFullLocation(x, y)
        console.log(position);
      });

    } else {
      alert(this.translate.instant("GEOCODE_ERROR"));
    }
  }

  getFullLocation(lat: any, lang: any) {
    var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lang + '&sensor=true' + '&key=AIzaSyBWIFKaRjrCO5mkqj59TwGoLoQGG7FEDeY';
    this.http.httpGetForGeoLocation(url).subscribe((res: any) => {
      if (res.status == "OK") {
        this.AddressChange(res.results[0], 'locate')
      }
      else if (res.status == 'REQUEST_DENIED') {
        this.common.presentsToast('error', 'top-end', this.translate.instant('PERMISSION_DENIED'))
      }
    })
  }

  ngOnInit() {
  }



  onFileChange(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      if (fileInput.target.files[0].type == "image/jpeg" || fileInput.target.files[0].type == "image/jpg" || fileInput.target.files[0].type == "image/png") {
        this.fileData = fileInput.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(this.fileData);
        reader.onload = (_event) => {
          this.fileDataUrl = reader.result;
        };
      }
      else {
        this.common.presentsToast(
          "error",
          "top-end",
          this.translate.instant("PROFILE_PIC_FORMAT")
        );
      }
    }
  }

  AddressChange(address: any, type: any) {
    address.address_components.forEach((res) => {
      if (
        res.types.includes('locality') ||
        res.types.includes('postal_town') ||
        res.types.includes('sublocality') ||
        res.types.includes('sublocality_level_1') ||
        res.types.includes('sublocality_level_2') ||
        res.types.includes('sublocality_level_3') ||
        res.types.includes('sublocality_level_4') ||
        res.types.includes('sublocality_level_5')
      ) {
        this.city = res.long_name;
      }
      if (
        res.types.includes('administrative_area_level_1') ||
        res.types.includes('administrative_area_level_2') ||
        res.types.includes('administrative_area_level_3') ||
        res.types.includes('administrative_area_level_4') ||
        res.types.includes('administrative_area_level_5')
      ) {
        this.state = res.long_name;
      }
      if (res.types[0] == 'country') {
        if (res.long_name !== 'United States') {
          this.country = '';
          this.city = '';
          this.state = '';
        }
        else {
          this.country = res.long_name;
        }
      }
    });
    if (this.country !== '') {
      this.address = address.formatted_address;
      if (type == 'change') {
        this.longitude = address.geometry.location.lng();
        this.latitude = address.geometry.location.lat();
      }
      if (type == 'locate') {
        this.longitude = address.geometry.location.lng;
        this.latitude = address.geometry.location.lat;
      }
      this.profileComplete.controls['location'].patchValue(this.address);
    }
    else {
      this.latitude = '';
      this.longitude = '';
      this.address = '';
      this.profileComplete.controls['location'].reset();
      this.common.presentsToast("error", "top-end", this.translate.instant("ONLY_FOR_UNITED_STATES"))
    }
  }

  onInputChange(event) {
    var locationName: any = '';
    locationName = this.profileComplete.value.location;
    if (locationName != this.address) {
      this.profileComplete.controls['location'].reset();
      this.city = '';
      this.state = '';
      this.country = '';
      this.address = '';
      this.longitude = '';
      this.latitude = '';
    }
  }

  signupCompleteProfile() {
    this.submitted = true;
    if (this.submitted && this.profileComplete.valid) {
      if (this.profileComplete.value.lastName.trim() !== '' && this.profileComplete.value.firstName.trim() == '') {
        this.common.presentsToast("error", "top-end", this.translate.instant("PLEASE_ENTER_FIRST_NAME"))
      }
      else if (this.profileComplete.value.firstName.trim() !== '' && this.profileComplete.value.lastName.trim() == '') {
        this.common.presentsToast("error", "top-end", this.translate.instant("PLEASE_ENTER_LAST_NAME"))
      }
      else if (this.profileComplete.value.firstName.trim() == '' && this.profileComplete.value.lastName.trim() == '') {
        this.common.presentsToast("error", "top-end", this.translate.instant("PLEASE_ENTER_FIRST_AND_LAST_NAME"))
      }
      else {
        var formData: any = new FormData();
        formData.append("firstName", this.profileComplete.value.firstName.trim());
        formData.append("lastName", this.profileComplete.value.lastName.trim());
        formData.append("address", this.profileComplete.value.location.trim());
        formData.append("city", this.city);
        formData.append("state", this.state);
        formData.append("country", this.country);
        formData.append("longitude", this.longitude);
        formData.append("latitude", this.latitude);
        if (this.fileData) {
          formData.append("profilePic", this.fileData);
        }
        this.auth.signupCompleteProfile(formData);
      }
    }
  }

  avoidSpace(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 32) return false;
  }
  avoidSpaceandalphabaticNo(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 32 || !(charCode >= 48 && charCode <= 57)) { return false };
  }
}
