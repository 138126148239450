import { AfterViewInit, Component, OnInit } from "@angular/core";
import { TweenLite } from "gsap";
declare var $: any;
declare let ScrollMagic: any;
declare let ParticleSlider: any;
declare let Blazy: any;

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
})
export class SliderComponent implements OnInit, AfterViewInit {
  constructor() {}

  ngOnInit() {
    var widsc = $(this).scrollTop();
    if (widsc >= 1) {
      $("header").addClass("fixed-header");
    }
    $(window).scroll(function () {
      var widsc1 = $(this).scrollTop();
      if (widsc1 >= 1) {
        $("header").addClass("fixed-header");
      } else {
        $("header").removeClass("fixed-header");
      }
    });
    $(function () {
      var _top = $(window).scrollTop();
      var _direction;
      $(window).scroll(function () {
        var _cur_top = $(window).scrollTop();
        if (_top < _cur_top) {
          $(".portskip.dwn").show();
          $(".portskip.up").hide();
        } else {
          $(".portskip.up").show();
          $(".portskip.dwn").hide();
        }
        _top = _cur_top;
      });
    });
    $(".portskip").click(function (event) {
      event.preventDefault();
      $("body,html").animate(
        {
          scrollTop: $(this.hash).offset().top,
        },
        1000
      );
    });

    $(".statutory-nav").click(function () {
      $(this).hide();
      $(".statutory-para").slideDown();
    });
    $(".typeinp").keyup(function () {
      var $this = $(this);
      if ($this.val().length >= 1) {
        $this.parent().removeClass("error");
        $this.parent().addClass("active");
      } else {
        $this.parent().removeClass("active");
        $this.parent().addClass("error");
      }
    });

    $(function () {
      var winht = $(window).height(),
        winhwt = $(window).width();
      var frstrd = $(".appi-our-ranking .container").offset().left;
      var controller = new ScrollMagic.Controller({
        container: window,
      });
      var logoflag = true,
        ps = null;

      function applogo() {
        if (logoflag) {
          ps = null;
          ps = new ParticleSlider({
            width: 1000,
            height: 1000,
            color: "#abcdef",
            showArrowControls: true,
          });
        }
      }
      var sliderapiTop = $(".appi-our-ranking").offset().top;
      $(".appi-portfolio-sec").css("height", winht * 7);
      var aglleft = $(".agileline").offset().left;
      var orgaglleft = aglleft - 65;
      var orgaglleft22 = $(".agile-method-sec ul li.l2").outerWidth();
      var orgaglleft123 = orgaglleft22 + 30;
      var orgaglleft125 = orgaglleft123 * 2;
      var autosideranimationsa = true;
      var iii = 0;
      var flagg = 1;

      function buidslideanimation() {
        if (autosideranimationsa) {
          if (flagg == 1) {
            setTimeout(function () {
              $(".agile-method-sec ul li.l1").addClass("active");
            }, 200);
            TweenLite.to(".agile-method-sec ul", 1.2, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
                transform: "translateX( " + orgaglleft + "px)",
              },
            });
            TweenLite.to(".agile-method-sec ul li.l1", 1.2, {
              ease: Power4.easeInOut,
              css: {
                width: "490px",
              },
            });
            TweenLite.to(".agile-method-sec ul li.l1 .define-cont", 1.6, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
              },
            });
            TweenLite.to(".agile-method-sec ul li .define-cont p .l1", 1.8, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".agile-method-sec ul li .define-cont p .l2", 2, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".agile-method-sec ul li .define-cont p .l3", 2.2, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".agileline", 1, {
              ease: Power4.easeInOut,
              css: {
                height: "70px",
              },
            });
            TweenLite.to(".agile-method-sec ul li.l2", 1.2, {
              ease: Power4.easeInOut,
              css: {
                width: "0",
              },
            });
            TweenLite.to(".agile-method-sec ul li.l2 .build-cont", 1.6, {
              ease: Power4.easeInOut,
              css: {
                opacity: 0,
              },
            });
            TweenLite.to(".agile-method-sec ul li .build-cont p .l1", 1.2, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            TweenLite.to(".agile-method-sec ul li .build-cont p .l2", 1.4, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            TweenLite.to(".agile-method-sec ul li .build-cont p .l3", 1.6, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            $(".agile-method-sec ul li.l2").removeClass("active");
          }
          if (iii == 0 && flagg == 0) {
            setTimeout(function () {
              $(".agile-method-sec ul li.l1").addClass("active");
            }, 200);
            TweenLite.to(".agile-method-sec ul", 1.2, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
                transform: "translateX( " + orgaglleft + "px)",
              },
            });
            TweenLite.to(".agile-method-sec ul li.l1", 1.2, {
              ease: Power4.easeInOut,
              css: {
                width: "490px",
              },
            });
            TweenLite.to(".agile-method-sec ul li.l1 .define-cont", 1.6, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
              },
            });
            TweenLite.to(".agile-method-sec ul li .define-cont p .l1", 1.8, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".agile-method-sec ul li .define-cont p .l2", 2, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".agile-method-sec ul li .define-cont p .l3", 2.2, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".agile-method-sec ul li.l2", 1.2, {
              ease: Power4.easeInOut,
              css: {
                width: "0",
              },
            });
            TweenLite.to(".agile-method-sec ul li.l2 .build-cont", 1.6, {
              ease: Power4.easeInOut,
              css: {
                opacity: 0,
              },
            });
            TweenLite.to(".agile-method-sec ul li .build-cont p .l1", 1.2, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            TweenLite.to(".agile-method-sec ul li .build-cont p .l2", 1.4, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            TweenLite.to(".agile-method-sec ul li .build-cont p .l3", 1.6, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            $(".agile-method-sec ul li.l2").removeClass("active");
            TweenLite.to(".agile-method-sec ul li.l3", 1.2, {
              ease: Power4.easeInOut,
              css: {
                width: "0",
              },
            });
            TweenLite.to(".agile-method-sec ul li.l3 .release-cont", 1.2, {
              ease: Power4.easeInOut,
              css: {
                opacity: 0,
              },
            });
            TweenLite.to(".agile-method-sec ul li .release-cont p .l1", 1.8, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            TweenLite.to(".agile-method-sec ul li .release-cont p .l2", 2, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            TweenLite.to(".agile-method-sec ul li .release-cont p .l3", 2.2, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            $(".agile-method-sec ul li.l3").removeClass("active");
          }
          if (iii == 1) {
            TweenLite.to(".agile-method-sec ul li .define-cont p .l1", 1.2, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            TweenLite.to(".agile-method-sec ul li .define-cont p .l2", 1.4, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            TweenLite.to(".agile-method-sec ul li .define-cont p .l3", 1.6, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            var orgaglleft2 = orgaglleft - orgaglleft123;
            TweenLite.to(".agile-method-sec ul", 1.2, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
                transform: "translateX( " + orgaglleft2 + "px)",
              },
            });
            setTimeout(function () {
              $(".agile-method-sec ul li.l2").addClass("active");
            }, 200);
            TweenLite.to(".agile-method-sec ul li.l2", 1.2, {
              ease: Power4.easeInOut,
              css: {
                width: "490px",
              },
            });
            TweenLite.to(".agile-method-sec ul li.l1", 1.2, {
              ease: Power4.easeInOut,
              css: {
                width: "0",
              },
            });
            TweenLite.to(".agile-method-sec ul li.l1 .define-cont", 1.6, {
              ease: Power4.easeInOut,
              css: {
                opacity: 0,
              },
            });
            TweenLite.to(".agile-method-sec ul li.l2 .build-cont", 1.6, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
              },
            });
            $(".agile-method-sec ul li.l1").removeClass("active");
            TweenLite.to(".agile-method-sec ul li.l1 .build-cont", 1.6, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
              },
            });
            TweenLite.to(".agile-method-sec ul li .build-cont p .l1", 1.8, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".agile-method-sec ul li .build-cont p .l2", 2, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".agile-method-sec ul li .build-cont p .l3", 2.2, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".agile-method-sec ul li.l3", 1.2, {
              ease: Power4.easeInOut,
              css: {
                width: "0",
              },
            });
            TweenLite.to(".agile-method-sec ul li.l3 .release-cont", 1.2, {
              ease: Power4.easeInOut,
              css: {
                opacity: 0,
              },
            });
            TweenLite.to(".agile-method-sec ul li .release-cont p .l1", 1.8, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            TweenLite.to(".agile-method-sec ul li .release-cont p .l2", 2, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            TweenLite.to(".agile-method-sec ul li .release-cont p .l3", 2.2, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            $(".agile-method-sec ul li.l3").removeClass("active");
          }
          if (iii == 2) {
            TweenLite.to(".agile-method-sec ul li .release-cont p .l1", 1.2, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            TweenLite.to(".agile-method-sec ul li .release-cont p .l2", 1.4, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            TweenLite.to(".agile-method-sec ul li .release-cont p .l3", 1.6, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            $(".agile-method-sec ul li.l2").removeClass("active");
            var orgaglleft2 = orgaglleft - orgaglleft125;
            TweenLite.to(".agile-method-sec ul", 1.2, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
                transform: "translateX( " + orgaglleft2 + "px)",
              },
            });
            setTimeout(function () {
              $(".agile-method-sec ul li.l3").addClass("active");
            }, 200);
            TweenLite.to(".agile-method-sec ul li.l3", 1.2, {
              ease: Power4.easeInOut,
              css: {
                width: "490px",
              },
            });
            TweenLite.to(".agile-method-sec ul li.l2", 1.2, {
              ease: Power4.easeInOut,
              css: {
                width: "0",
              },
            });
            TweenLite.to(".agile-method-sec ul li.l2 .build-cont", 1.6, {
              ease: Power4.easeInOut,
              css: {
                opacity: 0,
              },
            });
            TweenLite.to(".agile-method-sec ul li.l3 .release-cont", 1.6, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
              },
            });
            TweenLite.to(".agile-method-sec ul li .release-cont p .l1", 1.8, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".agile-method-sec ul li .release-cont p .l2", 2, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".agile-method-sec ul li .release-cont p .l3", 2.2, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".agile-method-sec ul li .build-cont p .l1", 1.8, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            TweenLite.to(".agile-method-sec ul li .build-cont p .l2", 2, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            TweenLite.to(".agile-method-sec ul li .build-cont p .l3", 2.2, {
              ease: Power4.easeInOut,
              top: "30px",
            });
            iii = 0;
          } else {
            iii++;
          }
          flagg = 0;
          setTimeout(function () {
            buidslideanimation();
          }, 5000);
        }
      }
      buidslideanimation();
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        duration: winht,
        offset: -winht + 2,
      })
        .on("enter", function () {
          TweenLite.to("#leadindus", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              visibility: "visible",
            },
            onStart: function () {
              console.clear();
              console.log("onStart");
            },
          });
          TweenLite.to(".appi-lead-text div:first-child h2", 1, {
            ease: Expo.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-lead-text div:last-child h2:first-child", 1.3, {
            ease: Expo.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-lead-text div:last-child h2:last-child", 1.5, {
            ease: Expo.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-our-ranking .appi-rating-left h3 .l1", 1, {
            ease: Power4.easeInOut,
            top: "55px",
          });
          TweenLite.to(".appi-our-ranking .appi-rating-left h3 .l2", 1.2, {
            ease: Power4.easeInOut,
            top: "55px",
          });
          TweenLite.to(".appi-our-ranking .appi-rating-left h3 .l3", 1.4, {
            ease: Power4.easeInOut,
            top: "55px",
          });
          TweenLite.to(".appi-rating-left .para p .l1", 1.6, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-rating-left .para p .l2", 1.8, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-rating-left .para p .l3", 2, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          $(".cio-review-sec, .canvas-sec, .appi-timeline-sec").removeClass(
            "active"
          );
          $(".social-network-right").addClass("active");
          $(".appi-ins-timeline").css({
            width: "0px",
          });
          $(".appi-timeline-sec .date-round-sec.cio").removeClass("active");
          setTimeout(() => {
            $(".canvas-sec")
              .children('canvas[style*="display: none;"]')
              .remove();
            $(".canvas-sec p:last-child").remove();
          }, 100);
          ps.requestAnimationFrame = function () {};
          ps = null;
          logoflag = true;
        })
        .addTo(controller);

      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: 2,
        duration: winht - winht / 2,
      })
        .on("enter", function () {
          applogo();
          logoflag = false;
          var frstwdt = $(".pos-wsth.one").width();
          $(".appi-ins-timeline").css({
            width: frstrd + frstwdt,
          });
          $(".appi-timeline-sec .date-round-sec.cio").addClass("active");
          TweenLite.to(".appi-our-ranking .appi-rating-left h3 .l1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-our-ranking .appi-rating-left h3 .l2", 1.2, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-our-ranking .appi-rating-left h3 .l3", 1.4, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-rating-left .para p .l1", 1.6, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-rating-left .para p .l2", 1.8, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-rating-left .para p .l3", 2, {
            ease: Power4.easeInOut,
            top: "0",
          });
          $(".cio-review-sec, .canvas-sec").addClass("active");
          $(".timeline-app-details.one, .appi-timeline-sec").removeClass(
            "active"
          );
          $(".timeline-app-details.three").removeClass("active");
          $(".timeline-app-details.two").removeClass("active");
          $(".timeline-app-details.four").removeClass("active");
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        duration: winht - winht / 3,
        offset: winht / 2,
      })
        .on("enter", function () {
          $(".appi-timeline-sec").addClass("active");
          setTimeout(function () {
            $(".timeline-app-details.one").addClass("active");
          }, 500);
          setTimeout(function () {
            $(".timeline-app-details.two").addClass("active");
          }, 700);
          setTimeout(function () {
            $(".timeline-app-details.three").addClass("active");
          }, 900);
          setTimeout(function () {
            $(".timeline-app-details.four").addClass("active");
          }, 1100);

          TweenLite.to(".appi-heading.inves-head div .l1", 1, {
            ease: Power4.easeInOut,
            top: "60px",
          });
          TweenLite.to(".appi-heading.inves-head div .l2", 1.3, {
            ease: Power4.easeInOut,
            top: "60px",
          });
          TweenLite.to(".appi-heading.inves-head .val i.il1", 1.2, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-heading.inves-head .val i.il2", 1.2, {
            ease: Power4.easeInOut,
            top: "50px",
          });
          TweenLite.to(".appi-youtube-video", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              visibility: "hidden",
            },
          });
          TweenLite.to(".stp-pp .stp-val", 1, {
            ease: Power4.easeInOut,
            opacity: 0,
          });
          TweenLite.to(".stp-pp .plus", 1.2, {
            ease: Power4.easeInOut,
            opacity: 0,
            left: "-14px",
          });
          TweenLite.to(".stp-pp p .l1", 1, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".stp-pp p .l2", 1.3, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".equalto .l1", 1.5, {
            ease: Power4.easeInOut,
            right: "-21px",
            opacity: 0,
          });
          TweenLite.to(".equalto .l2", 1.8, {
            ease: Power4.easeInOut,
            left: "-21px",
            opacity: 0,
          });
          TweenLite.to(".stp-pp .stp-val i.il1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".stp-pp .stp-val i.il2", 1, {
            ease: Power4.easeInOut,
            top: "60px",
          });
          TweenLite.to(".inves-million .dollar", 1, {
            ease: Power4.easeInOut,
            opacity: 0,
            top: "-70px",
          });
          TweenLite.to(".inves-million .num", 1.1, {
            ease: Power4.easeInOut,
            opacity: 0,
            top: "55px",
          });
          TweenLite.to(".inves-million .micon", 1.2, {
            ease: Power4.easeInOut,
            opacity: 0,
            top: "-70px",
          });
          TweenLite.to(".inves-million .plus", 1.3, {
            ease: Power4.easeInOut,
            opacity: 0,
            left: "55px",
          });
          TweenLite.to(".inves-million .num .l2", 1.1, {
            ease: Power4.easeInOut,
            top: "70px",
          });
          $(".inves-million .num .odometer.l2").html("000");
          TweenLite.to(".inves-million p .l1", 1.3, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".inves-million p .l2", 1.5, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-startup-content .para p .l1", 1.3, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-startup-content .para p .l2", 1.5, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-startup-content .para p .l3", 1.5, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".mvp-btn", 1, {
            ease: Power4.easeInOut,
            left: "-50px",
            opacity: "0",
          });
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht + winht / 6 + 2,
        duration: winht,
      })
        .on("enter", function () {
          TweenLite.to(".appi-heading.inves-head div .l1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-heading.inves-head div .l2", 1.3, {
            ease: Power4.easeInOut,
            top: "0",
          });
          setTimeout(function () {
            TweenLite.to(".appi-heading.inves-head .val i.il1", 1, {
              ease: Power4.easeInOut,
              top: "-50px",
            });
            TweenLite.to(".appi-heading.inves-head .val i.il2", 1, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".stp-pp .stp-val i.il1", 1, {
              ease: Power4.easeInOut,
              top: "-65px",
            });
            TweenLite.to(".stp-pp .stp-val i.il2", 1, {
              ease: Power4.easeInOut,
              top: "0",
            });
          }, 600);
          TweenLite.to(".appi-youtube-video", 1.8, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              visibility: "visible",
            },
          });
          TweenLite.to(".stp-pp .stp-val", 1, {
            ease: Power4.easeInOut,
            opacity: 1,
          });
          TweenLite.to(".stp-pp .plus", 1.2, {
            ease: Power4.easeInOut,
            opacity: 1,
            left: "0",
          });
          TweenLite.to(".stp-pp p .l1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".stp-pp p .l2", 1.3, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".equalto .l1", 1.5, {
            ease: Power4.easeInOut,
            right: "0",
            opacity: 1,
          });
          TweenLite.to(".equalto .l2", 1.8, {
            ease: Power4.easeInOut,
            left: "0",
            opacity: 1,
          });
          setTimeout(function () {
            TweenLite.to(".inves-million .dollar", 1, {
              ease: Power4.easeInOut,
              opacity: "1",
              top: "0",
            });
            TweenLite.to(".inves-million .num", 1.1, {
              ease: Power4.easeInOut,
              opacity: "1",
              top: "5px",
            });
            TweenLite.to(".inves-million .num .l2", 1, {
              ease: Power4.easeInOut,
              top: "-5px",
            });
            $(".inves-million .num .odometer.l2").html(100);
            setTimeout(function () {
              TweenLite.to(".inves-million .micon", 1.2, {
                ease: Power4.easeInOut,
                opacity: "1",
                top: "0",
              });
              TweenLite.to(".inves-million .plus", 1.3, {
                ease: Power4.easeInOut,
                opacity: "1",
                left: "0",
              });
              TweenLite.to(".inves-million p .l1", 1.3, {
                ease: Power4.easeInOut,
                top: "0",
              });
              TweenLite.to(".inves-million p .l2", 1.5, {
                ease: Power4.easeInOut,
                top: "0",
              });
            }, 700);
            TweenLite.to(".appi-startup-content .para p .l1", 1.3, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".appi-startup-content .para p .l2", 1.5, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".appi-startup-content .para p .l3", 1.5, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(".mvp-btn", 1, {
              ease: Power4.easeInOut,
              left: "0",
              opacity: "1",
            });
          }, 700);

          $(".clock-dial-center-wrap").removeClass("active");
          TweenLite.to("#Opaque_Ring", 1, {
            ease: Power4.easeInOut,
            strokeDasharray: "0,1000",
          });
          TweenLite.to(".appi-portfolio-sec-left figure.one", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
            },
          });
          TweenLite.to(".nexgtv-center-text .text-big p", 1, {
            ease: Power4.easeInOut,
            top: "95px",
          });
          TweenLite.to(".nexgtv-center-text .text-sm p", 1.3, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".nexgtv-center-text .text-big .plus", 1.3, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
              visibility: "hidden",
            },
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l1", 1, {
            ease: Power4.easeInOut,
            top: "45px",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l1 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l1 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l1 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l2", 1.8, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
              zIndex: "-1",
            },
          });
          TweenLite.to(".nexgtv-img figure.l1", 1, {
            ease: Power4.easeInOut,
            top: "-720px",
            onStart: function () {
              console.log("nasa-img");
              var bLazy = new Blazy({ offset: 100 });
              console.log(bLazy);
            },
          });
          TweenLite.to(".nexgtv-img figure.l2", 1.2, {
            ease: Power4.easeInOut,
            top: "-826px",
          });
          TweenLite.to(".nexgtv-img figure.l3", 1.4, {
            ease: Power4.easeInOut,
            top: "-7203px",
          });
          TweenLite.to(".nexgtv-img figure.l4", 1.6, {
            ease: Power4.easeInOut,
            bottom: "-768px",
          });
          TweenLite.to(".nexgtv-img figure.l5", 1.8, {
            ease: Power4.easeInOut,
            bottom: "-746px",
          });
          TweenLite.to(
            ".dotsstro, .dotsstro1, .dotsstro2, .dotsstro3, .dotsstro4, .dotsstro5, .dotsstro6",
            0.1,
            {
              ease: Power4.easeInOut,
              css: {
                opacity: 0.4,
              },
            }
          );
          TweenLite.to(
            ".dotsfill, .dotsfill1, .dotsfill2, .dotsfill3, .dotsfill4, .dotsfill5, .dotsfill6",
            0.1,
            {
              ease: Power4.easeInOut,
              css: {
                fill: "none",
              },
            }
          );
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger1",
        offset: 0,
        duration: winht * 6 + 2,
      })
        .setPin(".appi-ins-portfolio-sec")
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 2 + winht / 6 + 2,
        duration: winht - winht / 6,
      })
        .on("enter", function () {
          $(".clock-dial-center-wrap").addClass("active");
          TweenLite.to(".appi-portfolio-sec-left figure.one", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "1",
            },
          });
          setTimeout(function () {
            TweenLite.to("#Opaque_Ring", 1, {
              ease: Power4.easeInOut,
              strokeDasharray: "10,1000",
            });
            TweenLite.to(".dotsstro", 1.5, {
              ease: Power4.easeInOut,
              css: {
                opacity: 0,
              },
            });
            TweenLite.to(".dotsfill", 1.5, {
              ease: Power4.easeInOut,
              css: {
                fill: "#ffffff",
              },
            });
          }, 200);
          TweenLite.to(".nexgtv-center-text .text-big p", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".nexgtv-center-text .text-sm p", 1.3, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".nexgtv-center-text .text-big .plus", 1.3, {
            ease: Power4.easeInOut,
            css: {
              opacity: "1",
              visibility: "visible",
            },
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l1 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l1 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l1 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l2", 1.8, {
            ease: Power4.easeInOut,
            css: {
              scale: 1,
              zIndex: "1",
            },
          });
          TweenLite.to(".nexgtv-img", 1, {
            ease: Power4.easeInOut,
            opacity: "1",
            visibility: "visible",
          });
          TweenLite.to(".nexgtv-img figure.l1", 1, {
            ease: Power4.easeInOut,
            top: "140px",
          });
          TweenLite.to(".nexgtv-img figure.l2", 1.2, {
            ease: Power4.easeInOut,
            top: "-470px",
          });
          TweenLite.to(".nexgtv-img figure.l3", 1.4, {
            ease: Power4.easeInOut,
            top: "-314px",
          });
          TweenLite.to(".nexgtv-img figure.l4", 1.6, {
            ease: Power4.easeInOut,
            bottom: "-0px",
          });
          TweenLite.to(".nexgtv-img figure.l5", 1.8, {
            ease: Power4.easeInOut,
            bottom: "-261px",
          });
          TweenLite.to(".appi-ins-portfolio-sec", 1, {
            ease: Power4.easeInOut,
            css: {
              backgroundColor: "#000",
            },
          });
          TweenLite.to(".appi-portfolio-sec-left figure.two", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
            },
          });
          TweenLite.to(".appi-port-clocktext.blockchain .up-text .l1", 1, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-port-clocktext.blockchain .middel-text .l2", 1, {
            ease: Power4.easeInOut,
            top: "65px",
          });
          TweenLite.to(".appi-port-clocktext.blockchain .down-text .l3", 1, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l2", 1, {
            ease: Power4.easeInOut,
            top: "45px",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l2 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l2 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l2 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l1", 1.8, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
              zIndex: "-1",
            },
          });
          TweenLite.to(".vemeport-img", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
              visibility: "hideen",
            },
          });
          TweenLite.to(" .vemeport-img figure.l1 img", 1, {
            ease: Power4.easeInOut,
            top: "100vh",
          });
          TweenLite.to(" .vemeport-img figure.l2 img", 1.3, {
            ease: Power4.easeInOut,
            top: "100vh",
          });
          TweenLite.to(
            ".dotsstro1, .dotsstro2, .dotsstro3, .dotsstro4, .dotsstro5, .dotsstro6",
            0.1,
            {
              ease: Power4.easeInOut,
              css: {
                opacity: 0.4,
              },
            }
          );
          TweenLite.to(
            ".dotsfill1, .dotsfill2, .dotsfill3, .dotsfill4, .dotsfill5, .dotsfill6",
            0.1,
            {
              ease: Power4.easeInOut,
              css: {
                fill: "none",
              },
            }
          );
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 3 + winht / 2,
        duration: winht,
      })
        .on("enter", function () {
          TweenLite.to("#Opaque_Ring", 1, {
            ease: Power4.easeInOut,
            strokeDasharray: "135,1000",
          });
          TweenLite.to(".appi-portfolio-sec-left figure.one", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
            },
          });
          TweenLite.to(".nexgtv-center-text .text-big p", 1, {
            ease: Power4.easeInOut,
            top: "-115px",
          });
          TweenLite.to(".nexgtv-center-text .text-sm p", 1, {
            ease: Power4.easeInOut,
            top: "-38px",
          });
          TweenLite.to(".nexgtv-center-text .text-big .plus", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
            },
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l1", 1, {
            ease: Power4.easeInOut,
            top: "-45px",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l1 p .l1",
            1.1,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l1 p .l2",
            1.3,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l1 p .l3",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l2", 1.5, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
              zIndex: "-1",
            },
          });
          TweenLite.to(".nexgtv-img figure.l1", 1, {
            ease: Power4.easeInOut,
            top: "-720px",
          });
          TweenLite.to(".nexgtv-img figure.l2", 1, {
            ease: Power4.easeInOut,
            top: "-760px",
          });
          TweenLite.to(".nexgtv-img figure.l3", 1, {
            ease: Power4.easeInOut,
            top: "-7203px",
          });
          TweenLite.to(".nexgtv-img figure.l4", 1, {
            ease: Power4.easeInOut,
            bottom: "-730px",
          });
          TweenLite.to(".nexgtv-img figure.l5", 1, {
            ease: Power4.easeInOut,
            bottom: "-746px",
          });
          TweenLite.to(".appi-ins-portfolio-sec", 1, {
            ease: Power4.easeInOut,
            css: {
              backgroundColor: "#cd205c",
            },
          });
          TweenLite.to(".appi-portfolio-sec-left figure.two", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "1",
            },
          });
          setTimeout(function () {
            TweenLite.to(".dotsstro1", 1, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
              },
            });
            TweenLite.to(".dotsfill1", 1, {
              ease: Power4.easeInOut,
              css: {
                fill: "#fff",
              },
            });
          }, 800);
          TweenLite.to(".appi-port-clocktext.blockchain .up-text .l1", 1.3, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(
            ".appi-port-clocktext.blockchain .middel-text .l2",
            1.5,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(".appi-port-clocktext.blockchain .down-text .l3", 1.8, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l2", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l2 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l2 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l2 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l1", 1.8, {
            ease: Power4.easeInOut,
            css: {
              scale: 1,
              zIndex: "1",
            },
          });
          TweenLite.to(".vemeport-img", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "1",
              visibility: "visible",
            },
          });
          TweenLite.to(" .vemeport-img figure.l1 img", 1.5, {
            ease: Power4.easeInOut,
            top: "0vh",
          });
          TweenLite.to(" .vemeport-img figure.l2 img", 1.8, {
            ease: Power4.easeInOut,
            top: "0vh",
          });
          TweenLite.to(".appi-portfolio-sec-left figure.three", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
            },
          });
          TweenLite.to(".appi-port-clocktext.nasa .up-text .l1", 1, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-port-clocktext.nasa .middel-text .l2", 1.1, {
            ease: Power4.easeInOut,
            top: "89px",
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l3", 1, {
            ease: Power4.easeInOut,
            top: "45px",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l3 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l3 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l3 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l3", 1.5, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
              zIndex: "-1",
            },
          });
          TweenLite.to(" .nasa-img figure.l1", 1.1, {
            ease: Power4.easeInOut,
            bottom: "-100%",
          });
          TweenLite.to(" .nasa-img figure.l2", 1.3, {
            ease: Power4.easeInOut,
            top: "-100%",
          });
          TweenLite.to(
            ".dotsstro2, .dotsstro3, .dotsstro4, .dotsstro5, .dotsstro6",
            0.1,
            {
              ease: Power4.easeInOut,
              css: {
                opacity: 0.4,
              },
            }
          );
          TweenLite.to(
            ".dotsfill2, .dotsfill3, .dotsfill4, .dotsfill5, .dotsfill6",
            0.1,
            {
              ease: Power4.easeInOut,
              css: {
                fill: "none",
              },
            }
          );
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 4 + winht / 2,
        duration: winht,
      })
        .on("enter", function () {
          TweenLite.to("#Opaque_Ring", 1, {
            ease: Power4.easeInOut,
            strokeDasharray: "260,1000",
          });
          TweenLite.to(".appi-portfolio-sec-left figure.two", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
            },
          });
          TweenLite.to(".appi-port-clocktext.blockchain .up-text .l1", 1, {
            ease: Power4.easeInOut,
            top: "-30px",
          });
          TweenLite.to(".appi-port-clocktext.blockchain .middel-text .l2", 1, {
            ease: Power4.easeInOut,
            top: "-65px",
          });
          TweenLite.to(".appi-port-clocktext.blockchain .down-text .l3", 1, {
            ease: Power4.easeInOut,
            top: "-30px",
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l2", 1, {
            ease: Power4.easeInOut,
            top: "-45px",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l2 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l2 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l2 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l1", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
              zIndex: "-1",
            },
          });
          TweenLite.to(".vemeport-img figure.l1 img", 1, {
            ease: Power4.easeInOut,
            top: "100vh",
          });
          TweenLite.to(".vemeport-img figure.l2 img", 1.3, {
            ease: Power4.easeInOut,
            top: "100vh",
          });
          TweenLite.to(".appi-ins-portfolio-sec", 1, {
            ease: Power4.easeInOut,
            css: {
              backgroundColor: "#0e1041",
            },
          });
          TweenLite.to(".appi-portfolio-sec-left figure.three", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "1",
            },
          });
          setTimeout(function () {
            TweenLite.to(".dotsstro2", 1, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
              },
            });
            TweenLite.to(".dotsfill2", 1, {
              ease: Power4.easeInOut,
              css: {
                fill: "#fff",
              },
            });
          }, 800);
          TweenLite.to(".appi-port-clocktext.nasa .up-text .l1", 1.3, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-port-clocktext.nasa .middel-text .l2", 1.5, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l3", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l3 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l3 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l3 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l3", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 1,
              zIndex: "1",
            },
          });
          TweenLite.to(".appi-ins-portfolio-right.nasa-img", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "1",
              visibility: "visible",
            },
            onStart: function () {
              console.log("nasa-img");
              var bLazy = new Blazy({ offset: 100 });
              console.log(bLazy);
            },
          });
          TweenLite.to(".nasa-img figure.l1", 1.5, {
            ease: Power4.easeInOut,
            bottom: "-6%",
          });
          TweenLite.to(".nasa-img figure.l2", 1.8, {
            ease: Power4.easeInOut,
            top: "-9%",
          });
          TweenLite.to(".appi-portfolio-sec-left figure.four", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
            },
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l4", 1, {
            ease: Power4.easeInOut,
            top: "45px",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l4 p .l1",
            1,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l4 p .l2",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l4 p .l3",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l4", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
              zIndex: "-1",
            },
          });
          TweenLite.to(".appi-port-clocktext.dominos .up-text .l1", 1, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-port-clocktext.dominos .middel-text .l2", 1.2, {
            ease: Power4.easeInOut,
            top: "75px",
          });
          TweenLite.to(".appi-port-clocktext.dominos .down-text .l3", 1.4, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-portfolio-sec-left figure .donimg", 1, {
            ease: Power4.easeInOut,
            top: "-450px",
          });
          TweenLite.to(".appi-ins-portfolio-right.dominos-img figure.l1", 1.1, {
            ease: Power4.easeInOut,
            bottom: "-120%",
          });
          TweenLite.to(".appi-ins-portfolio-right.dominos-img figure.l2", 1.3, {
            ease: Power4.easeInOut,
            bottom: "-120%",
          });
          TweenLite.to(".dotsstro3, .dotsstro4, .dotsstro5, .dotsstro6", 0.1, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0.4,
            },
          });
          TweenLite.to(".dotsfill3, .dotsfill4, .dotsfill5, .dotsfill6", 0.1, {
            ease: Power4.easeInOut,
            css: {
              fill: "none",
            },
          });
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 5 + winht / 2,
        duration: winht,
      })
        .on("enter", function () {
          TweenLite.to("#Opaque_Ring", 1, {
            ease: Power4.easeInOut,
            strokeDasharray: "385,1000",
          });
          TweenLite.to(".appi-portfolio-sec-left figure.three", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
            },
          });
          TweenLite.to(".appi-port-clocktext.nasa .up-text .l1", 1, {
            ease: Power4.easeInOut,
            top: "-30px",
          });
          TweenLite.to(".appi-port-clocktext.nasa .middel-text .l2", 1.1, {
            ease: Power4.easeInOut,
            top: "-89px",
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l3", 1, {
            ease: Power4.easeInOut,
            top: "-45px",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l3 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l3 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l3 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l3", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
              zIndex: "-1",
            },
          });
          TweenLite.to(" .nasa-img figure.l1", 1.1, {
            ease: Power4.easeInOut,
            bottom: "-100%",
          });
          TweenLite.to(" .nasa-img figure.l2", 1.3, {
            ease: Power4.easeInOut,
            top: "-100%",
          });
          TweenLite.to(".appi-ins-portfolio-sec", 1, {
            ease: Power4.easeInOut,
            css: {
              backgroundColor: "#2c6cb1",
            },
          });
          TweenLite.to(".appi-portfolio-sec-left figure.four", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "1",
            },
          });
          setTimeout(function () {
            TweenLite.to(".dotsstro3", 1, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
              },
            });
            TweenLite.to(".dotsfill3", 1, {
              ease: Power4.easeInOut,
              css: {
                fill: "#fff",
              },
            });
          }, 800);
          TweenLite.to(".appi-port-left-content .pp-head p .l4", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l4 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l4 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l4 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l4", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 1,
              zIndex: "1",
            },
          });
          TweenLite.to(".appi-port-clocktext.dominos .up-text .l1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-port-clocktext.dominos .middel-text .l2", 1.2, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-port-clocktext.dominos .down-text .l3", 1.4, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-portfolio-sec-left figure .donimg", 1.4, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-ins-portfolio-right.dominos-img", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "1",
              visibility: "visible",
            },
            onStart: function () {
              console.log("dominos-img");
              var bLazy = new Blazy({ offset: 100 });
              console.log(bLazy);
            },
          });
          TweenLite.to(".appi-ins-portfolio-right.dominos-img figure.l1", 1.3, {
            ease: Power4.easeInOut,
            bottom: "-24%",
          });
          TweenLite.to(".appi-ins-portfolio-right.dominos-img figure.l2", 1.5, {
            ease: Power4.easeInOut,
            bottom: "-25%",
          });
          TweenLite.to(".appi-portfolio-sec-left figure.five", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
            },
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l5", 1, {
            ease: Power4.easeInOut,
            top: "45px",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l5 p .l1",
            1,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l5 p .l2",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l5 p .l3",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l5", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
              zIndex: "-1",
            },
          });
          TweenLite.to(".appi-port-clocktext.binkx .up-text .l1", 1, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-port-clocktext.binkx .middel-text .l2", 1.2, {
            ease: Power4.easeInOut,
            top: "70px",
          });
          TweenLite.to(".appi-port-clocktext.binkx .down-text .l3", 1.4, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-ins-portfolio-right.binkx-img figure.l1", 1, {
            ease: Power4.easeInOut,
            bottom: "-100%",
          });
          TweenLite.to(".appi-ins-portfolio-right.binkx-img figure.l2", 1.1, {
            ease: Power4.easeInOut,
            right: "-100%",
          });
          TweenLite.to(".dotsstro4, .dotsstro5, .dotsstro6", 0.1, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0.4,
            },
          });
          TweenLite.to(".dotsfill4, .dotsfill5, .dotsfill6", 0.1, {
            ease: Power4.easeInOut,
            css: {
              fill: "none",
            },
          });
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 6 + winht / 2,
        duration: winht,
      })
        .on("enter", function () {
          TweenLite.to("#Opaque_Ring", 1, {
            ease: Power4.easeInOut,
            strokeDasharray: "510,1000",
          });
          TweenLite.to(".appi-portfolio-sec-left figure.four", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
            },
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l4", 1, {
            ease: Power4.easeInOut,
            top: "-45px",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l4 p .l1",
            1,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l4 p .l2",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l4 p .l3",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l4", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
              zIndex: "-1",
            },
          });
          TweenLite.to(".appi-port-clocktext.dominos .up-text .l1", 1, {
            ease: Power4.easeInOut,
            top: "-30px",
          });
          TweenLite.to(".appi-port-clocktext.dominos .middel-text .l2", 1.1, {
            ease: Power4.easeInOut,
            top: "-80px",
          });
          TweenLite.to(".appi-port-clocktext.dominos .down-text .l3", 1.2, {
            ease: Power4.easeInOut,
            top: "-30px",
          });
          TweenLite.to(".appi-portfolio-sec-left figure .donimg", 1.4, {
            ease: Power4.easeInOut,
            top: "-450px",
          });
          TweenLite.to(".appi-ins-portfolio-right.dominos-img figure.l1", 1, {
            ease: Power4.easeInOut,
            bottom: "-120%",
          });
          TweenLite.to(".appi-ins-portfolio-right.dominos-img figure.l2", 1.1, {
            ease: Power4.easeInOut,
            bottom: "-120%",
          });
          TweenLite.to(".appi-ins-portfolio-sec", 1, {
            ease: Power4.easeInOut,
            css: {
              backgroundColor: "#070d68",
            },
          });
          TweenLite.to(".appi-portfolio-sec-left figure.five", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "1",
            },
          });
          setTimeout(function () {
            TweenLite.to(".dotsstro4", 1, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
              },
            });
            TweenLite.to(".dotsfill4", 1, {
              ease: Power4.easeInOut,
              css: {
                fill: "#fff",
              },
            });
          }, 800);
          TweenLite.to(".appi-port-left-content .pp-head p .l5", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l5 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l5 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l5 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l5", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 1,
              zIndex: "1",
            },
          });
          TweenLite.to(".appi-port-clocktext.binkx .up-text .l1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-port-clocktext.binkx .middel-text .l2", 1.2, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-port-clocktext.binkx .down-text .l3", 1.4, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-ins-portfolio-right.binkx-img", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "1",
              visibility: "visible",
            },
            onStart: function () {
              console.log("dominos-img");
              var bLazy = new Blazy({ offset: 100 });
              console.log(bLazy);
            },
          });
          TweenLite.to(".appi-ins-portfolio-right.binkx-img figure.l1", 1.3, {
            ease: Power4.easeInOut,
            bottom: "-5%",
          });
          TweenLite.to(".appi-ins-portfolio-right.binkx-img figure.l2", 1.5, {
            ease: Power4.easeInOut,
            right: "-35%",
          });
          TweenLite.to(".appi-portfolio-sec-left figure.six", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
            },
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l6", 1, {
            ease: Power4.easeInOut,
            top: "45px",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l6 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l6 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l6 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l6", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
              zIndex: "-1",
            },
          });
          TweenLite.to(".appi-port-clocktext.ikea .up-text .l1", 1, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-port-clocktext.ikea .middel-text .l2", 1.1, {
            ease: Power4.easeInOut,
            top: "65px",
          });
          TweenLite.to(".appi-port-clocktext.ikea .down-text .l3", 1.3, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-ins-portfolio-right.ikea-img figure.l1", 1, {
            ease: Power4.easeInOut,
            bottom: "-100%",
          });
          TweenLite.to(".appi-ins-portfolio-right.ikea-img figure.l2", 1.1, {
            ease: Power4.easeInOut,
            right: "-100%",
          });
          TweenLite.to(".appi-ins-portfolio-right.ikea-img figure.l3", 1.3, {
            ease: Power4.easeInOut,
            top: "-100%",
          });
          TweenLite.to(".dotsstro5, .dotsstro6", 0.1, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0.4,
            },
          });
          TweenLite.to(".dotsfill5, .dotsfill6", 0.1, {
            ease: Power4.easeInOut,
            css: {
              fill: "none",
            },
          });
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 7 + winht / 2,
        duration: winht,
      })
        .on("enter", function () {
          TweenLite.to("#Opaque_Ring", 1, {
            ease: Power4.easeInOut,
            strokeDasharray: "635,1000",
          });
          TweenLite.to(".appi-portfolio-sec-left figure.five", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
            },
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l5", 1, {
            ease: Power4.easeInOut,
            top: "-45px",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l5 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l5 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l5 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l5", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
              zIndex: "-1",
            },
          });
          TweenLite.to(".appi-port-clocktext.binkx .up-text .l1", 1, {
            ease: Power4.easeInOut,
            top: "-30px",
          });
          TweenLite.to(".appi-port-clocktext.binkx .middel-text .l2", 1.1, {
            ease: Power4.easeInOut,
            top: "-80px",
          });
          TweenLite.to(".appi-port-clocktext.binkx .down-text .l3", 1.3, {
            ease: Power4.easeInOut,
            top: "-30px",
          });
          TweenLite.to(".appi-ins-portfolio-right.binkx-img figure.l1", 1, {
            ease: Power4.easeInOut,
            bottom: "-100%",
          });
          TweenLite.to(".appi-ins-portfolio-right.binkx-img figure.l2", 1.2, {
            ease: Power4.easeInOut,
            right: "-100%",
          });
          TweenLite.to(".appi-ins-portfolio-sec", 1, {
            ease: Power4.easeInOut,
            css: {
              backgroundColor: "#fea700",
            },
          });
          TweenLite.to(".appi-portfolio-sec-left figure.six", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "1",
            },
          });
          setTimeout(function () {
            TweenLite.to(".dotsstro5", 1, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
              },
            });
            TweenLite.to(".dotsfill5", 1, {
              ease: Power4.easeInOut,
              css: {
                fill: "#fff",
              },
            });
          }, 800);
          TweenLite.to(".appi-port-left-content .pp-head p .l6", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l6 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l6 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l6 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l6", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 1,
              zIndex: "1",
            },
          });
          TweenLite.to(".appi-port-clocktext.ikea .up-text .l1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-port-clocktext.ikea .middel-text .l2", 1.2, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-port-clocktext.ikea .down-text .l3", 1.4, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-ins-portfolio-right.ikea-img", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "1",
              visibility: "visible",
            },
            onStart: function () {
              console.log("dominos-img");
              var bLazy = new Blazy({ offset: 100 });
              console.log(bLazy);
            },
          });
          TweenLite.to(".appi-ins-portfolio-right.ikea-img figure.l1", 1.3, {
            ease: Power4.easeInOut,
            bottom: "-26%",
          });
          TweenLite.to(".appi-ins-portfolio-right.ikea-img figure.l2", 1.5, {
            ease: Power4.easeInOut,
            right: "-24%",
          });
          TweenLite.to(".appi-ins-portfolio-right.ikea-img figure.l3", 1.5, {
            ease: Power4.easeInOut,
            top: "-28%",
          });
          TweenLite.to(".appi-portfolio-sec-left figure.seven", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
            },
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l7", 1, {
            ease: Power4.easeInOut,
            top: "45px",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l7 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l7 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l7 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l7", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
              zIndex: "-1",
            },
          });
          TweenLite.to(".appi-port-clocktext.melltoo .up-text .l1", 1, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-port-clocktext.melltoo .middel-text .l2", 1.2, {
            ease: Power4.easeInOut,
            top: "65px",
          });
          TweenLite.to(".appi-ins-portfolio-right.melltoo-img figure.l1", 1.1, {
            ease: Power4.easeInOut,
            bottom: "-100%",
          });
          TweenLite.to(".appi-ins-portfolio-right.melltoo-img figure.l2", 1.3, {
            ease: Power4.easeInOut,
            bottom: "-100%",
          });
          TweenLite.to(".dotsstro6", 0.1, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0.4,
            },
          });
          TweenLite.to(".dotsfill6", 0.1, {
            ease: Power4.easeInOut,
            css: {
              fill: "none",
            },
          });
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 8 + winht / 2,
        duration: winht - winht / 3,
      })
        .on("enter", function () {
          TweenLite.to("#Opaque_Ring", 1, {
            ease: Power4.easeInOut,
            strokeDasharray: "815,1000",
          });
          TweenLite.to(".appi-portfolio-sec-left figure.six", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "0",
            },
          });
          TweenLite.to(".appi-port-left-content .pp-head p .l6", 1, {
            ease: Power4.easeInOut,
            top: "-45px",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l6 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l6 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l6 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "-30px",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l6", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
              zIndex: "-1",
            },
          });
          TweenLite.to(".appi-port-clocktext.ikea .up-text .l1", 1, {
            ease: Power4.easeInOut,
            top: "-30px",
          });
          TweenLite.to(".appi-port-clocktext.ikea .middel-text .l2", 1.1, {
            ease: Power4.easeInOut,
            top: "-80px",
          });
          TweenLite.to(".appi-port-clocktext.ikea .down-text .l3", 1.3, {
            ease: Power4.easeInOut,
            top: "-30px",
          });
          TweenLite.to(".appi-ins-portfolio-right.ikea-img figure.l1", 1, {
            ease: Power4.easeInOut,
            bottom: "-100%",
          });
          TweenLite.to(".appi-ins-portfolio-right.ikea-img figure.l2", 1.1, {
            ease: Power4.easeInOut,
            right: "-100%",
          });
          TweenLite.to(".appi-ins-portfolio-right.ikea-img figure.l3", 1.3, {
            ease: Power4.easeInOut,
            top: "-100%",
          });
          TweenLite.to(".appi-ins-portfolio-sec", 1, {
            ease: Power4.easeInOut,
            css: {
              backgroundColor: "#008952",
            },
          });
          TweenLite.to(".appi-portfolio-sec-left figure.seven", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "1",
            },
          });
          setTimeout(function () {
            TweenLite.to(".dotsstro6", 1, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
              },
            });
            TweenLite.to(".dotsfill6", 1, {
              ease: Power4.easeInOut,
              css: {
                fill: "#fff",
              },
            });
          }, 500);
          TweenLite.to(".appi-port-left-content .pp-head p .l7", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l7 p .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l7 p .l2",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-port-left-content .appi-pp-para .appi-cont.appi-pp-l7 p .l3",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(".appi-casestudy-navi .pp-navi.l7", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 1,
              zIndex: "1",
            },
          });
          TweenLite.to(".appi-port-clocktext.melltoo .up-text .l1", 1.2, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-port-clocktext.melltoo .middel-text .l2", 1.4, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-port-clocktext.melltoo .down-text .l3", 1.6, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-ins-portfolio-right.melltoo-img", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: "1",
              visibility: "visible",
            },
            onStart: function () {
              console.log("dominos-img");
              var bLazy = new Blazy({ offset: 100 });
              console.log(bLazy);
            },
          });
          TweenLite.to(".appi-ins-portfolio-right.melltoo-img figure.l1", 1.2, {
            ease: Power4.easeInOut,
            bottom: "-10%",
          });
          TweenLite.to(".appi-ins-portfolio-right.melltoo-img figure.l2", 1.4, {
            ease: Power4.easeInOut,
            bottom: "-17%",
          });
          TweenLite.to(".appi-heading.suite-head p .l1", 1, {
            ease: Power4.easeInOut,
            top: "60px",
          });
          TweenLite.to(".appi-subheading.suite-subhead p .l1", 1.1, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-subheading.suite-subhead p .l2", 1.3, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-services-list li figure.l1", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              visibility: "hidden",
            },
          });
          TweenLite.to(".appi-services-list li figure.l2", 1.2, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              visibility: "hidden",
            },
          });
          TweenLite.to(".appi-services-list li figure.l3", 1.4, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              visibility: "hidden",
            },
          });
          TweenLite.to(
            ".appi-services-list li:first-child .serv-head .l1",
            1.1,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-services-list li:first-child .serv-para .l1",
            1.3,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-services-list li:first-child .serv-para .l2",
            1.5,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-services-list li:first-child .serv-para .l3",
            1.7,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-services-list li:nth-child(2) .serv-head .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-services-list li:nth-child(2) .serv-para .l1",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-services-list li:nth-child(2) .serv-para .l2",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-services-list li:nth-child(2) .serv-para .l3",
            1.8,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-services-list li:last-child .serv-head .l1",
            1.3,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-services-list li:last-child .serv-para .l1",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-services-list li:last-child .serv-para .l2",
            1.8,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(".appi-services-list li:last-child .serv-para .l3", 2, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-services-list li .navi-more", 0.8, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              visibility: "hidden",
            },
          });
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 9 + winht / 6 + 2,
        duration: winht - winht / 6,
      })
        .on("enter", function () {
          TweenLite.to(".appi-heading.suite-head p .l1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-subheading.suite-subhead p .l1", 1.1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-subheading.suite-subhead p .l2", 1.3, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-services-list li figure.l1", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              visibility: "visible",
            },
          });
          TweenLite.to(".appi-services-list li figure.l2", 1.2, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              visibility: "visible",
            },
          });
          TweenLite.to(".appi-services-list li figure.l3", 1.4, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              visibility: "visible",
            },
          });
          TweenLite.to(
            ".appi-services-list li:first-child .serv-head .l1",
            1.1,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-services-list li:first-child .serv-para .l1",
            1.3,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-services-list li:first-child .serv-para .l2",
            1.5,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-services-list li:first-child .serv-para .l3",
            1.7,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-services-list li:nth-child(2) .serv-head .l1",
            1.2,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-services-list li:nth-child(2) .serv-para .l1",
            1.4,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-services-list li:nth-child(2) .serv-para .l2",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-services-list li:nth-child(2) .serv-para .l3",
            1.8,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-services-list li:last-child .serv-head .l1",
            1.3,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-services-list li:last-child .serv-para .l1",
            1.6,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-services-list li:last-child .serv-para .l2",
            1.8,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(".appi-services-list li:last-child .serv-para .l3", 2, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-services-list li .navi-more", 0.8, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              visibility: "visible",
            },
          });
          TweenLite.to(".appi-heading.build-suite-head p .l1", 0.5, {
            ease: Power4.easeInOut,
            top: "60px",
          });
          TweenLite.to(".appi-subheading.build-suite-subhead p .l1", 0.5, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-subheading.build-suite-subhead p .l2", 0.5, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".build-softwares-platforms-list li.l1", 0.5, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              visibility: "hidden",
              top: "100px",
            },
          });
          TweenLite.to(".build-softwares-platforms-list li.l2", 0.5, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              visibility: "hidden",
              top: "100px",
            },
          });
          TweenLite.to(".build-softwares-platforms-list li.l3", 0.5, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              visibility: "hidden",
              top: "100px",
            },
          });
          TweenLite.to(".build-softwares-platforms-list li.l4", 0.5, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              visibility: "hidden",
              top: "100px",
            },
          });
          TweenLite.to(".build-softwares-platforms-list li.l5", 0.5, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              visibility: "hidden",
              top: "100px",
            },
          });
          TweenLite.to(".build-softwares-platforms-list li.l6", 0.5, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              visibility: "hidden",
              top: "100px",
            },
          });
          TweenLite.to(".build-softwares-platforms-list li.l7", 0.5, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              visibility: "hidden",
              top: "100px",
            },
          });
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 10 + 2,
        duration: winht - winht / 3,
      })
        .on("enter", function () {
          TweenLite.to(".appi-heading.build-suite-head p .l1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-subheading.build-suite-subhead p .l1", 1.1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-subheading.build-suite-subhead p .l2", 1.3, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".build-softwares-platforms-list li.l1", 1.3, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              visibility: "visible",
              top: "0",
            },
          });
          TweenLite.to(".build-softwares-platforms-list li.l2", 1.4, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              visibility: "visible",
              top: "0",
            },
          });
          TweenLite.to(".build-softwares-platforms-list li.l3", 1.5, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              visibility: "visible",
              top: "0",
            },
          });
          TweenLite.to(".build-softwares-platforms-list li.l4", 1.6, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              visibility: "visible",
              top: "0",
            },
          });
          TweenLite.to(".build-softwares-platforms-list li.l5", 1.7, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              visibility: "visible",
              top: "0",
            },
          });
          TweenLite.to(".build-softwares-platforms-list li.l6", 1.8, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              visibility: "visible",
              top: "0",
            },
          });
          TweenLite.to(".build-softwares-platforms-list li.l7", 1.9, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              visibility: "visible",
              top: "0",
            },
          });
          TweenLite.to(".appi-heading.revolutionizing-head div .l1", 1, {
            ease: Power4.easeInOut,
            top: "60px",
          });
          TweenLite.to(".appi-subheading.revolutionizing-subhead p .l1", 1.3, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-subheading.revolutionizing-subhead p .l2", 1.5, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".revo-logo-sec figure.l1", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              visibility: "hidden",
              left: "-50px",
            },
          });
          TweenLite.to(".revo-logo-sec figure.l2", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              visibility: "hidden",
              right: "-50px",
            },
          });
          $(".revolutionizing-list").removeClass("active");
          TweenLite.to(".blockchain-cta.l1", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
            },
          });
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 11 - winht / 3 + 2,
        duration: winht,
      })
        .on("enter", function () {
          setTimeout(function () {
            TweenLite.to(".appi-heading.revolutionizing-head div .l1", 0.5, {
              ease: Power4.easeInOut,
              top: "0",
            });
            TweenLite.to(
              ".appi-subheading.revolutionizing-subhead p .l1",
              0.8,
              {
                ease: Power4.easeInOut,
                top: "0",
              }
            );
            TweenLite.to(
              ".appi-subheading.revolutionizing-subhead p .l2",
              1.1,
              {
                ease: Power4.easeInOut,
                top: "0",
              }
            );
            TweenLite.to(".revo-logo-sec figure.l1", 0.8, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
                visibility: "visible",
                left: "0",
              },
            });
            TweenLite.to(".revo-logo-sec figure.l2", 0.8, {
              ease: Power4.easeInOut,
              css: {
                opacity: 1,
                visibility: "visible",
                right: "0",
              },
            });
            $(".revolutionizing-list").addClass("active");
            TweenLite.to(".blockchain-cta.l1", 1, {
              ease: Power4.easeInOut,
              css: {
                scale: 1,
              },
            });
          }, 10);
          TweenLite.to("#brand-list, #startup-list", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
            },
          });
          TweenLite.to(
            ".appi-heading.brand-head .l1, .appi-heading.startup-head .l1",
            1,
            {
              ease: Power4.easeInOut,
              top: "80px",
            }
          );
          TweenLite.to(
            ".appi-heading .brandval .val  i.il1, .appi-heading.startup-head .val i.il1",
            1,
            {
              ease: Power4.easeInOut,
              top: "0",
            }
          );
          TweenLite.to(
            ".appi-heading .brandval .val  i.il2, .appi-heading.startup-head .val i.il2",
            1,
            {
              ease: Power4.easeInOut,
              top: "55px",
            }
          );
          TweenLite.to(
            ".appi-subheading.brand-subhead p .l1, .appi-subheading.startups-subhead p .l1",
            1,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(
            ".appi-subheading.brand-subhead p .l2, .appi-subheading.startups-subhead p .l2",
            1,
            {
              ease: Power4.easeInOut,
              top: "30px",
            }
          );
          TweenLite.to(".brand-cta.l1, .brand-cta.l2", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
            },
          });
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 12 - winht / 3 + 2,
        duration: winht,
      })
        .on("enter", function () {
          TweenLite.to("#brand-list", 1.5, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
            },
          });
          TweenLite.to(".appi-heading.brand-head .l1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          setTimeout(function () {
            TweenLite.to(".appi-heading .brandval .val  i.il1", 1, {
              ease: Power4.easeInOut,
              top: "-60px",
            });
            TweenLite.to(".appi-heading .brandval .val  i.il2", 1, {
              ease: Power4.easeInOut,
              top: "0",
            });
          }, 500);
          TweenLite.to(".appi-subheading.brand-subhead p .l1", 1.1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-subheading.brand-subhead p .l2", 1.3, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".brand-cta.l1", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 1,
            },
          });
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 13 - winht / 3 + 4,
        duration: winht / 2,
      })
        .on("enter", function () {
          TweenLite.to("#startup-list", 1.5, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
            },
          });
          TweenLite.to(".appi-heading.startup-head .l1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          setTimeout(function () {
            TweenLite.to(".appi-heading.startup-head .val i.il1", 1, {
              ease: Power4.easeInOut,
              top: "-60px",
            });
            TweenLite.to(".appi-heading.startup-head .val i.il2", 1, {
              ease: Power4.easeInOut,
              top: "0",
            });
          }, 500);
          TweenLite.to(".appi-subheading.startups-subhead p .l1", 1.1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-subheading.startups-subhead p .l2", 1.3, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".brand-cta.l2", 1, {
            ease: Power4.easeInOut,
            css: {
              scale: 1,
            },
          });
          TweenLite.to(".appi-heading.agile-head .l1", 1, {
            ease: Power4.easeInOut,
            top: "60px",
          });
          TweenLite.to(".appi-heading.agile-head .l2", 1.2, {
            ease: Power4.easeInOut,
            top: "60px",
          });
          TweenLite.to(".appi-subheading.agile-subhead p .l1", 1.4, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-subheading.agile-subhead p .l2", 1.6, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".agile-cta", 1.2, {
            ease: Power4.easeInOut,
            css: {
              scale: 0,
            },
          });
          TweenLite.to(".agile-method-sec ul li.l1", 1.2, {
            ease: Power4.easeInOut,
            css: {
              width: "0",
            },
          });
          TweenLite.to(".agile-method-sec ul li.l1 .define-cont", 1.6, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
            },
          });
          TweenLite.to(".agile-method-sec ul li .define-cont p .l1", 1.8, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".agile-method-sec ul li .define-cont p .l2", 2, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".agile-method-sec ul li .define-cont p .l3", 2.2, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          $(".agile-method-sec ul li.l1").removeClass("active");
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 13 + winht / 6 + 8,
        duration: winht - winht / 6,
      })
        .on("enter", function () {
          TweenLite.to(".appi-heading.agile-head .l1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-heading.agile-head .l2", 1.2, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-subheading.agile-subhead p .l1", 1.4, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-subheading.agile-subhead p .l2", 1.6, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".agile-cta", 1.2, {
            ease: Power4.easeInOut,
            css: {
              scale: 1,
            },
          });
          TweenLite.to(".appi-heading.testimonial-head div .l1", 1, {
            ease: Power4.easeInOut,
            top: "60px",
          });
          TweenLite.to(".appi-subheading.testimonial-subhead p .l1", 1, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-subheading.testimonial-subhead p .l2", 1.2, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".ranking-appi-web figure.l1", 1.2, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              left: "-100px",
            },
          });
          TweenLite.to(".ranking-appi-web figure.l2", 1.2, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              right: "-100px",
            },
          });
          TweenLite.to("#testimonials-list", 1, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
            },
          });
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 14 + 4,
        duration: winht - winht / 3,
      })
        .on("enter", function () {
          TweenLite.to(".appi-heading.testimonial-head div .l1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-subheading.testimonial-subhead p .l1", 1.2, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-subheading.testimonial-subhead p .l2", 1.4, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".ranking-appi-web figure.l1", 1.6, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              left: "0",
            },
          });
          TweenLite.to(".ranking-appi-web figure.l2", 1.8, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              right: "0",
            },
          });
          TweenLite.to("#testimonials-list", 2, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
            },
          });
          TweenLite.to(".appi-heading.fortune .l1", 1, {
            ease: Power4.easeInOut,
            top: "60px",
          });
          TweenLite.to(".fortune-list-wrap li:nth-child(1) figure", 1.2, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              scale: "0",
            },
          });
          TweenLite.to(".fortune-list-wrap li:nth-child(2) figure", 1.4, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              scale: "0",
            },
          });
          TweenLite.to(".fortune-list-wrap li:nth-child(3) figure", 1.6, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              scale: "0",
            },
          });
          TweenLite.to(".fortune-list-wrap li:nth-child(4) figure", 1.8, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              scale: "0",
            },
          });
          TweenLite.to(".fortune-list-wrap li:nth-child(5) figure", 2, {
            ease: Power4.easeInOut,
            css: {
              opacity: 0,
              scale: "0",
            },
          });
          TweenLite.to(".appi-heading.fortune .l1", 1, {
            ease: Power4.easeInOut,
            top: "45px",
          });
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 15 - winht / 3 + 8,
        duration: winht / 3,
      })
        .on("enter", function () {
          TweenLite.to(".appi-heading.fortune .l1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".fortune-list-wrap li:nth-child(1) figure", 1.2, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              scale: "1",
            },
          });
          TweenLite.to(".fortune-list-wrap li:nth-child(2) figure", 1.4, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              scale: "1",
            },
          });
          TweenLite.to(".fortune-list-wrap li:nth-child(3) figure", 1.6, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              scale: "1",
            },
          });
          TweenLite.to(".fortune-list-wrap li:nth-child(4) figure", 1.8, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              scale: "1",
            },
          });
          TweenLite.to(".fortune-list-wrap li:nth-child(5) figure", 2, {
            ease: Power4.easeInOut,
            css: {
              opacity: 1,
              scale: "1",
            },
          });
          TweenLite.to(".appi-heading.brilliance-head div .l1", 1, {
            ease: Power4.easeInOut,
            top: "60px",
          });
          TweenLite.to(".appi-subheading.brilliance-subhead p .l1", 1.2, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          TweenLite.to(".appi-subheading.brilliance-subhead p .l2", 1.4, {
            ease: Power4.easeInOut,
            top: "30px",
          });
          $(".craft-brilliance-form, .appinventiv-foot-logo").removeClass(
            "active"
          );
          TweenLite.to("#footappi-logo .st0", 1, {
            ease: Power4.easeInOut,
            opacity: 0,
          });
          TweenLite.to(".appiline", 1, {
            ease: Power4.easeInOut,
            opacity: 0,
          });
        })
        .addTo(controller);
      var scene = new ScrollMagic.Scene({
        triggerElement: "#trigger",
        offset: winht * 15 + 4,
        duration: winht,
      })
        .on("enter", function () {
          TweenLite.to(".appi-heading.brilliance-head div .l1", 1, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-subheading.brilliance-subhead p .l1", 1.2, {
            ease: Power4.easeInOut,
            top: "0",
          });
          TweenLite.to(".appi-subheading.brilliance-subhead p .l2", 1.4, {
            ease: Power4.easeInOut,
            top: "0",
          });
          $(".craft-brilliance-form, .appinventiv-foot-logo").addClass(
            "active"
          );
          TweenLite.to("#footappi-logo .st0", 1, {
            ease: Power4.easeInOut,
            opacity: 0.2,
          });
          setTimeout(function () {
            TweenLite.to(".appiline", 1, {
              ease: Power4.easeInOut,
              opacity: 1,
            });
          }, 1000);
        })
        .addTo(controller);
    });

    $(function () {
      $("[data-popup-open]").on("click", function (e) {
        var t = $(this).attr("data-popup-open");
        $('[data-popup="' + t + '"]').fadeIn(350), e.preventDefault();
        var a = $(this).attr("href");
        if ($(window).width() > 800) {
          var o = a.substring(a.search("=") + 1, a.length);
          $("#mPlayer DIV").html(
            '<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' +
              o +
              '" frameborder="0" allowfullscreen></iframe>'
          ),
            $("#mPlayer").fadeIn(500);
        } else window.location.href = a;
        $("body").css("overflow", "hidden");
      }),
        $("[data-popup-close]").on("click", function (e) {
          var t = $(this).attr("data-popup-close");
          $('[data-popup="' + t + '"]').fadeOut(350),
            $("#mPlayer DIV").empty(),
            e.preventDefault(),
            $("body").css("overflow", "auto");
        });
    });
  }

  ngAfterViewInit() {
    $("#brand-list").owlCarousel({
      loop: true,
      margin: 0,
      nav: false,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 5,
        },
        1000: {
          items: 7,
        },
      },
    });
    $("#startup-list").owlCarousel({
      loop: true,
      margin: 0,
      nav: false,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 5,
        },
        1000: {
          items: 6,
        },
      },
    });
    $("#testimonials-list").owlCarousel({
      loop: true,
      margin: 0,
      dots: false,
      nav: true,
      navText: [
        "<div class='left'><div class='arrow-nav'></div></div>",
        "<div class='right'><div class='arrow-nav'></div></div>",
      ],
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 2,
        },
      },
    });
    var owl = $("#leadindus").owlCarousel({
      loop: false,
      margin: 0,
      nav: true,
      animateOut: "fadeOut",
      touchDrag: false,
      mouseDrag: false,
      autoplay: true,
      autoplayTimeout: 10000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 1,
        },
      },
    });
    owl.on("change.owl.carousel changed.owl.carousel", function (e) {
      if (e.property.name != "position") return;
      var current = e.relatedTarget.current();
      var items = $(this).find(".owl-stage").children();
      var add = e.type == "changed";
      items
        .eq(e.relatedTarget.normalize(current + 1))
        .toggleClass("before", add);
      items
        .eq(e.relatedTarget.normalize(current - 1))
        .toggleClass("after", add);
    });
  }
}
