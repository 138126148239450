import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { ChatService } from "src/app/services/chat/chat.service";
import { HttpService } from "src/app/services/http/http.service";
import { CommonService } from "src/app/services/common/common.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/services/auth-guard/auth.service";
import { filter, pairwise } from 'rxjs/operators';
import { Router, RoutesRecognized } from "@angular/router";
declare var $: any;

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
  userData: any = localStorage.userProfileData
    ? JSON.parse(localStorage.userProfileData)
    : {};
  imageUrl: any = environment.imageUrl;
  allChats: any[] = [];
  totalCount: any;
  page: any = 1;
  dataImageUrl: any = "";
  file: any[] = [];
  fileDataArray: any[] = [];
  fileDataUrl: any[] = [];
  imageFileData: any[] = [];
  index: any;
  imgUrl: any;
  totalPage: any;
  editedFile: any;
  scrollTopHeigth: any = 0;
  previousScrollHeightMinusTop: any = 0;
  userAllData: any;
  isIconShow: boolean = true;
  @ViewChild("myfile", { static: false }) myfile: ElementRef;

  newMessage: any = '';
  constructor(
    private chatService: ChatService,
    private http: HttpService,
    private common: CommonService,
    public translate: TranslateService,
    private auth: AuthService,
    private _cdr: ChangeDetectorRef,
    private router: Router,
  ) {

  }


  ngAfterViewInit() {
    this.auth.isLoogedOut.subscribe((res: any) => {
      if (res == true) {
        if (this.userAllData && !(this.userAllData._id == undefined || this.userAllData._id == null || this.userAllData._id == '')) {
          var query = {
            userId: this.userAllData._id,
          };
          this.chatService.emitAction("disConnectToSupport", query);

        }
        let chatbox = document.querySelector(".chat");
        chatbox.classList.remove("show");
        this.isIconShow = false;
      }
    })
    let chatbox = document.querySelector(".chat");
    chatbox.classList.remove("show");
    this.isIconShow = true;
    this.chatService.onEvent("supportMessageRecevied").subscribe((res: any) => {
      console.log("connectionokforrecieved", res);
      const audio = new Audio();
      audio.src = 'assets/sounds/notification-sound1.mp3';
      audio.load();
      audio.play();
      this.allChats.push(res);
      let chatbox = document.querySelector(".chat");
      chatbox.classList.add("show");
      this.isIconShow = false;
      var elem = document.getElementById("chat_converse");
      $("#chat_converse").animate(
        { scrollTop: (elem.scrollTop = elem.scrollHeight * (this.page * 10)) },
        1000
      );
    });
  }
  onScroll() { }

  onScrollUp() {
      this.page = this.page + 1;
      if (this.totalPage >= this.page) {
        var param = "page=" + this.page + "&" + "limit=1000";
        this.http
          .httpGetWithHeader("getSupportChatHistory", param)
          .subscribe((res: any) => {
            if (res.status == 200) {
              console.log(res);
              let chatData: any[] = [];
              chatData = res.result.messages;
              chatData.forEach((item: any) => {
                this.allChats.unshift(item);
              });
              console.log(this.allChats);
              this.totalPage = res.result.count;
            }
          });
      }
  }
  download(path: any) {
    window.open(path, "_blank");
  }

  ngOnInit() {
    this.userAllData = localStorage.userData
      ? JSON.parse(localStorage.userData)
      : "";
  }

  removeImage(data: any, i: any) {
    this.fileDataUrl.splice(i, 1);
    this.imageFileData.splice(i, 1);
  }

  getAllChatHistory(page: any) {
    var param = "page=" + page + "&" + "limit=1000";
    this.http
      .httpGetWithHeader("getSupportChatHistory", param)
      .subscribe((res: any) => {
        if (res.status == 200) {
          console.log(res);
            this.allChats = res.result.messages.reverse();
          this.totalPage = res.result.count;
          var elem = document.getElementById("chat_converse");
          $("#chat_converse").animate(
            { scrollTop: (elem.scrollTop = elem.scrollHeight * (page*1000)) },
            10
          );   
        }
      });
  }

  getUpload(event) {
    if (event.target.files && event.target.files) {
      let fileData: any[] = [];
      fileData = event.target.files;
      if (Array.from(fileData).length <= 3) {
        this.file = Array.from(fileData);
        Array.prototype.forEach.call(this.file, (child) => {
          console.log(child);
          if (child.type.includes("image")) {
            if (this.imageFileData.length < 3) {
              var reader = new FileReader();
              this.imageFileData.push(child);
              reader.readAsDataURL(child);
              reader.onload = (_event) => {
                this.fileDataUrl.push(reader.result);
              };
            } else {
              this.common.presentsToast(
                "error",
                "top-end",
                this.translate.instant("THREE_IMAGE_ALLOWED")
              );
            }
          }

          else if (child.type.includes("video")) {
            var duration: any;
							new Audio(URL.createObjectURL(child)).onloadedmetadata = (e: any) => {
								duration = e.currentTarget.duration;
								console.log('duration', duration)
							  };
              if (duration > 30) {
                this.common.presentsToast(
                  "error",
                  "top-end",
                  this.translate.instant("MAXIMUM_THIRTY_SECOND_VIDEO")
                );
              }
              else {
                let formData = new FormData();
                formData.append("file", child);
                this.http
                  .httpPostWithHeaderFormData("supportChatFileUpload", formData)
                  .subscribe((res: any) => {
                    if (res.status == 200) {
                      console.log(res);
                      this.myfile.nativeElement.value = "";
                      this.file = [];
                    }
                  });
              }
            //};
          }
          else {
            let formData = new FormData();
            formData.append("file", child);
            this.http
              .httpPostWithHeaderFormData("supportChatFileUpload", formData)
              .subscribe((res: any) => {
                if (res.status == 200) {
                  console.log(res);
                  this.myfile.nativeElement.value = "";
                  this.file = [];
                }
              });
          }
        });
      }
      else {
        this.common.presentsToast(
          "error",
          "top-end",
          this.translate.instant("THREE_IMAGE_ALLOWED")
        );
      }

    }
  }

  sendMessage() {
    if (
      !(
        this.newMessage.trim() == "" ||
        this.newMessage == undefined ||
        this.newMessage == null
      ) &&
      this.imageFileData.length == 0
    ) {
      var connect = "sendSupportMessage";
      var data = {
        text: this.newMessage.trim(),
      };
      this.chatService.emitAction(connect, data);
      this.newMessage = "";
    } else if (
      (this.newMessage == "" ||
        this.newMessage == undefined ||
        this.newMessage == null) &&
      this.imageFileData.length > 0
    ) {
      let formData = new FormData();
      for (let i = 0; i < this.imageFileData.length; i++) {
        formData.append("file", this.imageFileData[i]);
      }
      this.http
        .httpPostWithHeaderFormData("supportChatFileUpload", formData)
        .subscribe((res: any) => {
          if (res.status == 200) {
            console.log(res);
            this.file = [];
            this.imageFileData = [];
            this.fileDataUrl = [];
          }
        });
    } else {
      this.newMessage = "";
      this.common.presentsToast(
        "success",
        "top-end",
        this.translate.instant("ONLY_WHITE_SPACES")
      );
    }
    this.chatService.isUserDatafetched.subscribe((res: any) => {
      if (res == true) {
        var elem = document.getElementById("chat_converse");
        $("#chat_converse").animate(
          {
            scrollTop: (elem.scrollTop = elem.scrollHeight * (this.page * 10)),
          },
          1000
        );
      }
    });
  }

  modelOpen(url: any, i: any) {
    this.imgUrl = '';
    setTimeout(() => {
      this.imgUrl = url;
    }, 500);
    this.index = i;
    $("#fileUpload").modal("show");
  }

  save(event: any) {
    console.log(event);
    var b: any = event;
    b.lastModifiedDate = new Date();
    b.name = "new-image.jpeg";
    this.editedFile = <File>event;
    this.imageFileData[this.index] = this.editedFile;
    setTimeout(() => {
      var reader = new FileReader();
      reader.readAsDataURL(this.editedFile);
      reader.onload = (_event) => {
        this.fileDataUrl[this.index] = reader.result;
        this._cdr.detectChanges();
      };
      $("#fileUpload").modal("hide");
    }, 500);
  }

  cancel() {
    $("#fileUpload").modal("hide");
  }

  chatopen() {
    let chatbtn = document.getElementById("prime");
    let chatbox = document.querySelector(".chat");
    let chatClose = document.querySelector(".close-icon");
    chatbtn.addEventListener("click", (event) => {
      console.log(event);
      chatbox.classList.toggle("show");
      chatClose;
      var elem = document.getElementById("chat_converse");
      $("#chat_converse").animate(
        { scrollTop: (elem.scrollTop = elem.scrollHeight * (this.page * 1000)) },
        1000
      );
    });
  }
  openChat() {
    this.page = 1;
    this.getAllChatHistory(this.page);
    this.chatService.emitAction("connectToSupport", {})
    this.chatService.onEvent("connectToSupportOk").subscribe((res: any) => {
      if (res.status == 200) {
        console.log("connectionok", res);
      }
    });
    let chatbox = document.querySelector(".chat");
    chatbox.classList.add("show");
    this.isIconShow = false;
    var elem = document.getElementById("chat_converse");
    $("#chat_converse").animate(
      { scrollTop: (elem.scrollTop = elem.scrollHeight * (this.page * 1000)) },
      1000
    );
  }
  closeChat() {
    var query = {
      userId: this.userAllData._id,
    };
    this.chatService.emitAction("disConnectToSupport", query);
    this.chatService.onEvent("disConnectToSupportOk").subscribe((res: any) => {
      console.log("diconnectionokforrecieved", res);
    });
    let chatbox = document.querySelector(".chat");
    chatbox.classList.remove("show");
    this.isIconShow = true;
  }

  ngOnDestroy() {
    if (!(localStorage.accessToken == '' || localStorage.accessToken == undefined || localStorage.accessToken == null)) {
      var query = {
        userId: this.userAllData._id,
      };
      this.chatService.emitAction("disConnectToSupport", query);
      this.chatService.onEvent("disConnectToSupportOk").subscribe((res: any) => {
        console.log("diconnectionokforrecieved", res);
      });
    }
  }
}
